import * as React from "react";
import { Form } from "react-bootstrap";
import { IChoiceModel } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { FormPreviewConstants } from "src/components/helper/Constants";

interface MultiChoiceQuestionPreviewProps {
    choices: IChoiceModel[];
    resetValidations: boolean;
    isEditMode?: boolean;
    isRequired: boolean;
    followupQuestionId?:number;
}

const MultiChoiceQuestionPreview: React.FC<MultiChoiceQuestionPreviewProps> = ({
    choices,
    resetValidations,
    isEditMode,
    isRequired,
    followupQuestionId
}) => {
    const [isValid, setIsValid] = React.useState(true);
    const [options, setOptions] = React.useState<IChoiceModel[]>([...choices]);
    React.useEffect(() => {
        setIsValid(true);
        let updatedOptions: IChoiceModel[] = [...choices].map((option) => {
            return { ...option, isSelected: false };
        });
        setOptions(updatedOptions);
    }, [resetValidations, followupQuestionId, choices]);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let updatedOptions: IChoiceModel[] = options.map((option) => {
            return { ...option, isSelected: option.id === choice.id && e.target.checked };
        });
        isRequired && setIsValid(e.target.checked);
        setOptions(updatedOptions);
    };

    const onOptionClick = (e: React.MouseEvent<HTMLInputElement>, choice: IChoiceModel) => {
        if (choice.isSelected) {
            e.preventDefault();
            return;
        }
        let updatedOptions: IChoiceModel[] = options.map((option) => {
            return { ...option, isSelected: false };
        });
        isRequired && setIsValid(false);
        setOptions(updatedOptions);
    };
    return (
        <div className={`multi-choice-preview-wrapper answer-wrapper ${isEditMode ? "non-editable" : ""}`}>
            {options &&
                options.length > 0 &&
                options.map((option) => (
                    <Form.Check
                        checked={option.isSelected}
                        title={option.text}
                        label={option.text}
                        type="radio"
                        key={option.id}
                        onChange={(e) => onChangeHandler(e, option)}
                        onClick={(e) => onOptionClick(e, option)}
                        className={!isValid ? "error-radio" : " "}
                    />
                ))}

            {!isValid && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
        </div>
    );
};

export default MultiChoiceQuestionPreview;
