import DOMPurify from 'dompurify';
import { VariableListData } from 'src/Organizer/models/OrganizerComponentModels';


export const configureDOMPurify = (variableList: VariableListData[]) => {
    const placeholdersToSkip = variableList.map(item => item.variable.toUpperCase());

    const replacePlaceholdersWithMarkers = (input: string): string => {
        placeholdersToSkip.forEach(placeholder => {
            const regex = new RegExp(`<${placeholder}>`, 'gi');
            input = input.replace(regex, `PLACEHOLDER_START_${placeholder}`);
        });
        return input;
    };

    const restorePlaceholdersFromMarkers = (input: string): string => {
        placeholdersToSkip.forEach(placeholder => {
            const startRegex = new RegExp(`PLACEHOLDER_START_${placeholder}`, 'gi');
            input = input.replace(startRegex, `<${placeholder}>`);
        });
        return input;
    };

    return (input: string): string => {
        const inputWithMarkers = replacePlaceholdersWithMarkers(input);
        const sanitizedInput = DOMPurify.sanitize(inputWithMarkers);
        return restorePlaceholdersFromMarkers(sanitizedInput);
    };
};
