import { PdfViewer } from 'awesome-pdf-viewer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties, PageSize } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { CustomOptions } from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { isEqual } from 'lodash';
import * as React from 'react';
import { ButtonGroup, Dropdown, Popover, Overlay } from 'react-bootstrap';
import {
    OrganizerBookmarks, GroupType, OrganizerDocument, UserGroup, ControlType, DataType, ControlRole, SignerDropDown, OrganizerClientType, OrganizerClient, OrganizerDocumentSignatureSettings, defaultSigner,
    Control, SignatureControlsDictionary, PdfPageSignatureControl, SignatureRadioButtonControl, SignatureCheckBoxControl, ChoosableControlData, initialControl
} from './../../../../../models/OrganizerComponentModels';
import { OrganizerTabBookmarkPanel } from './Panels/OrganizerTabBookmarkPanel';
import { PdfProperties } from '../../../../Helper/PdfHelper';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ToggleSwitch from '../../../../../../components/common/ToggleSwitch';
import { BookMarkTitleConstants } from '../../../../Helper/OrganizerConstants';
import { SignaturePanel } from './Panels/SignaturePanel';
import * as CompanyStore from 'src/store/company/CompanyStore';
import { EROSignatureCard } from './Modal/EROSignaturePanel';
import { IEROSigner } from '../../../../../../components/common/ProcessReturnModal/ProcessReturnModels';
import { SignatureGroupType } from '../../../../../../components/common/TaxReturn';
import { IUserProfile } from '../../../../../../components/navigation/profile/ProfileObjects';
import { IUserModel } from '../../../../../../Core/ViewModels/User/UserViewModel';
import { IUserSignatures } from '../../../../../store/UserSignatureStore';
import { UserSettings } from '../../../../../../store/UserManagement/UserSettingStore';
import ControlLayer from 'awesome-pdf-viewer/dist/Layers/ControlLayer';
import { ControlBase, PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH } from 'awesome-pdf-viewer/dist/Controls/ControlBase';
import { PlaceholderControl } from 'awesome-pdf-viewer/dist/Controls/PlaceholderControl/PlaceholderControl';
import { getControl, getControlIcon, getControlText, getTopAdjustmentSignatureControlValue } from './../../../../Helper/OrganizerHelperFunctions';
import { getControlTopPositionPDFJS } from 'src/components/helper/HelperFunctions';
import { Guid } from './../../../../../../Core/Utilities/Guid';
import { SignerTooltipPopup } from 'src/components/common/SignerToolTip';
import { INVALID_PAGE, SignatureControlConstants, UploadtaxReturnConstants } from '../../../../../../components/helper/Constants';
import { IPartnerData } from '../../../../../../store/UserManagement/UserStore';
import { SignaturePanelConstants } from 'src/components/helper/Constants';
const Draggabilly: any = require('draggabilly/draggabilly');

export interface OrganizerTabProps {
    pdf: any;
    pages: number[];
    moveToGroup?: (source: GroupType, destination: GroupType, pageNo: number) => void;
    bookmarks: OrganizerBookmarks;
    engagementLetterExists: boolean;
    readOnlyView?: boolean;
    hideMoveTo?: boolean;
    pdfURL: string;
    sourceDocumentEnabled?: boolean,
    updatesignatureStatus?: (currentPageNo: number, enableSignature: boolean) => void;
    organizerClients: OrganizerClient[];
    company: CompanyStore.ICompanyData;
    isAssignedToLoggedinUser: boolean;
    userProfile: IUserProfile;
    partners: IPartnerData;
    userSignatures: IUserSignatures;
    userSettings: UserSettings;
    ero: number;
    requestDownloadPath(userId: number): void;
    users: IUserModel[];
    signatureSettings: OrganizerDocumentSignatureSettings;
    isBatchOrganizer: boolean;
    signatureControls: SignatureControlsDictionary;
    onDrop: (control: Control, pageNo: number) => void;
    onControlRemove: (control: Control, pageNo: number) => void;
    onControlUpdate: (oldControl: Control, newControl: Control, pageNo: number) => void;
    onSaveToolTip: (control: Control, pageNo: number) => void;
}

export interface OrganizerTabState {
    bookmarkExpand: boolean;
    currentPage: number;
    zoomEnabled: boolean;
    scale: number;
    pageHeight: number;
    showDeleteConfirmationPopover: boolean;
    moveToToggle: boolean;
    deletePopoverTarget: any;
    currentPageNo: number;
    clientSignatureExpand: boolean;
    seletedSigner: SignerDropDown;
    focusGroup: UserGroup;
    selectedEROSigner: IEROSigner;
    eroSigners: IEROSigner[];
    focusedGroup: SignatureGroupType;
    senderSignatureExpand: boolean;
    toolTipControl: Control;
    showToolTipPopup: boolean;
    signatureControlType: number,
    signatureDataType: DataType,
    content: {
        header: string,
        title: string,
        tooltipContent: string
    }
}

export default class OrganizerTab extends React.Component<OrganizerTabProps, OrganizerTabState> {
    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;
    private _bookmarkPanel: any;
    private _controlDisplayPanel: any;


    constructor(props: OrganizerTabProps) {
        super(props);
        this.state = {
            bookmarkExpand: true,
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            scale: 1,
            pageHeight: PdfProperties.PageHeight,
            showDeleteConfirmationPopover: false,
            moveToToggle: false,
            deletePopoverTarget: {},
            currentPageNo: 1,
            clientSignatureExpand: false,
            focusGroup: UserGroup.None,
            selectedEROSigner: {
                value: 0,
                label: "",
                isEnableSignatureDelegation: true,
                eroImage: ""
            },
            seletedSigner: defaultSigner,
            eroSigners: [],
            focusedGroup: SignatureGroupType.None,
            senderSignatureExpand: false,
            toolTipControl: initialControl,
            showToolTipPopup: false,
            signatureControlType: 0,
            signatureDataType: DataType.None,
            content: {
                header: "",
                title: "",
                tooltipContent: ""
            }
        }
    }

    componentDidMount() {
        this.setReferences();
        this.setEroSignerDropdownValues(this.props);

        const taxpayer = this.props.organizerClients
            .find(client => client.clientType == OrganizerClientType.Taxpayer);

        if (taxpayer?.isDeceased && this.props.organizerClients.length > 1
            && !this.props.organizerClients[1]?.isDeceased) {
            this.setState({
                seletedSigner: {
                    value: this.props.organizerClients[1]?.id,
                    label: this.props.organizerClients[1]?.name,
                    disabled: this.props.organizerClients[1]?.isDeceased,
                    role: ControlRole.Spouse
                }
            });
        }
        else {
            if (taxpayer) {
                this.setState({
                    seletedSigner: {
                        value: taxpayer.id,
                        label: taxpayer.name,
                        disabled: taxpayer.isDeceased,
                        role: ControlRole.Taxpayer
                    }
                })
            }
        }

        this.setState({
            currentPage: this.props.pages[0],
            currentPageNo: 1,
        });
    }

    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
        this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    addEroSigner = (eroSignersArray: IEROSigner[], eroSigner: IEROSigner) => {
        if (eroSignersArray.filter(e => e.value == eroSigner.value).length === 0) {
            eroSignersArray.push(eroSigner);
        }
    }

    setEroSignerDropdownValues = (nextprops: OrganizerTabProps) => {
        let eroSigners: IEROSigner[] = [];

        if (this.props.company) {
            let companyAsSigner = {
                label: this.props.company.companyProfile?.companyInfo?.companyName,
                value: 0,
                isEnableSignatureDelegation: true,
                eroImage: this.props.company.signatureUploadLink,
            }
            this.addEroSigner(eroSigners, companyAsSigner);
        }

        if (this.props.userProfile && this.props.partners?.partners?.length > 0) {
            const currentUser = {
                label: this.props.userProfile.firstName + " " + this.props.userProfile.lastName,
                value: this.props.userProfile.userId as number,
                isEnableSignatureDelegation: true,
                eroImage: this.props.userSignatures[this.props.userProfile.userId]?.signatureDownloadPath
            }
            this.addEroSigner(eroSigners, currentUser);
        }

        if (this.props.userSettings?.delegatedSigners &&
            this.props.userSettings.delegatedSigners.length > 0) {

            this.props.userSettings.delegatedSigners.map(eroSigner => {
                let signer = {
                    label: eroSigner.firstName + " " + eroSigner.lastName,
                    value: eroSigner.userId,
                    isEnableSignatureDelegation: eroSigner.isEnableSignatureDelegation,
                    eroImage: eroSigner.signaturePath,
                }
                this.addEroSigner(eroSigners, signer);
            });
        }

        if (this.props.userProfile &&
            this.props.userProfile.userId !== this.props.ero) {
            const ero = this.props.userSignatures[this.props.ero];
            if (ero) {
                this.props.requestDownloadPath(this.props.ero), () => {
                    const documentEroUser = this.props.users.filter(x => x.userId === this.props.ero)[0];
                    if (documentEroUser) {
                        const eroUser = {
                            label: documentEroUser.firstName + " " + documentEroUser.lastName,
                            value: documentEroUser.userId,
                            isEnableSignatureDelegation: true,
                            eroImage: this.props.userSignatures[this.props.ero].signatureDownloadPath
                        }
                        this.addEroSigner(eroSigners, eroUser);
                    }
                }
            }
        }
        if (this.props.signatureSettings) {
            const selectedEROId = this.props.signatureSettings.signatureStampUser != 0 ?
                this.props.signatureSettings.signatureStampUser
                : this.state.selectedEROSigner.value;

            let tempSelectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
            tempSelectedEROSigner = tempSelectedEROSigner ? tempSelectedEROSigner :
                eroSigners.find(x => x.value === 0) as IEROSigner;

            this.setState({
                eroSigners: eroSigners,
                selectedEROSigner: {
                    value: tempSelectedEROSigner.value,
                    label: tempSelectedEROSigner.label,
                    isEnableSignatureDelegation: tempSelectedEROSigner.isEnableSignatureDelegation,
                    eroImage: tempSelectedEROSigner.eroImage
                }
            });
        }
    }

    componentWillReceiveProps(nextProps: OrganizerTabProps) {
        if (nextProps.pages.length === 0) {
            this.setState({
                currentPage: 0
            })
        }
        else if (nextProps.pages.length > 0 &&
            nextProps.pages.length != this.props.pages.length) {
            this.onSelectFirstPage(nextProps.pages);
        }
    }

    componentDidUpdate() {
        this.setReferences();
    }


    shouldComponentUpdate(nextProps: OrganizerTabProps, nextState: OrganizerTabState) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage !== INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    toggleBookmarkExpand = () => {
        this.setState(prevState => ({
            bookmarkExpand: !prevState.bookmarkExpand
        }));
    }


    onPaginationClick = (pageNo: number) => {
        this.setState({ currentPage: this.props.pages[pageNo - 1] });
    }

    onZoom = (enable: boolean) => {
        this.setState({ zoomEnabled: enable });
    }

    onPageSelect = (pageNo: number) => {
        this._viewPanel.gotoPage(pageNo);
        this.setState({
            currentPage: pageNo,
            currentPageNo: this.props.pages.indexOf(pageNo) + 1
        });

    }

    onPageChange = (pageProps: PageProperties) => {
        this._toolbar.handleZoomChange(this.state.scale);
        this.setState({
            currentPage: pageProps.page,
            currentPageNo: this.props.pages.indexOf(pageProps.page) + 1,
        }, () => {
            this._viewPanel.updateControlState(pageProps);
        });
    }

    onFirstPage = () => {
        this._viewPanel.gotoPage(this.props.pages[0]);
        this.setState({
            currentPage: this.props.pages[0],
            currentPageNo: 1
        });
    }

    onLastPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.length - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.length - 1],
            currentPageNo: this.props.pages.length
        });
    }

    onNextPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage) + 2
        });
    }

    onPreviousPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage)
        });
    }

    onGotoPage = (pageNo: number) => {
        this._viewPanel.gotoPage(this.props.pages[pageNo - 1]);
        this.setState({
            currentPage: this.props.pages[pageNo - 1],
            currentPageNo: pageNo
        });
    }


    onSelectFirstPage = (newPages: number[]) => {
        this._viewPanel.gotoPage(newPages[0]);
        this.setState({
            currentPage: newPages[0],
            currentPageNo: 1
        });
    }

    UpdateSignStatus = () => {
        let bookmarks = this.props.bookmarks;
        let page = bookmarks.pages[this.state.currentPageNo - 1];
        let currentSignatureState = page.enableSignature;
        let currentPageNo = this.state.currentPage;
        if (currentSignatureState) {
            const signatureControls: Control[] = this.props.signatureControls[this.state.currentPage];
            if (signatureControls && signatureControls.length) {
                signatureControls.forEach((control: Control) => { this._controlLayer.removeControl("control_" + control.id); });
            }
        }
        this.props.updatesignatureStatus && this.props.updatesignatureStatus(currentPageNo, !currentSignatureState);
    }


    createMoveTo = () => {
        const { hideMoveTo, moveToGroup, engagementLetterExists, readOnlyView, bookmarks } = this.props;
        const { currentPageNo } = this.state;
        const page = bookmarks.pages[currentPageNo - 1];
        let signatureEnabled = page.enableSignature;
        const deleteConfirmationPopover = (
            <Popover id="popover-to-delete-confirm" style={{ fontSize: '14px' }}>
                <Popover.Title as="h3" style={{ fontSize: '14px' }}>Confirm Delete</Popover.Title>
                <Popover.Content>
                    {"Are you sure you want to move this page to deleted?"}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={() => { this.handleMoveToDelete(signatureEnabled ? GroupType.OrganizerWithSignature : GroupType.Organizer) }}
                            title="Yes"
                            style={{ marginLeft: "5px" }}>
                            Yes
                        </button>
                        <button
                            onClick={this.hideMoveToDeletePopover}
                            title="No"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                        </button>
                    </div>
                </Popover.Content>
            </Popover>);
        return <div>{readOnlyView ? null : <ButtonGroup className="zIndex10"
            style={{ visibility: `${hideMoveTo ? 'hidden' : 'visible'}` as React.CSSProperties }} >
            <Dropdown
                show={this.state.moveToToggle}
                onToggle={this.toggleMoveToDropDown}
                rootClose>
                <Dropdown.Toggle
                    style={{ zIndex: 10000 }}
                    id="move-to-dropdown"
                    variant="warning"
                    pullRight={true}>
                    Move To
                </Dropdown.Toggle>

                <Dropdown.Menu rootCloseEvent="click">
                    {
                        (readOnlyView) ? "" : (
                            engagementLetterExists &&
                            <Dropdown.Item eventKey={GroupType.Engagement}

                                onClick={() => { moveToGroup && moveToGroup(signatureEnabled ? GroupType.OrganizerWithSignature : GroupType.Organizer, GroupType.Engagement, this.state.currentPage) }} href="#"
                                style={{ fontSize: '14px' }}>
                                <i className='fas fa-file-alt ddl-icon'
                                    style={{ marginLeft: '0px' }}
                                ></i>Engagement Letter
                            </Dropdown.Item>)
                    }

                    {
                        <div>
                            <Dropdown.Item eventKey={GroupType.Removed}
                                onClick={(e: React.MouseEvent) => {
                                    this.setState({ showDeleteConfirmationPopover: true });
                                    e.stopPropagation();
                                }}
                                onSelect={this.toggleMoveToDropDown}
                                style={{ fontSize: '14px' }}
                                ref={this.attachMoveToDeletePopoverRef} href="#"
                            >
                                <i className='fas fa-trash ddl-icon'
                                    style={{ marginLeft: '0px' }}>
                                </i>Delete
                            </Dropdown.Item>
                            <Overlay rootClose={true} onHide={this.hideMoveToDeletePopover}
                                target={this.state.deletePopoverTarget} placement="left"
                                show={this.state.showDeleteConfirmationPopover}
                            >
                                {deleteConfirmationPopover}
                            </Overlay>

                        </div>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>}
        </div>
    }

    private toggleMoveToDropDown = (eventKey: any, event: Object) => {
        if (eventKey === GroupType.Removed.toString()) {
            this.setState({ moveToToggle: true })
        }
        else {
            this.setState({ moveToToggle: !this.state.moveToToggle })
        }
        this._toolbar.forceUpdate();
    }

    private attachMoveToDeletePopoverRef = (target: any) => {
        this.setState({ deletePopoverTarget: target });
    };

    private handleMoveToDelete = (source: GroupType) => {
        if (this.props.moveToGroup) {
            this.props.moveToGroup(source, GroupType.Removed, this.state.currentPage);
            this.hideMoveToDeletePopover();
        }
    }

    private hideMoveToDeletePopover = () => {
        this.setState({ showDeleteConfirmationPopover: false });
        this._toolbar.forceUpdate();
    }

    toggleClientSignatureExpand = () => {
        this.setState(prevState => ({
            clientSignatureExpand: !prevState.clientSignatureExpand
        }));
    }

    dragEnd = (event: any) => {
        const element = document.getElementById("dragging-element");
        if (element) {
            element.remove();
        }
    }

    dragStartSignatureControl = (event: any, controlType: ControlType, dataType: DataType, signerId: number, role: ControlRole) => {
        this.registerDropEvents();
        event.dataTransfer.setData('controlType', controlType.toString());
        event.dataTransfer.setData('dataType', dataType.toString());
        event.dataTransfer.setData('signerId', signerId);
        event.dataTransfer.setData('controlRole', role);

        const element = document.createElement("div");
        element.id = "dragging-element";
        element.style.position = "absolute";
        element.style.top = "-1000px";

        const controlText = getControlText(controlType, dataType);
        const icon = getControlIcon(controlType, dataType);
        element.innerHTML = '<p style="border-style: solid; border-width: 1px;"><i class="'
            + icon + '" style="padding: 5px; background: rgb(240, 240, 241);"></i><label style="font-size: '
            + (12 * this.state.scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px;">'
            + controlText + '</label></p>';

        document.body.appendChild(element);
        event.dataTransfer.setDragImage(element, 0, 0);
    }

    onSignerChange = (singerId: number) => {
        const selectedClient = this.props.organizerClients.find(client => client.id == singerId);
        if (selectedClient) {
            this.setState({
                seletedSigner: {
                    value: selectedClient.id,
                    label: selectedClient.name,
                    disabled: selectedClient.isDeceased,
                    role: selectedClient.clientType == OrganizerClientType.Taxpayer ? ControlRole.Taxpayer : ControlRole.Spouse
                }
            })
        }
    }

    onFocusChange = (focusGroup: UserGroup) => {
        this.setState({
            focusGroup: focusGroup
        });
    }
    handleEROSignerChange = (selectedOption: IEROSigner) => {
        if (selectedOption && selectedOption.eroImage !== "") {
            this.setState({
                selectedEROSigner: {
                    value: selectedOption.value,
                    label: selectedOption.label,
                    isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
                    eroImage: selectedOption.eroImage
                }
            });
        }
    }

    private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
        this.setState({
            focusedGroup: focusedGroup
        });
    }

    dragStartEroControl = (event: any, controlType: ControlType, signatureRole: ControlRole, dataType: DataType, signerId: number) => {
        if (this.state.selectedEROSigner.eroImage != "") {
            this.registerDropEvents();
            event.dataTransfer.setData('controlType', controlType.toString());
            event.dataTransfer.setData('dataType', dataType.toString());
            event.dataTransfer.setData('signerId', signerId);
            event.dataTransfer.setData('controlRole', signatureRole);
            let element = document.createElement("div");
            element.id = "dragging-element";
            element.style.position = "absolute";
            element.style.top = "-1000px";
            element.innerHTML = '<p class="esignEROStamp" style="border-style: solid; border-width: 1px; max-height: '
                + (22 * this.state.scale).toString() + 'px; font-size: '
                + (12 * this.state.scale).toString() + 'px; font-weight: normal; padding: 0px 5px 0px 5px; overflow: hidden;">'
                + this.state.selectedEROSigner.label + '</p>';

            document.body.appendChild(element);
            event.dataTransfer.setDragImage(element, 0, 0);
        }
    }

    toggleSenderSignatureExpand = () => {
        this.setState(prevState => ({
            senderSignatureExpand: !prevState.senderSignatureExpand
        }));
    }


    public getCurrentPageSize = (): PageSize => {
        const { currentPage } = this.state;
        if (this._viewPanel) {
            return this._viewPanel.getPageSize(currentPage, 1);
        }
        else {
            return PageSize.create(PDF_VIEWER_BASE_HEIGHT, PDF_VIEWER_BASE_WIDTH);
        }
    }

    onDragStop = (id: string, top: number, left: number) => {
        const draggedControl = this.props.signatureControls[this.state.currentPage].filter(x => x.id == id)[0];
        let newControl = { ...draggedControl };
        const pageSize: PageSize = this.getCurrentPageSize();
        newControl.boundingRectangle.left = ControlBase.getBackendControlLeftPosition(left);
        newControl.boundingRectangle.top = ControlBase.getBackendControlTopPosition(pageSize.height,
            top + getTopAdjustmentSignatureControlValue(newControl.controlType));
        this.onControlUpdate(draggedControl, newControl);
    }

    createControls = () => {
        const { currentPage } = this.state;
        const controlCollection: any[] = [];
        this._controlList = [];
        const eroSignHighlightStyle = "ero-sign-highlight";
        const signatureControls: Control[] = this.props.signatureControls[currentPage];
        if (signatureControls && signatureControls.length) {
            signatureControls
                .forEach((control: Control) => {
                    const pageSize: PageSize = this.getCurrentPageSize();
                    const pdfViewerTop = ControlBase.getPdfViewerControlTopPosition(pageSize.height, control.boundingRectangle.top) - getTopAdjustmentSignatureControlValue(control.controlType);
                    const pdfViewerLeft = ControlBase.getPdfViewerControlLeftPosition(control.boundingRectangle.left);
                    const height = control.boundingRectangle.height == 0 ? 30 : control.boundingRectangle.height;
                    const width = control.boundingRectangle.width == 0 ? 150 : control.boundingRectangle.width;
                    const top = pdfViewerTop;
                    const left = pdfViewerLeft;
                    const controlText = getControlText(control.controlType, control.dataType);
                    if (control.controlRole == ControlRole.Ero)
                        controlCollection.push(
                            <PlaceholderControl
                                height={(height * this.state.scale)}
                                id={"control_" + control.id}
                                key={"control_key_" + control.id}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                width={(width * this.state.scale)}
                                top={pdfViewerTop}
                                left={pdfViewerLeft}
                                helptext={control.tooltip}
                                disabled={false}
                                isRequired={control.required}
                                draggable={true}
                                onDragStop={(top: number, left: number) => { this.onDragStop(control.id, top, left) }}>
                                <div className={`esignEROStamp ${eroSignHighlightStyle}`} style={{ height: "100%", width: "100%" }}>
                                    {this.state.selectedEROSigner.eroImage && this.state.selectedEROSigner.eroImage.length > 1 ?
                                        <img src={this.state.selectedEROSigner.eroImage} draggable="false" /> : null}
                                </div>
                                <span
                                    onClick={() => { this.onDeleteControl(control.id) }}
                                    aria-disabled={false}
                                    className="remove-handle glyphicon glyphicon-remove"
                                    style={{ background: "#000", color: "#fff", cursor: 'pointer', fontSize: `${16 * this.state.scale}px`, float: "right", lineHeight: 1, margin: "-10% auto auto -10%", position: "relative" }}></span>
                            </PlaceholderControl>);
                    else
                        controlCollection.push(
                            <PlaceholderControl
                                height={(height * this.state.scale)}
                                id={"control_" + control.id}
                                key={"control_key_" + control.id}
                                ref={(ref) => {
                                    this._controlList.push(ref)
                                }}
                                page={currentPage}
                                width={(width * this.state.scale)}
                                top={top}
                                left={left}
                                name={controlText}
                                helptext={control.tooltip}
                                disabled={this.props.readOnlyView}
                                isRequired={control.required}
                                draggable={(control.controlType === ControlType.SignatureCheckBoxControl ||
                                    control.controlType === ControlType.SignatureRadioButtonControl) ? false : !this.props.readOnlyView}
                                onLoad={this.onControlLoad}
                                onDragStop={(top: number, left: number) => { this.onDragStop(control.id, top, left) }}>

                                {this.getSignatureControlContent(control, controlText)}
                            </ PlaceholderControl>);
                });
        }
        return controlCollection;
    }

    onControlLoad = (control: any, pageElement: any) => {
        if (control.draggable === false) {
            this.applyControlStyles(control);
            this.makeDraggableControl(control, pageElement, this.handleControlDragEnd);
            this.registerResizeEvent(control, this.handleResize);
        }
    }

    applyControlStyles = (control: any) => {
        const controlElement = document.getElementById(control.id);
        if (controlElement) {
            controlElement.removeAttribute('class');
            controlElement.classList.add('choosable-control-group');
            controlElement.classList.add('resizable-both');
            const selectedSignerStyle = controlElement.children[0]?.getAttribute('data-signerstyle');
            controlElement.classList.add(selectedSignerStyle ? selectedSignerStyle : 'choosable-signature-control-border');
        }
    }

    private makeDraggableControl = (control: any, pageElement: any, dragEndCallBack: any) => {
        const draggie = new Draggabilly('#' + control.id, {
            containment: pageElement,
            handle: '.draggable-btn-control'
        });

        draggie.off('dragEnd', '#' + control.id);
        draggie.on('dragEnd', function (event: any, pointer: any) {
            event = event;
            pointer = pointer;
            const controlElement = document.getElementById(control.id);
            controlElement &&
                dragEndCallBack(control.id.replace(SignatureControlConstants.ControlIdPrefix, ''),
                    controlElement.offsetTop, controlElement.offsetLeft)
        });
        this.setDraggableControlItems(this.handleControlItemsDragEnd);
    }

    setDraggableControlItems = (dragEndCallBack: any, handle?: string): any => {

        const elements: any = document.querySelectorAll('.choosable-control-item');
        for (let i = 0; i <= elements.length - 1; i++) {

            const element: any = elements[i];
            const draggable = element.attributes["data-draggable"]?.value;

            if (!draggable) {
                element.setAttribute("data-draggable", "true");
                const draggie = new Draggabilly(element, {
                    containment: element.parentElement,
                    handle: handle
                });

                draggie.off('dragEnd', element);
                draggie.on('dragEnd', function (event: any, pointer: any) {
                    event = event;
                    pointer = pointer;

                    if (element) {
                        const id: string = element.attributes["data-id"].value;
                        const controlGuid = element.attributes["data-controlguid"].value;

                        dragEndCallBack(id,
                            controlGuid.replace(SignatureControlConstants.ControlIdPrefix, ''),
                            element.offsetTop, element.offsetLeft)
                    }
                });
            }
        };
    }

    private handleControlItemsDragEnd = (id: string, controlGuid: string, top: number, left: number) => {
        const { currentPage } = this.state;
        let controls = this.props.signatureControls[currentPage];
        const index = controls.findIndex(x => x.id === controlGuid);
        if (index != -1) {
            let control = controls[index] as SignatureRadioButtonControl | SignatureCheckBoxControl;
            if (control) {
                let itemIndex = control.items.findIndex(x => x.id == id);
                if (itemIndex != -1) {
                    control.items[itemIndex].left = left;
                    control.items[itemIndex].top = top;
                    this.onUpdateControlData(control);
                }
            }
        }
    }

    private handleControlDragEnd = (controlGuid: string, top: number, left: number) => {
        const { currentPage } = this.state;
        let controls = this.props.signatureControls[currentPage];
        const index = controls.findIndex(x => x.id === controlGuid);
        if (index != -1) {
            const controltop = top / this.state.scale;
            const controlLeft = left / this.state.scale;
            const pageSize: PageSize = this.getCurrentPageSize();
            controls[index].boundingRectangle.left = ControlBase.getBackendControlLeftPosition(controlLeft);
            controls[index].boundingRectangle.top = ControlBase.getBackendControlTopPosition(pageSize.height,
                controltop + getTopAdjustmentSignatureControlValue(controls[index].controlType));
            let control = controls[index] as SignatureRadioButtonControl | SignatureCheckBoxControl;
            this.onUpdateControlData(control);
        }
    }

    private onUpdateControlData = (control: Control) => {
        const { currentPage } = this.state;
        const draggedControl = this.props.signatureControls[currentPage]
            .filter(c => c.id == control.id)[0];
        draggedControl.boundingRectangle.left = control.boundingRectangle.left;
        draggedControl.boundingRectangle.top = control.boundingRectangle.top;
        this.onControlUpdate(draggedControl, control)
    }

    public handleResize = (controlGuid: string, height: number, width: number) => {
        const { currentPage } = this.state;
        let controls = this.props.signatureControls[currentPage];
        const index = controls.findIndex(x => x.id === controlGuid);
        if (index != -1) {
            let control = controls[index] as SignatureRadioButtonControl | SignatureCheckBoxControl;
            control.boundingRectangle.height = height;
            control.boundingRectangle.width = width;
            this.onUpdateControlData(control);
        }
    }

    registerResizeEvent = (control: any, onResize: any): any => {
        const element = document.getElementById(control.id);
        if (element) {
            element.onmouseup = function (event: any) {
                event = event;
                onResize(control.id.replace(SignatureControlConstants.ControlIdPrefix, ''),
                    element.offsetHeight, element.offsetWidth);
            };
        }
    }

    getSignatureControlContent = (control: Control, controlText: string): (JSX.Element) => {
        const controlContent =
            control.controlType === ControlType.SignatureRadioButtonControl
                || control.controlType === ControlType.SignatureCheckBoxControl ? (
                this.choosableControlGroup(control, this.getSelectedStyle(control.controlRole, control.id))
            ) : (
                <div className={'draggable-signature-control ' + this.getSelectedStyle(control.controlRole, control.id)}
                    style={{ height: "100%", width: "auto", fontSize: `${16 * this.state.scale}px` }} title=''>
                    <i className={getControlIcon(control.controlType, control.dataType)}
                        style={{ padding: '5px', background: '#f0f0f1' }}></i>{' '}
                    {controlText}
                    {
                        (control.controlType === ControlType.Signature || (control.controlType === ControlType.Textbox && control.dataType === DataType.Text)) &&
                        <i
                            className='edit-handle fas fa-edit'
                            onClick={() => { this.showControlPropertyPopup(control) }}
                            title=''
                        ></i>
                    }
                    {
                        <span
                            onClick={() => { this.onDeleteControl(control.id) }}
                            aria-disabled={false}
                            className="remove-handle glyphicon glyphicon-remove"
                            style={{ background: "#000", color: "#fff", cursor: 'pointer', fontSize: `${16 * this.state.scale}px`, float: "right", lineHeight: 1, margin: "auto auto auto -10%", position: "relative" }}
                            title=''></span>
                    }
                </div>
            )
        return controlContent;
    }

    private choosableControlGroup = (control: any, selectedSignStyle: any) => {
        let choosableControl = control as SignatureRadioButtonControl | SignatureCheckBoxControl;
        return <div id={'choosable_control_' + control.id} data-id={control.id}
            data-signerstyle={selectedSignStyle}
            className={'choosable-signature-control'}
            style={{
                height: "100%",
                width: "100%",
            }}
            title=''>
            {this.createChoosableControls(choosableControl, control.id, choosableControl.items)}
            {this.getControlActions(control)}
            <div className={"radio-group-control-footer"} title=''>
                <button id={control.id + '_draggable-btn'}
                    className={"btn btn-default btn-xs draggable-btn-control"}>
                    <span className={"fas fa-arrows-alt"} title=''></span>
                </button>
            </div>
        </div>
    }

    private createChoosableControls = (control: Control, controlId: string, data: ChoosableControlData[]) => {

        let controls: any = [];
        let icon = getControlIcon(control.controlType, control.dataType);

        data.forEach(function (control: ChoosableControlData) {
            controls.push(<div id={control.id} key={"key_" + control.id}
                data-id={control.id} data-controlguid={controlId} className={"radio-btn-container choosable-control-item"} style={{
                    left: control.left + "px", top: control.top + "px"
                }} title=''>
                <i className={icon} style={{ padding: '2px', background: '#f0f0f1' }} title=''></i>
            </div>);
        });
        return controls;
    }

    private getControlActions = (control: Control) => {
        switch (control.controlType) {
            case ControlType.SignatureCheckBoxControl:
            case ControlType.SignatureRadioButtonControl:
                return <><span
                    onClick={() => {
                        this.showControlPropertyPopup(control)
                    }}
                    aria-disabled={!this.props.isAssignedToLoggedinUser}
                    className="edit-handle fas fa-edit"
                    style={{ cursor: 'pointer' }}
                    title=''
                ></span>
                    <span
                        onClick={() => {
                            this.onDeleteControl(control.id)
                        }}
                        aria-disabled={!this.props.isAssignedToLoggedinUser}
                        className="remove-handle glyphicon glyphicon-remove"
                        style={{ cursor: 'pointer' }}
                        title=''
                    ></span></>
            case ControlType.Textbox:
                {
                    if (control.controlType == ControlType.Textbox && control.dataType == DataType.Text) {
                        return <><span
                            onClick={() => {
                                this.showControlPropertyPopup(control)
                            }}
                            aria-disabled={!this.props.isAssignedToLoggedinUser}
                            className="edit-handle fas fa-edit"
                            style={{ cursor: 'pointer' }}
                            title=''
                        ></span>
                            <span
                                onClick={() => {
                                    this.onControlRemove(control)
                                }}
                                aria-disabled={!this.props.isAssignedToLoggedinUser}
                                className="remove-handle glyphicon glyphicon-remove"
                                style={{ cursor: 'pointer' }}
                                title=''
                            ></span></>
                    }
                    else {
                        return <>
                            <span
                                onClick={() => {
                                    this.onControlRemove(control)
                                }}
                                aria-disabled={!this.props.isAssignedToLoggedinUser}
                                className="remove-handle glyphicon glyphicon-remove"
                                style={{ cursor: 'pointer' }}
                                title=''
                            ></span>
                        </>
                    }
                }
            default:
                return <>
                    <span
                        onClick={() => {
                            this.onControlRemove(control)
                        }}
                        aria-disabled={!this.props.isAssignedToLoggedinUser}
                        className="remove-handle glyphicon glyphicon-remove"
                        style={{ cursor: 'pointer' }}
                    ></span>
                </>
        }
    }

    private showControlPropertyPopup = (control: Control) => {
        this.setState({
            toolTipControl: control,
            showToolTipPopup: true,
            signatureControlType: control.controlType,
            signatureDataType: control.dataType,
            content: this.getTooltipContent(control.controlType)
        });
    }

    private getTooltipContent = (controlType: number): any => {
        const { SignatureCheckBoxControl, SignatureRadioButtonControl, Signature } = ControlType;
        switch (controlType) {
            case SignatureCheckBoxControl:
                return SignaturePanelConstants.CheckBoxControl;
            case SignatureRadioButtonControl:
                return SignaturePanelConstants.RadioButtonControl;
            case Signature:
                return SignaturePanelConstants.Signature;
            default:
                return SignaturePanelConstants.Default;
        }
    }

    getSelectedStyle = (role: ControlRole, id: string) => {
        if (role == this.state.seletedSigner.role && !this.props.readOnlyView) {
            ControlBase.focusControl('custom_control_' + id, 'organizer-taxpayer-highlight');
            return 'organizer-taxpayer-highlight';
        }
        else {
            ControlBase.removeFocus('custom_control_' + id, 'organizer-taxpayer-highlight');
            return '';
        }
    }

    onControlRemove = (control: Control) => {
        this._controlLayer.removeControl(control.id);
        this.props.onControlRemove(control, this.state.currentPage);
    }

    onControlUpdate = (oldControl: Control, newControl: Control) => {
        this.props.onControlUpdate(oldControl, newControl, this.state.currentPage);
    }

    private onDeleteControl = (id: string) => {
        const control: any = this.props.signatureControls[this.state.currentPage].find(x => x.id === id);
        this._controlLayer.removeControl("control_" + id);
        this.props.onControlRemove(control, this.state.currentPage);
    }

    getSignatureControls = (): PdfPageSignatureControl => {
        const signatureControls: Control[] = this.props.signatureControls[this.state.currentPage];
        return {
            signatureControls: signatureControls,
            signerId: this.state.seletedSigner.value,
            focusGroup: this.state.focusGroup
        };
    }

    registerDropEvents = () => {
        const _this = this;
        const elements = document.getElementsByClassName("page");
        for (var i = 0; i <= elements.length - 1; i++) {
            const tmpElement: any = elements[i];
            this._toolbar.handleZoomChange(1);
            tmpElement.ondrop = function (ev: any) {
                ev.preventDefault();
                const tmpPageElement = ev.target.offsetParent;
                if (tmpPageElement.attributes["data-page-number"]) {
                    _this.dropSignatureControl(ev);
                }
            };
            tmpElement.ondragover = function (ev: any) {
                ev.preventDefault();
            };
        }
    }

    dropSignatureControl = (event: any) => {

        let controlType = event.dataTransfer.getData('controlType');
        let dataType = event.dataTransfer.getData('dataType');
        const signerId = event.dataTransfer.getData('signerId');
        const role = event.dataTransfer.getData('controlRole');

        if (controlType && dataType && signerId) {
            controlType = parseInt(controlType);
            dataType = parseInt(dataType);
            if (!Number.isNaN(controlType) && !Number.isNaN(dataType)) {
                this.addSignatureControl(controlType, dataType, event.offsetX, event.offsetY, signerId, role);
            }
        }
    }

    addSignatureControl = (type: ControlType, dataType: DataType, clientX: number, clientY: number, signerId: number, role: ControlRole) => {
        const { currentPage, pageHeight } = this.state;
        const left = ControlBase.getBackendControlLeftPosition(clientX);
        const top = getControlTopPositionPDFJS(currentPage, clientY, 1);

        const control: Control = getControl(type, dataType, left, top);
        control.controlRole = role;
        control.id = Guid.newGuid().toString();
        control.signerId = signerId;
        if (type != ControlType.SignatureCheckBoxControl && type != ControlType.SignatureRadioButtonControl) {
            control.boundingRectangle = {
                left: left,
                top: top,
                width: 150,
                height: 30
            }
        }
        const { Textbox, SignatureCheckBoxControl, SignatureRadioButtonControl, Signature } = ControlType;
        const showControlPopup = ControlRole.Ero !== Number(control.controlRole) && [SignatureCheckBoxControl, SignatureRadioButtonControl, Signature].includes(control.controlType);
        if ([Textbox].includes(control.controlType) && dataType == DataType.Text) {
            this.showControlPropertyPopup(control);
            this.props.onSaveToolTip(control, this.state.currentPage);
        }
        else if (showControlPopup) {
            if (Signature === type) {
                control.required = true;
            }
            this.setControlDefaultTooltipValue(control);
            this.showControlPropertyPopup(control);
            this.props.onSaveToolTip(control, this.state.currentPage);
        }
        else {
            this.props.onDrop(control, this.state.currentPage);
        }
    }

    adjustSamePositionControl = (control: Control): Control => {
        let signatureControls = this.props.signatureControls[this.state.currentPage];
        if (!signatureControls) {
            signatureControls = [];
        }
        const index = signatureControls.findIndex(x => x.id === control.id);
        if (index !== -1) {
            control.boundingRectangle.left += 5;
            control.boundingRectangle.top += 5;
            return this.adjustSamePositionControl(control);
        }
        else {
            return control;
        }
    }

    private setControlDefaultTooltipValue = (control: Control) => {
        const { SignatureCheckBoxControl, SignatureRadioButtonControl } = ControlType;
        if (control.controlType === SignatureCheckBoxControl) {
            control.tooltip = "1";
        } else if (control.controlType === SignatureRadioButtonControl) {
            control.tooltip = "2";
        }
    }

    private onCancelToolTipPopup = () => {
        this.setState({
            toolTipControl: initialControl,
            showToolTipPopup: false,
            signatureControlType: 0,
            signatureDataType: DataType.None
        });
    }

    private onUpdateToolTipPopupControl = (control: SignatureCheckBoxControl | SignatureRadioButtonControl | Control) => {
        this.setState({
            toolTipControl: control
        });
    }

    render() {
        const { pages, bookmarks, readOnlyView, pdfURL, organizerClients, isBatchOrganizer } = this.props;
        const { bookmarkExpand, currentPageNo, clientSignatureExpand, seletedSigner } = this.state;

        let page = bookmarks.pages[currentPageNo - 1];
        let isSourceDocument = (page.enabledSourceDocument && this.props.sourceDocumentEnabled);
        let signatureEnabled = page.enableSignature;

        const toolText = isSourceDocument ?
            BookMarkTitleConstants.OrganizerTab.ESignDisabled :
            (
                (!isSourceDocument && !signatureEnabled) ?
                    BookMarkTitleConstants.OrganizerTab.SignToggleDisabledTitle : ""
            );

        const recievers = organizerClients?.map(client => {
            return {
                value: client.id,
                label: client.name,
                disabled: client.isDeceased,
                role: client.clientType == OrganizerClientType.Taxpayer ? ControlRole.Taxpayer : ControlRole.Spouse
            } as SignerDropDown
        });

        return (<>
            <PdfViewer>
                <Header>

                    <Toolbar
                        ref={(ref: any) => this._toolbar = ref}
                        customToolbarOptions={this.createMoveTo()}
                        hideReadOnly={true}
                        hideRightPanel={isBatchOrganizer}>

                        <Pagination
                            currentPage={currentPageNo}
                            onFirstPage={this.onFirstPage}
                            onNextPage={this.onNextPage}
                            onPreviousPage={this.onPreviousPage}
                            onLastPage={this.onLastPage}
                            onGotoPage={this.onGotoPage}
                            totalPages={pages.length}
                        >

                        </Pagination>
                        <Zoom>
                        </Zoom>
                        <CustomOptions position="left" />
                    </Toolbar>
                </Header>
                <div className="main" style={{
                    height: 'calc(100vh - 250px)',
                    fontSize: '12px'
                }}>
                    <LeftPanel>
                        <OrganizerTabBookmarkPanel
                            expanded={bookmarkExpand}
                            toggleExpand={this.toggleBookmarkExpand}
                            bookmarks={bookmarks}
                            onPageSelect={this.onPageSelect}
                            isBatchOrganizer={isBatchOrganizer}
                        />
                    </LeftPanel>

                    {(!isBatchOrganizer && signatureEnabled) && <ControlLayer enableControlsLazyLoad={false} ref={(ref: any) => { this._controlLayer = ref; }} useDefaultNavigationStartControl={false} >
                        {
                            this.createControls()
                        }
                    </ControlLayer>
                    }
                    <ViewPanel onDocumentLoad={() => {
                        this.setState({
                            scale: 1
                        })
                    }}
                        ref={(ref: any) => this._viewPanel = ref}

                        onPageChanged={this.onPageChange}
                        onScaleChanged={(scale: number) => {
                            this.setState({
                                scale: scale
                            })
                        }}
                        pageMode={PageMode.SinglePage}
                        defaultPage={bookmarks.pages[0].pageNo}
                        pdfSource={PdfSource.createFromUrl(this.props.pdfURL)}
                        disableTextLayer={true}
                    >
                    </ViewPanel>
                    {!isBatchOrganizer ?
                        <RightPanel>
                            <div className="form-row margin-top-7 margin-bottom-5" >
                                <div className="col-12">
                                    <label className="text-left form-label organizer-sign-lable">
                                        <b>{BookMarkTitleConstants.OrganizerTab.SignToggleLabel}</b>
                                    </label>
                                    <ToggleSwitch
                                        title={toolText}
                                        switched={signatureEnabled}
                                        disabled={isSourceDocument}
                                        handleChange={this.UpdateSignStatus}
                                        size="S"
                                        className="margin-top-7 margin-left-10"
                                    />
                                </div>

                            </div>
                            {signatureEnabled &&
                                <>
                                    <SignaturePanel
                                        expanded={clientSignatureExpand}
                                        group={UserGroup.Reciever}
                                        title={"e-Signatures"}
                                        toggleExpand={this.toggleClientSignatureExpand}
                                        onDragEnd={this.dragEnd}
                                        onDragStart={this.dragStartSignatureControl}
                                        onSignerChange={(id: any) => { this.onSignerChange(id) }}
                                        selectedSigner={seletedSigner}
                                        signers={recievers}
                                        onFocusChange={this.onFocusChange}
                                        addCustomInputControls={true}                        >
                                    </SignaturePanel>

                                    {this.props.company.companySettings?.signatureSettingsModel?.useSignatureStamp &&
                                        <EROSignatureCard
                                            title="ERO Signature Stamp"
                                            selectedEROSigner={this.state.selectedEROSigner}
                                            eroSigner={this.state.eroSigners}
                                            onEROSignerChange={this.handleEROSignerChange}
                                            isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                                            onSignatureControlFocus={this.onSignatureControlFocus}
                                            dragStart={this.dragStartEroControl}
                                            dragEnd={this.dragEnd}
                                            signerId={this.state.seletedSigner?.value}
                                            group={UserGroup.Sender}
                                            onFocusChange={this.onFocusChange}
                                            toggleExpand={this.toggleSenderSignatureExpand}
                                        />
                                    }
                                </>
                            }
                        </RightPanel> : <></>};
                </div>
            </PdfViewer>
            <SignerTooltipPopup
                control={this.state.toolTipControl}
                showState={this.state.showToolTipPopup}
                onHide={this.onCancelToolTipPopup}
                onUpdateToolTipControl={this.onUpdateToolTipPopupControl}
                submitButtonClick={this.props.onSaveToolTip}
                initialControl={initialControl}
                content={this.state.content}
                controlType={this.state.signatureControlType}
                dataType={this.state.signatureDataType}
                pageNo={this.state.currentPage}
                documentGuid=""
            />
        </>
        );
    }
}