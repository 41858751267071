import React from 'react'
import { useSelector } from 'react-redux'

import { ApplicationState } from '../store'

const LoggedOut: React.FC = ({ children }) => {
    const user = useSelector((state: ApplicationState) => state.auth.user)
    return user ? <></> : <>{children}</>
}

LoggedOut.displayName = 'LoggedOut'

export default LoggedOut
