import React, { HTMLProps } from "react";

export interface IconImageProps extends HTMLProps<HTMLButtonElement> {
   height?: number;
    width?: number;
    color?: string;
    disabled?: boolean;
    style?: any;
}

export const StackIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "16"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
    >
        <g clipPath="url(#clip0_721_62374)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
            <g clipPath="url(#clip1_721_62374)">
                <path
                    fill="#fff"
                    d="M3-.001h10a2 2 0 012 2v3a2 2 0 01-2 2H3a2 2 0 01-2-2v-3a2 2 0 012-2zm0 1a1 1 0 00-1 1v3a1 1 0 001 1h10a1 1 0 001-1v-3a1 1 0 00-1-1H3zm0 8h10a2 2 0 012 2v3a2 2 0 01-2 2H3a2 2 0 01-2-2v-3a2 2 0 012-2zm0 1a1 1 0 00-1 1v3a1 1 0 001 1h10a1 1 0 001-1v-3a1 1 0 00-1-1H3z"
                ></path>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_721_62374">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
            <clipPath id="clip1_721_62374">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
);
export const PlusIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "17"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 17 16"
    >
        <g fill="#fff" clipPath="url(#clip0_811_108766)">
            <path fillOpacity="0.01" d="M0 0H16V16H0z" transform="translate(.5)"></path>
            <g clipPath="url(#clip1_811_108766)">
                <path d="M8.5 14.999a7 7 0 110-14 7 7 0 010 14zm0 1a8 8 0 100-16 8 8 0 000 16z"></path>
                <path d="M8.5 3.999a.5.5 0 01.5.5v3h3a.5.5 0 010 1H9v3a.5.5 0 01-1 0v-3H5a.5.5 0 110-1h3v-3a.5.5 0 01.5-.5z"></path>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_811_108766">
                <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)"></path>
            </clipPath>
            <clipPath id="clip1_811_108766">
                <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)"></path>
            </clipPath>
        </defs>
    </svg>
);
export const DeleteIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "16"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
    >
        <g clipPath="url(#clip0_811_103541)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
            <g clipPath="url(#clip1_811_103541)">
                <path
                    fill="#05386B"
                    d="M6.5.999h3a.5.5 0 01.5.5v1H6v-1a.5.5 0 01.5-.5zm4.5 1.5v-1a1.5 1.5 0 00-1.5-1.5h-3a1.5 1.5 0 00-1.5 1.5v1H1.5a.5.5 0 000 1h.538l.853 10.66a2 2 0 001.994 1.84h6.23a2 2 0 001.993-1.84l.853-10.66h.538a.5.5 0 000-1H11zm1.957 1l-.846 10.58a1 1 0 01-.997.92h-6.23a1 1 0 01-.996-.92l-.846-10.58h9.915zm-7.486 1a.5.5 0 01.528.47l.5 8.5a.5.5 0 01-.998.06L5 5.029a.5.5 0 01.47-.53zm5.057 0a.5.5 0 01.47.53l-.5 8.5a.502.502 0 01-.72.428.5.5 0 01-.277-.488l.5-8.5a.5.5 0 01.527-.47zM8 4.499a.5.5 0 01.5.5v8.5a.5.5 0 01-1 0v-8.5a.5.5 0 01.5-.5z"
                ></path>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_811_103541">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
            <clipPath id="clip1_811_103541">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
);
export const VerticalEllipsisIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "22"}
        height={props.height ?? "22"}
        fill="none"
        viewBox="0 0 22 22"
    >
        <g clipPath="url(#clip0_811_108648)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H22V22H0z"></path>
            <path
                fill="#6B7075"
                d="M13.063 17.875a2.062 2.062 0 11-4.125 0 2.062 2.062 0 014.124 0zm0-6.875a2.062 2.062 0 11-4.125 0 2.062 2.062 0 014.124 0zm0-6.875a2.062 2.062 0 11-4.125 0 2.062 2.062 0 014.124 0z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_811_108648">
                <path fill="#fff" d="M0 0H22V22H0z"></path>
            </clipPath>
        </defs>
    </svg>
);
export const EyeLashIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "16"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
    >
        <g clipPath="url(#clip0_1107_45240)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z"></path>
            <g fill="#05386B" clipPath="url(#clip1_1107_45240)">
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.172 8c.483-.736 1.04-1.42 1.66-2.044C4.12 4.667 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.13 13.13 0 0114.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-1.29 1.29-3.05 2.457-5.168 2.457-2.12 0-3.88-1.168-5.168-2.457A13.13 13.13 0 011.172 8z"></path>
                <path d="M8 5.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.5 8a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0z"></path>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1107_45240">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
            <clipPath id="clip1_1107_45240">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
);
export const RestartIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "17"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 17 16"
    >
        <g clipPath="url(#clip0_1107_45590)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H16V16H0z" transform="translate(.5)"></path>
            <path
                fill="#05386B"
                fillRule="evenodd"
                d="M8.5 3a5 5 0 11-4.546 2.914.5.5 0 00-.909-.418A6 6 0 108.5 1.999v1z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#05386B"
                d="M8.5 4.465V.533A.25.25 0 008.09.34L5.73 2.307a.25.25 0 000 .384l2.36 1.966a.25.25 0 00.41-.192z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_1107_45590">
                <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)"></path>
            </clipPath>
        </defs>
    </svg>
);
export const BackIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "16"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
    >
        <path
            fill={props.color ?? "#000"}
            fillRule="evenodd"
            d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zm-4.5-.5a.5.5 0 010 1H5.707l2.147 2.146a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5z"
            clipRule="evenodd"
        ></path>
    </svg>
);
export const BackIconFilled: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "16"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
    >
        <path
            fill="#0973BA"
            d="M8 0a8 8 0 100 16A8 8 0 008 0zm3.5 7.5a.5.5 0 010 1H5.707l2.147 2.146a.501.501 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 11.708.708L5.707 7.5H11.5z"
        ></path>
    </svg>
);

export const CustomQuestionsPlaceHolderIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg width="213" height="213" viewBox="0 0 213 213" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="106.5" cy="106.5" r="106.5" fill="#E6EBF0" />
            <path
                d="M68.1947 154H120.09C123.048 153.997 125.885 152.821 127.976 150.73C130.068 148.638 131.246 145.802 131.248 142.844V74.9165C131.245 71.9585 130.068 69.1217 127.976 67.0301C125.885 64.9379 123.049 63.7615 120.09 63.7581H109.576C109.504 61.1496 108.419 58.6717 106.55 56.8495C104.681 55.0281 102.176 54.0056 99.5672 54H88.6816C86.0717 54.0057 83.566 55.0281 81.6972 56.8495C79.8282 58.6709 78.742 61.1491 78.6696 63.7581H68.1875C65.2252 63.7588 62.3848 64.9336 60.2877 67.0252C58.1911 69.1168 57.0085 71.955 57 74.9167V142.844C57.0085 145.807 58.1919 148.646 60.2906 150.736C62.3886 152.828 65.2311 154.001 68.194 154H68.1947ZM88.6834 58.7661L99.569 58.7653C100.967 58.7675 102.307 59.3233 103.296 60.3117C104.284 61.3001 104.84 62.6401 104.842 64.0383V69.2893H83.4076V64.0383C83.409 62.6401 83.9656 61.2993 84.9547 60.311C85.9437 59.3219 87.2845 58.7667 88.6834 58.7653L88.6834 58.7661ZM61.7657 74.9174C61.7728 73.2179 62.4534 71.59 63.6588 70.391C64.8634 69.1927 66.4949 68.5205 68.1951 68.5234H78.6425V71.6715C78.6425 72.9867 79.7089 74.0538 81.0248 74.0538H107.224C108.539 74.0538 109.606 72.9867 109.606 71.6715V68.5234H120.088C121.783 68.5255 123.408 69.2005 124.607 70.3988C125.805 71.5977 126.479 73.2221 126.482 74.9174V142.845C126.479 144.54 125.804 146.163 124.605 147.362C123.407 148.559 121.783 149.233 120.088 149.236H68.1949C66.4954 149.238 64.8646 148.567 63.6594 147.369C62.454 146.171 61.7733 144.545 61.7655 142.845L61.7657 74.9174Z"
                fill="#B4C3D3"
            />
            <path
                d="M75.8728 98.4725C76.3195 98.9192 76.9257 99.1709 77.5582 99.1709C78.1899 99.1709 78.7961 98.9192 79.2428 98.4725L88.2609 89.4701C89.1635 88.5349 89.15 87.0495 88.2304 86.1307C87.3108 85.2125 85.8254 85.2005 84.891 86.1037L77.5853 93.4095L74.4655 90.2897C73.5324 89.3538 72.0173 89.3517 71.0813 90.2854C70.1454 91.2185 70.1432 92.7336 71.0763 93.6697L75.8728 98.4725Z"
                fill="#B4C3D3"
            />
            <path
                d="M75.8727 117.831C76.3193 118.278 76.9256 118.529 77.558 118.529C78.1898 118.529 78.796 118.278 79.2426 117.831L88.2607 108.829C89.1916 107.898 89.1916 106.389 88.2607 105.458C87.3298 104.528 85.821 104.528 84.8908 105.458L77.5851 112.764L74.466 109.642H74.4653C73.5316 108.706 72.0157 108.704 71.0798 109.639C70.144 110.573 70.1425 112.089 71.0763 113.025L75.8727 117.831Z"
                fill="#B4C3D3"
            />
            <path
                d="M75.8728 137.183C76.3195 137.63 76.9257 137.881 77.5582 137.881C78.1899 137.881 78.7961 137.63 79.2428 137.183L88.2609 128.185C89.1918 127.254 89.1918 125.745 88.2609 124.814C87.3299 123.884 85.8212 123.884 84.891 124.814L77.5853 132.12L74.4662 129.001H74.4655C73.5324 128.065 72.0173 128.063 71.0813 128.996C70.1454 129.929 70.1432 131.445 71.0763 132.38L75.8728 137.183Z"
                fill="#B4C3D3"
            />
            <path
                d="M99.3774 94.6704H115.485C116.801 94.6704 117.867 93.604 117.867 92.2881C117.867 90.9728 116.801 89.9058 115.485 89.9058H99.3774C98.0615 89.9058 96.9951 90.9728 96.9951 92.2881C96.9951 93.604 98.0615 94.6704 99.3774 94.6704Z"
                fill="#B4C3D3"
            />
            <path
                d="M99.3774 114.028H115.485C116.801 114.028 117.867 112.962 117.867 111.646C117.867 110.331 116.801 109.264 115.485 109.264H99.3774C98.0615 109.264 96.9951 110.331 96.9951 111.646C96.9951 112.962 98.0615 114.028 99.3774 114.028Z"
                fill="#B4C3D3"
            />
            <path
                d="M99.3774 133.382H115.485C116.801 133.382 117.867 132.316 117.867 131C117.867 129.684 116.801 128.618 115.485 128.618H99.3774C98.0615 128.618 96.9951 129.684 96.9951 131C96.9951 132.316 98.0615 133.382 99.3774 133.382Z"
                fill="#B4C3D3"
            />
            <path
                d="M147.9 63.7583C145.486 63.7619 143.172 64.7233 141.466 66.4319C139.76 68.1407 138.803 70.4571 138.804 72.8713V140.716C138.803 141.478 138.98 142.229 139.321 142.911L143.511 151.29C143.916 152.106 144.543 152.792 145.317 153.271C146.092 153.75 146.986 154.003 147.898 154C148.809 154.003 149.704 153.751 150.48 153.272C151.257 152.793 151.884 152.107 152.291 151.29L156.48 142.911C156.821 142.229 156.998 141.478 156.998 140.716V72.8713C156.998 70.4571 156.041 68.1408 154.335 66.4319C152.63 64.7232 150.315 63.7618 147.9 63.7583ZM152.236 140.716H152.237C152.241 140.737 152.241 140.759 152.237 140.78L147.79 149.156L143.584 140.713V72.8707C143.552 71.7008 143.995 70.5677 144.811 69.729C145.627 68.8909 146.748 68.4173 147.918 68.4173C149.089 68.4173 150.209 68.8909 151.025 69.729C151.842 70.5677 152.284 71.7007 152.252 72.8707L152.236 140.716Z"
                fill="#B4C3D3"
            />
        </svg>
    );
};
export const CircleDeleteIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0029 20.626C8.45022 20.626 6.00206 19.6119 4.19703 17.8069C2.39199 16.0018 1.37793 13.5537 1.37793 11.001C1.37793 8.44827 2.39199 6.00011 4.19703 4.19507C6.00206 2.39004 8.45022 1.37598 11.0029 1.37598C13.5556 1.37598 16.0038 2.39004 17.8088 4.19507C19.6139 6.00011 20.6279 8.44827 20.6279 11.001C20.6279 13.5537 19.6139 16.0018 17.8088 17.8069C16.0038 19.6119 13.5556 20.626 11.0029 20.626ZM11.0029 22.001C13.9203 22.001 16.7182 20.8421 18.7811 18.7792C20.844 16.7163 22.0029 13.9184 22.0029 11.001C22.0029 8.0836 20.844 5.2857 18.7811 3.2228C16.7182 1.1599 13.9203 0.000976563 11.0029 0.000976562C8.08555 0.000976563 5.28766 1.1599 3.22476 3.2228C1.16186 5.2857 0.00292969 8.0836 0.00292969 11.001C0.00292969 13.9184 1.16186 16.7163 3.22476 18.7792C5.28766 20.8421 8.08555 22.001 11.0029 22.001Z"
                fill="#CC4A43"
            />
            <path
                d="M5.50293 11.001C5.50293 10.8186 5.57536 10.6438 5.70429 10.5148C5.83323 10.3859 6.00809 10.3135 6.19043 10.3135H15.8154C15.9978 10.3135 16.1726 10.3859 16.3016 10.5148C16.4305 10.6438 16.5029 10.8186 16.5029 11.001C16.5029 11.1833 16.4305 11.3582 16.3016 11.4871C16.1726 11.616 15.9978 11.6885 15.8154 11.6885H6.19043C6.00809 11.6885 5.83323 11.616 5.70429 11.4871C5.57536 11.3582 5.50293 11.1833 5.50293 11.001Z"
                fill="#CC4A43"
            />
        </svg>
    );
};

export const CopyIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "16"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
    >
        <g clipPath="url(#clip0_2203_448664)">
            <path
                fill="#05386B"
                d="M13 0H6a2 2 0 00-2 2 2 2 0 00-2 2v10a2 2 0 002 2h7a2 2 0 002-2 2 2 0 002-2V2a2 2 0 00-2-2zm0 13V4a2 2 0 00-2-2H5a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1zM3 4a1 1 0 011-1h7a1 1 0 011 1v10a1 1 0 01-1 1H4a1 1 0 01-1-1V4z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_2203_448664">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
);
export const ParagraphIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? "16"}
        height={props.height ?? "16"}
        fill="none"
        viewBox="0 0 16 16"
    >
        <path
            fill="#000"
            fillRule="evenodd"
            d="M2 12.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM2 9.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zM2 6.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zM6 3.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5z"
            clipRule="evenodd"
        ></path>
    </svg>
);
export const DownIcon: React.FC<IconImageProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
        <path
            fill="#212529"
            fillRule="evenodd"
            d="M1.235 3.484a.375.375 0 01.53 0L6 7.72l4.235-4.236a.374.374 0 01.612.122.377.377 0 01-.081.41l-4.5 4.5a.375.375 0 01-.531 0l-4.5-4.5a.375.375 0 010-.532z"
            clipRule="evenodd"
        ></path>
    </svg>
);
export const CreateTemplateIcon: React.FC<IconImageProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none" viewBox="0 0 60 60">
        <path
            fill="#05386B"
            d="M8.722 51.069a29.753 29.753 0 0013.367 7.87 29.654 29.654 0 0015.494.062 29.756 29.756 0 0013.428-7.765 29.952 29.952 0 007.73-13.49 30.055 30.055 0 00-.062-15.563 29.953 29.953 0 00-7.835-13.428 29.62 29.62 0 00-9.854-6.572 29.864 29.864 0 00-22.415 0A29.54 29.54 0 008.72 8.755a1.527 1.527 0 000 2.152 1.51 1.51 0 002.143 0 26.563 26.563 0 018.853-5.905 26.86 26.86 0 0120.134 0A26.791 26.791 0 0151.975 14.9a26.974 26.974 0 014.56 15.012 26.974 26.974 0 01-4.56 15.012 26.794 26.794 0 01-12.125 9.898 26.82 26.82 0 01-20.134 0 26.818 26.818 0 01-9.403-6.471 26.977 26.977 0 01-5.878-9.81 27.265 27.265 0 01-1.228-11.752 1.52 1.52 0 00-1.328-1.688 1.516 1.516 0 00-1.68 1.335 30.306 30.306 0 001.366 13.078 29.627 29.627 0 007.157 11.554z"
        ></path>
        <path
            fill="#05386B"
            d="M29.784 18.108c-.836 0-1.515.682-1.515 1.522v8.76h-8.721c-.837 0-1.515.682-1.515 1.522s.678 1.522 1.515 1.522h8.72v8.76c0 .84.677 1.52 1.514 1.52s1.514-.68 1.514-1.52v-8.76h8.722c.837 0 1.514-.681 1.514-1.522 0-.84-.677-1.521-1.514-1.521h-8.722V19.63c0-.84-.676-1.52-1.512-1.522z"
        ></path>
    </svg>
);

export const ReopenOrganizerBlueIcon: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="14"
        height="14"
        style={{ marginRight: "11px" }}
        fill="none"
        viewBox="0 0 58.001 58.001"
    >
        <defs>
            <path id="path_1" d="M0 0h58.001v58.001H0V0z"></path>
            <clipPath id="mask_1">
                <use xlinkHref="#path_1"></use>
            </clipPath>
        </defs>
        <g transform="rotate(90 29 29)">
            <path fill="#FEFEFE" fillOpacity="0" fillRule="evenodd" stroke="none" d="M0 0h58.001v58.001H0V0z"></path>
            <g clipPath="url(#mask_1)">
                <path
                    fill="#17A2B8"
                    fillRule="evenodd"
                    stroke="none"
                    d="M54.001 22.5v-10H52v-4H25l-2-4H2.732A2.736 2.736 0 000 7.233V52.5l.001-.002v.002c0 .734-.047 1 .565 1h44.759c1.156 0 2.174-.779 2.45-1.813L58.001 23.5v-1h-4zM50 10.5v2H7v2H5v-4h45zM8.001 15.914V18.5H5.415l2.586-2.586zm44 5.586h-8v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-2.269c-1.157 0-2.175.779-2.45 1.813l-5.281 14.48V20.5h6v-6h42v7z"
                ></path>
            </g>
        </g>
    </svg>
);

export const ReopenOrganizerGreyIcon: React.FC<IconImageProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={props.width ?? "18px"}
        height={props.height ?? "18px"}
        style={props.style ?? { marginRight: "6px" }}
        viewBox="0 0 58.001 58.001"
    >
        <defs>
            <path id="path_1" d="M0 0h58.001v58.001H0V0z"></path>
            <clipPath id="mask_1">
                <use xlinkHref="#path_1"></use>
            </clipPath>
        </defs>
        <g transform="rotate(90 29 29)">
            <path fill="#FEFEFE" fillOpacity="0" fillRule="evenodd" stroke="none" d="M0 0h58.001v58.001H0V0z"></path>
            <g clipPath="url(#mask_1)">
                <path
                    fill="#303641"
                    fillRule="evenodd"
                    stroke="none"
                    d="M54.001 22.5v-10H52v-4H25l-2-4H2.732A2.736 2.736 0 000 7.233V52.5l.001-.002v.002c0 .734-.047 1 .565 1h44.759c1.156 0 2.174-.779 2.45-1.813L58.001 23.5v-1h-4zM50 10.5v2H7v2H5v-4h45zM8.001 15.914V18.5H5.415l2.586-2.586zm44 5.586h-8v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-5v-3a1 1 0 10-2 0v3h-2.269c-1.157 0-2.175.779-2.45 1.813l-5.281 14.48V20.5h6v-6h42v7z"
                ></path>
            </g>
        </g>
    </svg>
);
export const FileIcon: React.FC = () => (
    <svg width="55" height="72" viewBox="0 0 55 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.8646 19.9513H37.6917C38.7018 19.9513 39.522 19.1311 39.522 18.1209C39.522 17.1107 38.7018 16.2905 37.6917 16.2905H16.8646C15.8544 16.2905 15.0342 17.1107 15.0342 18.1209C15.0342 19.1316 15.8544 19.9513 16.8646 19.9513Z"
            fill="#B4C3D3"
        />
        <path
            d="M46.942 0H10.3488C9.33865 0 8.51845 0.820193 8.51845 1.83038C8.51845 2.84057 9.33865 3.66076 10.3488 3.66076H46.942C49.0288 3.67761 50.7514 5.30313 50.8908 7.3848V64.6077C50.8883 66.6628 49.2212 68.3292 47.1668 68.3343H29.1075V52.1276C29.0707 48.0813 25.7664 44.8255 21.7201 44.8525H3.66076V7.38597C3.66076 6.37579 2.84057 5.55559 1.83038 5.55559C0.820193 5.55559 0 6.37579 0 7.38597V45.0526C0.00714998 45.7946 0.131761 46.5315 0.36618 47.2344L0.371287 47.2369C0.742056 48.4427 1.42794 49.5284 2.35542 50.3849L23.1002 69.9085C24.4863 71.2604 26.3483 72.0121 28.2838 71.9999H47.1733C51.2512 71.9948 54.5535 68.6905 54.5581 64.6124V7.26014C54.3528 3.20206 51.0066 0.0126206 46.9429 0.000461645L46.942 0ZM25.4468 67.0886L5.70359 48.5116H21.7181C23.7339 48.5065 25.3885 50.1102 25.4448 52.1259L25.4442 67.0893L25.4468 67.0886Z"
            fill="#B4C3D3"
        />
        <path
            d="M16.8646 32.2229H37.6917C38.7018 32.2229 39.522 31.4027 39.522 30.3925C39.522 29.3823 38.7018 28.5621 37.6917 28.5621H16.8646C15.8544 28.5621 15.0342 29.3823 15.0342 30.3925C15.0342 31.4027 15.8544 32.2229 16.8646 32.2229Z"
            fill="#B4C3D3"
        />
    </svg>
);

export const CloseIcon: React.FC = () => (
    <svg viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" fill="#D9D9D9" />
        <path
            d="M18.6347 19.7661C18.3223 19.4537 18.3223 18.9471 18.6347 18.6347C18.9471 18.3223 19.4537 18.3223 19.7661 18.6347L28.0004 26.869L36.2347 18.6347C36.5471 18.3223 37.0537 18.3223 37.3661 18.6347C37.6785 18.9471 37.6785 19.4537 37.3661 19.7661L29.1318 28.0004L37.3661 36.2347C37.6785 36.5471 37.6785 37.0537 37.3661 37.3661C37.0537 37.6785 36.5471 37.6785 36.2347 37.3661L28.0004 29.1318L19.7661 37.3661C19.4537 37.6785 18.9471 37.6785 18.6347 37.3661C18.3223 37.0537 18.3223 36.5471 18.6347 36.2347L26.869 28.0004L18.6347 19.7661Z"
            fill="black"
        />
    </svg>
);
export const ExpandIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" fill="none" viewBox="0 0 9 15">
        <g fill="#6B7075" fillRule="evenodd" clipRule="evenodd">
            <path d="M8.854 4.854a.5.5 0 01-.708 0L4.5 1.207.854 4.854a.5.5 0 01-.708-.708l4-4a.5.5 0 01.708 0l4 4a.5.5 0 010 .708z"></path>
            <path d="M8.854 10.146a.5.5 0 00-.708 0L4.5 13.793.854 10.146a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z"></path>
        </g>
    </svg>
);
export const CollapseIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" fill="none" viewBox="0 0 10 15">
        <g fill="#6B7075" fillRule="evenodd" clipRule="evenodd">
            <path d="M1.147.146a.5.5 0 01.707 0L5.5 3.793 9.147.146a.5.5 0 01.707.708l-4 4a.5.5 0 01-.707 0l-4-4a.5.5 0 010-.708z"></path>
            <path d="M1.132 14.855a.5.5 0 00.707 0l3.647-3.647 3.646 3.647a.5.5 0 00.707-.707l-4-4a.5.5 0 00-.707 0l-4 4a.5.5 0 000 .707z"></path>
        </g>
    </svg>
);
export const GrabIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
        <g clipPath="url(#clip0_2522_2567)">
            <path fill="#fff" fillOpacity="0.01" d="M0 0H22V22H0z"></path>
            <path
                fill="#05386B"
                d="M9.628 2.751a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm4.125 0a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zM9.628 6.876a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm4.125 0a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm-4.125 4.125a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm4.125 0a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm-4.125 4.125a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm4.125 0a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm-4.125 4.125a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0zm4.125 0a1.375 1.375 0 11-2.75 0 1.375 1.375 0 012.75 0z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_2522_2567">
                <path fill="#fff" d="M0 0H22V22H0z"></path>
            </clipPath>
        </defs>
    </svg>
);
export const YesNoQuestionIcon: React.FC = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2780_2260)">
            <path
                d="M8 14.999C11.866 14.999 15 11.865 15 7.99902C15 4.13303 11.866 0.999023 8 0.999023V7.99902C8 11.865 8 14.999 8 14.999ZM8 15.999C3.58172 15.999 0 12.4173 0 7.99902C0 3.58075 3.58172 -0.000976562 8 -0.000976562C12.4183 -0.000976562 16 3.58075 16 7.99902C16 12.4173 12.4183 15.999 8 15.999Z"
                fill="#05386B"
            />
        </g>
        <defs>
            <clipPath id="clip0_2780_2260">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
export const MCQIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
        <g fill="#05386B" clipPath="url(#clip0_2717_2699)">
            <path d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108-.001 8 8 0 008 16z"></path>
            <path d="M11 8a3 3 0 11-6 0 3 3 0 016 0z"></path>
        </g>
        <defs>
            <clipPath id="clip0_2717_2699">
                <path fill="#fff" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
);
export const PlusCircleIcon: React.FC = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
    >
        <path
            fill="#0973BA"
            d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
        ></path>
        <path
            fill="#0973BA"
            d="M8 4a.5.5 0 01.5.5v3h3a.5.5 0 010 1h-3v3a.5.5 0 01-1 0v-3h-3a.5.5 0 010-1h3v-3A.5.5 0 018 4z"
        ></path>
    </svg>
);

export const ProductAccessDeniedIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg
            width={props.width ?? 200}
            height={props.height ?? 200}
            viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="100" cy="100" r="100" fill="#E6EBF0" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M121.382 80.9565C124.178 83.7531 126.387 87.0806 127.879 90.7434C129.699 95.2353 130.39 100.105 129.887 104.926C129.385 109.746 127.707 114.37 125 118.39C122.293 122.41 118.641 125.704 114.363 127.982C110.086 130.26 105.315 131.453 100.469 131.455C94.2834 131.461 88.2526 129.525 83.2258 125.921C78.1989 122.317 74.4295 117.226 72.4485 111.366C71.0499 107.209 70.5856 102.794 71.0889 98.4366C71.1352 98.0403 71.3369 97.6787 71.6498 97.4312C71.9627 97.1837 72.3611 97.0707 72.7573 97.1169C73.1535 97.1632 73.5151 97.365 73.7626 97.6779C74.01 97.9909 74.1231 98.3893 74.0768 98.7855C73.6252 102.701 74.0422 106.668 75.298 110.403C76.4976 113.935 78.4252 117.173 80.955 119.911C81.197 119.584 81.4772 119.259 81.7852 118.951L117.713 83.0225C117.964 82.772 118.225 82.54 118.49 82.332C116.15 80.1747 113.433 78.449 110.465 77.2463C104.05 74.6653 96.8852 74.6653 90.4701 77.2463C87.1765 78.5805 84.1863 80.5662 81.6789 83.0844C81.5392 83.2241 81.3733 83.3349 81.1908 83.4105C81.0083 83.4861 80.8126 83.525 80.6151 83.525C80.4175 83.525 80.2219 83.4861 80.0393 83.4105C79.8568 83.3349 79.691 83.2241 79.5513 83.0844C79.4116 82.9446 79.3007 82.7788 79.2251 82.5962C79.1495 82.4137 79.1106 82.218 79.1106 82.0204C79.1106 81.8228 79.1495 81.6272 79.2251 81.4446C79.3007 81.2621 79.4116 81.0962 79.5513 80.9565C82.3428 78.1541 85.6712 75.944 89.3371 74.4586C96.4778 71.5811 104.455 71.5811 111.596 74.4586C115.258 75.9506 118.585 78.1599 121.382 80.9565ZM120.598 84.5096C120.709 84.6384 120.819 84.7682 120.928 84.8992C123.73 88.2753 125.652 92.2929 126.523 96.5931C127.393 100.893 127.185 105.342 125.917 109.543C124.649 113.743 122.361 117.564 119.256 120.664C116.748 123.18 113.758 125.165 110.465 126.5C104.05 129.084 96.8846 129.084 90.4701 126.5C87.774 125.402 85.2819 123.873 83.0895 121.976C83.4034 121.741 83.7145 121.471 84.0101 121.176L119.938 85.2474C120.176 85.0093 120.398 84.7611 120.598 84.5096Z"
                fill="#B4C3D3"
                stroke="#B4C3D3"
                stroke-width="3"
            />
            <path
                d="M154.848 59.1888C137.336 59.4942 120.141 54.5004 105.523 44.8633C103.905 43.7742 101.998 43.1925 100.047 43.1925C98.0964 43.1925 96.1899 43.7742 94.5719 44.8633C81.3889 53.6022 66.0573 58.5575 50.2488 59.1888C48.0036 59.2684 45.8548 60.12 44.1654 61.5996C42.476 63.0792 41.3497 65.0961 40.9768 67.3094C37.9311 84.6663 32.9871 142.822 97.1556 162.03C98.0943 162.307 99.0685 162.445 100.047 162.441C101.025 162.442 101.998 162.302 102.935 162.026C178.725 139.33 158.044 62.0585 157.828 61.2815C157.649 60.643 157.254 60.086 156.711 59.7046C156.168 59.3233 155.51 59.1411 154.848 59.1888ZM153.431 102.046C148.302 129.359 130.751 147.693 101.272 156.521C100.472 156.759 99.6192 156.759 98.819 156.521C79.5011 150.716 35.744 130.543 46.664 68.3019C46.8183 67.3899 47.2823 66.5589 47.978 65.9486C48.6737 65.3383 49.5586 64.9862 50.4837 64.9513C67.3697 64.2739 83.7448 58.9751 97.8217 49.6333C98.4789 49.1894 99.254 48.9522 100.047 48.9522C100.841 48.9522 101.616 49.1894 102.273 49.6333C117.208 59.6548 134.789 65.011 152.78 65.0206C155.351 77.2089 155.572 89.7752 153.431 102.046Z"
                fill="#B4C3D3"
            />
        </svg>
    );
};

export const FeatureDisabledIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 200}
            height={props.height ?? 200}
            fill="none"
            viewBox="0 0 200 200"
        >
            <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
            <path
                fill="#B4C3D3"
                fillRule="evenodd"
                stroke="#B4C3D3"
                strokeWidth="3"
                d="M134.19 64.856a48.503 48.503 0 0110.622 16.001 48.39 48.39 0 01-4.706 45.2 48.403 48.403 0 01-17.391 15.682 48.372 48.372 0 01-50.907-3.37 48.317 48.317 0 01-17.62-23.796 48.746 48.746 0 01-2.223-21.138 2.46 2.46 0 114.885.57A43.851 43.851 0 0058.847 113a43.528 43.528 0 009.248 15.543c.396-.535.854-1.066 1.358-1.57l58.739-58.74c.41-.409.838-.788 1.271-1.128a43.428 43.428 0 00-13.121-8.315 43.79 43.79 0 00-32.69 0 43.141 43.141 0 00-14.373 9.545 2.461 2.461 0 01-4.012-.798 2.459 2.459 0 01.533-2.68 48.055 48.055 0 0116-10.624 48.682 48.682 0 0136.391 0 48.502 48.502 0 0115.999 10.623zm-1.282 5.81a43.427 43.427 0 01-2.193 59.109 43.198 43.198 0 01-14.373 9.541 43.74 43.74 0 01-32.69 0 43.513 43.513 0 01-12.067-7.396 14.104 14.104 0 001.505-1.309l58.74-58.74c.389-.389.751-.795 1.078-1.206z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export const CommonFeatureDisabledIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 200}
            height={props.height ?? 200}
            fill="none"
            viewBox="0 0 130 130"
        >
        <path fill="#929292" d="M0 0H130V130H0z"/>
        <g clipPath="url(#clip0_1805_24)">
            <path fill="#F0F1F1" d="M0 0H1440V970H0z" transform="translate(-655 -289)"/>
            <rect width="1439.75" height="897.75" x="-654.875" y="-216.875" fill="#fff" stroke="#6B7075" strokeWidth="0.25" rx="1.875"/>
            <circle cx="65" cy="65" r="65" fill="#E6EBF0"/>
            <g stroke="#B4C3D3" strokeWidth="3.042" clipPath="url(#clip1_1805_24)">
            <path strokeLinecap="round" strokeLinejoin="round" d="M30.576 46.7h60.833M54.909 83.187h-18.25a6.083 6.083 0 01-6.083-6.083v-36.5a6.084 6.084 0 016.083-6.083h48.667a6.084 6.084 0 016.083 6.083v21.292"/>
            <path strokeLinecap="round" strokeLinejoin="round" d="M82.229 104.479c10.08 0 18.25-8.17 18.25-18.25 0-10.079-8.17-18.25-18.25-18.25s-18.25 8.171-18.25 18.25c0 10.08 8.17 18.25 18.25 18.25zM69.326 99.132l25.812-25.808"/>
            <path d="M39.7 41.377a.76.76 0 110-1.521M39.7 41.377a.76.76 0 000-1.521M45.784 41.377a.76.76 0 110-1.521M45.784 41.377a.76.76 0 100-1.521M51.867 41.377a.76.76 0 110-1.521M51.867 41.377a.76.76 0 100-1.521"/>
            </g>
        </g>
        </svg>

    );
};

export const ProductDisabledIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 200}
            height={props.height ?? 200}
            fill="none"
            viewBox="0 0 200 200"
        >
            <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
            <path
                fill="#B4C3D3"
                d="M76.552 72.227c.351-.72.403-1.55.144-2.31a3.019 3.019 0 00-1.523-1.735 2.997 2.997 0 00-2.3-.144c-.755.26-1.376.81-1.727 1.53L42.382 128.57c-1.203 2.461-4.411 9.004 1.204 13.5 8.382 6.696 32.164 10.048 55.954 10.044 23.791-.004 47.57-3.348 55.936-10.044 5.614-4.496 2.406-11.043 1.203-13.5l-37.411-76.775a22.032 22.032 0 00-8.103-9.015 21.897 21.897 0 00-11.625-3.342c-4.11 0-8.137 1.158-11.624 3.342a22.035 22.035 0 00-8.103 9.014 3.035 3.035 0 00-.146 2.31 3.018 3.018 0 001.521 1.735 2.997 2.997 0 002.299.147 3.014 3.014 0 001.728-1.529 15.997 15.997 0 015.883-6.549 15.897 15.897 0 0116.885 0 15.991 15.991 0 015.883 6.55l37.435 76.771c2.093 4.294 1.431 5.31.425 6.115-14.631 11.716-89.744 11.716-104.375 0-1.002-.805-1.664-1.817.434-6.115l28.767-59z"
            ></path>
            <path
                fill="#B4C3D3"
                d="M104.225 104.464V76.757c0-.933-.445-1.828-1.237-2.487-.793-.66-1.867-1.03-2.988-1.03-1.12 0-2.195.37-2.988 1.03-.792.66-1.237 1.554-1.237 2.487v27.707c0 .933.445 1.827 1.237 2.487.793.66 1.867 1.03 2.988 1.03s2.195-.37 2.988-1.03c.792-.66 1.237-1.554 1.237-2.487zM93.897 122.066c0 1.114.358 2.203 1.028 3.129a6.016 6.016 0 002.738 2.075 6.573 6.573 0 003.526.322 6.28 6.28 0 003.125-1.541 5.511 5.511 0 001.671-2.883 5.234 5.234 0 00-.345-3.255 5.75 5.75 0 00-2.246-2.529 6.466 6.466 0 00-3.389-.952c-1.62 0-3.172.593-4.317 1.65-1.146 1.056-1.79 2.489-1.791 3.984z"
            ></path>
        </svg>
    );
};

export const NoPermissionButProductAccessIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width ?? 200}
            height={props.height ?? 200}
            fill="none"
            viewBox="0 0 200 200"
        >
            <circle cx="100" cy="100" r="100" fill="#E6EBF0"></circle>
            <path
                fill="#B4C3D3"
                d="M132.974 47.887H67.026c-10.571.004-19.135 8.492-19.139 18.97v65.362c0 1.45 1.187 2.626 2.65 2.626 1.463 0 2.65-1.176 2.65-2.626V79.287h93.626v52.932c.004 7.574-6.19 13.713-13.832 13.716H67.026c-2.403 0-4.763-.623-6.85-1.8a2.662 2.662 0 00-3.544 1.208 2.614 2.614 0 00.922 3.345 19.288 19.288 0 009.472 2.486h65.948c10.571 0 19.139-8.492 19.139-18.969V66.856c-.004-10.477-8.572-18.965-19.139-18.969zm13.839 18.97v7.178H53.187v-7.179c-.004-7.574 6.19-13.713 13.832-13.716h65.955c7.642-.004 13.836 6.135 13.839 13.71v.006z"
            ></path>
            <path
                fill="#B4C3D3"
                d="M80.285 60.094a3.286 3.286 0 10-.007 6.573 3.286 3.286 0 003.29-3.283v-.007a3.284 3.284 0 00-3.283-3.283zM67.61 60.094c-2.075-.004-3.756 1.467-3.76 3.283-.004 1.815 1.677 3.286 3.752 3.29 2.075.003 3.755-1.468 3.76-3.283v-.007c-.004-1.812-1.681-3.28-3.752-3.283zM94.37 60.094a3.286 3.286 0 10-.007 6.573 3.286 3.286 0 00.007-6.573zM86.671 122.693c.372.396.813.711 1.3.926a3.805 3.805 0 001.534.325c.526 0 1.047-.111 1.534-.325.486-.215.927-.53 1.299-.926l7.709-8.193 7.714 8.193a3.884 3.884 0 002.804 1.143 3.9 3.9 0 002.764-1.248 4.397 4.397 0 001.174-2.938 4.42 4.42 0 00-1.076-2.98l-7.708-8.192 7.708-8.193a4.409 4.409 0 001.127-2.996 4.4 4.4 0 00-1.173-2.977 3.894 3.894 0 00-2.8-1.25 3.884 3.884 0 00-2.82 1.195l-7.714 8.193-7.71-8.193a4.014 4.014 0 00-1.3-.954 3.804 3.804 0 00-3.1-.027c-.494.214-.942.531-1.318.931-.376.4-.674.876-.875 1.4a4.495 4.495 0 00.028 3.296c.21.52.516.99.9 1.382l7.687 8.193-7.709 8.192a4.295 4.295 0 00-.865 1.385 4.493 4.493 0 00.012 3.26c.203.516.5.984.874 1.378z"
            ></path>
        </svg>
    );
};

export const SupportIcon: React.FC<IconImageProps> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className} onClick={props.onClick}>
        <title>Support</title>
        <g clipPath="url(#clip0_2203_448356)">
            <path d="M8 1C6.67392 1 5.40215 1.52678 4.46447 2.46447C3.52678 3.40215 3 4.67392 3 6V7H4C4.26522 7 4.51957 7.10536 4.70711 7.29289C4.89464 7.48043 5 7.73478 5 8V11C5 11.2652 4.89464 11.5196 4.70711 11.7071C4.51957 11.8946 4.26522 12 4 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V6C2 5.21207 2.15519 4.43185 2.45672 3.7039C2.75825 2.97595 3.20021 2.31451 3.75736 1.75736C4.31451 1.20021 4.97595 0.758251 5.7039 0.456723C6.43185 0.155195 7.21207 0 8 0C8.78793 0 9.56815 0.155195 10.2961 0.456723C11.0241 0.758251 11.6855 1.20021 12.2426 1.75736C12.7998 2.31451 13.2417 2.97595 13.5433 3.7039C13.8448 4.43185 14 5.21207 14 6V12C14 12.663 13.7366 13.2989 13.2678 13.7678C12.7989 14.2366 12.163 14.5 11.5 14.5H9.366C9.27823 14.652 9.152 14.7783 8.99999 14.866C8.84797 14.9538 8.67553 15 8.5 15H7.5C7.23478 15 6.98043 14.8946 6.79289 14.7071C6.60536 14.5196 6.5 14.2652 6.5 14C6.5 13.7348 6.60536 13.4804 6.79289 13.2929C6.98043 13.1054 7.23478 13 7.5 13H8.5C8.67553 13 8.84797 13.0462 8.99999 13.134C9.152 13.2217 9.27823 13.348 9.366 13.5H11.5C11.8978 13.5 12.2794 13.342 12.5607 13.0607C12.842 12.7794 13 12.3978 13 12H12C11.7348 12 11.4804 11.8946 11.2929 11.7071C11.1054 11.5196 11 11.2652 11 11V8C11 7.73478 11.1054 7.48043 11.2929 7.29289C11.4804 7.10536 11.7348 7 12 7H13V6C13 5.34339 12.8707 4.69321 12.6194 4.08658C12.3681 3.47995 11.9998 2.92876 11.5355 2.46447C11.0712 2.00017 10.52 1.63188 9.91342 1.3806C9.30679 1.12933 8.65661 1 8 1Z" fill="#212529"/>
        </g>
        <defs>
            <clipPath id="clip0_2203_448356">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
        </svg>

    );
};

export const LinkIcon:React.FC<IconImageProps> = () => (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.71476 5.54244L1.343 6.9142C0.171427 8.08577 0.171427 9.98527 1.343 11.1568C2.51457 12.3284 4.41407 12.3284 5.58564 11.1568L7.41407 9.32842C8.58564 8.15684 8.58564 6.25735 7.41407 5.08577C7.16613 4.83784 6.88559 4.64237 6.58625 4.49938L5.99985 5.08578C5.93888 5.14675 5.88742 5.21365 5.84548 5.28461C6.16092 5.37499 6.4585 5.54442 6.70696 5.79288C7.48801 6.57393 7.48801 7.84026 6.70696 8.62131L4.87853 10.4497C4.09749 11.2308 2.83116 11.2308 2.05011 10.4497C1.26906 9.66869 1.26906 8.40236 2.05011 7.62131L2.84271 6.8287C2.73052 6.40858 2.68787 5.97375 2.71476 5.54244Z"
        fill="#05386B"
      />
      <path
        d="M4.58564 3.67156C3.41407 4.84313 3.41407 6.74263 4.58564 7.9142C4.83358 8.16214 5.11411 8.3576 5.41345 8.5006L6.18905 7.72501C5.86071 7.63732 5.55034 7.46468 5.29275 7.2071C4.5117 6.42605 4.5117 5.15972 5.29275 4.37867L7.12118 2.55024C7.90222 1.76919 9.16855 1.76919 9.9496 2.55024C10.7307 3.33129 10.7307 4.59762 9.9496 5.37867L9.15699 6.17128C9.26919 6.5914 9.31184 7.02623 9.28495 7.45754L10.6567 6.08577C11.8283 4.9142 11.8283 3.01471 10.6567 1.84313C9.48514 0.671561 7.58564 0.671561 6.41407 1.84313L4.58564 3.67156Z"
        fill="#05386B"
      />
    </svg>
  )
  
