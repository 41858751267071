import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { FinishProcessReturn } from '../../components/helper/Constants';
import { openWindowWithPostRequest } from '../../components/helper/HelperFunctions';
import { IFileClient } from '../../Core/Services/FileClient';
import { IPdfDocumentFacade, PdfDocumentFacade } from '../../Core/Utilities/PdfDocumentFacade';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { container } from './../../Startup/inversify.config';
import { TYPES } from './../../Startup/types';
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import { handleResponse, handleBlobwithFileName } from './../../store/Library';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { AdditionalEsignModel, OrganizerClient, OrganizerClientType, OrganizerDocument, ProcessStatus } from './../models/OrganizerComponentModels';
import {
    apiPrefix, InprocessOrganizerClientsSaved, InprocessOrganizerPopupLoaderAction, OrganizeDelivered, OrganizerStoreState, OrganizerTableModel, ReceiveAdditionalEsignDocuments, ReceiveAdditionalEsignPdfAction, ReceiveInprocessOrganizerClient, ReceiveOrganizerDocumentsAction, ReceiveOrganizerPdfDocumentAction, RecieveOrganizerAction, RequestInprocessOrganizerClient, RequestOrganizerDocumentsAction, unloadedOrganizerStoreState, UpdateOrganizerDocumentStatus
} from './../models/OrganizerStoreModels';

import { HideLoader } from '../../components/helper/Loader';
const logger = new LoggerFactory().getTelemetryLogger();
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { convertToJSON } from 'src/components/common/utils';

type KnownActions =
    RequestOrganizerDocumentsAction |
    ReceiveOrganizerDocumentsAction |
    RecieveOrganizerAction |
    ReceiveOrganizerPdfDocumentAction |
    UpdateOrganizerDocumentStatus |
    OrganizeDelivered |
    NotificationAction |
    ReceiveInprocessOrganizerClient |
    RequestInprocessOrganizerClient |
    InprocessOrganizerPopupLoaderAction |
    InprocessOrganizerClientsSaved |
    ReceiveAdditionalEsignPdfAction |
    ReceiveAdditionalEsignDocuments;


const fileClient = container.get<IFileClient>(TYPES.IFileClient);

let organizerReportAbortController = new AbortController();
const validateTaxpayerClientView= (validateUrl: string, cpaToken: string): AppThunkAction<KnownActions> => (dispatch, getstate) => {
    const data = {
        token: cpaToken,
        scope: 'client_view'
      }
    const fetchTask = fetch(`${validateUrl}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
        .then(response => response.json())
        .then((result) => {
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerClientViewSuccess, statusType: StatusType.Success });
            window.open(result.url, '_blank');
        })
        .catch((error) => {
            HideLoader();
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerClientViewError, statusType: StatusType.Error, statusCode: error?.status });
        });

    addTask(fetchTask);
}
const validateTaxpayerPreview= (validateUrl: string, cpaToken: string): AppThunkAction<KnownActions> => (dispatch, getstate) => {
    const data = {
        token: cpaToken,
        scope: 'preview'
      }
      console.log("validateTaxpayerPreview called",validateUrl);
    const fetchTask = fetch(`${validateUrl}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
        .then(response => response.json())
        .then((result) => {
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerViewSuccess, statusType: StatusType.Success });
            window.open(result.url, '_blank');
        })
        .catch((error) => {
            HideLoader();
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerClientViewError, statusType: StatusType.Error, statusCode: error?.status });
        });

    addTask(fetchTask);
}

export const actionCreator = {
    requestOrganizers: (query: string, callback?: any): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.REQUEST_ORGANIZER_DOCUMENTS,
            loading: true
        });
        organizerReportAbortController.abort();
        organizerReportAbortController = new AbortController();
        const fetchTask = fetch(`${apiPrefix}Organizer/${query}`, {
            method: 'GET',
            credentials: 'include',
            signal: organizerReportAbortController.signal
        })
            .then(handleResponse)
            .then(response => response as OrganizerTableModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_DOCUMENTS,
                    loading: false,
                    model: data
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    if (callback) {
                        callback();
                    }
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AllOrganizers,
                    statusType: StatusType.Error
                });
                logger?.trackError(`requestOrganizers failed for the request having parameters ${query} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    exportOrganizersAsExcel: (query: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/ExportOrganizers/${query}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => handleBlobwithFileName(response))
            .then(data => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, data.filename);
                if (callback) callback();
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Export, statusType: StatusType.Error, statusCode: error?.status });
                if (callback) {
                    callback();
                }
                logger?.trackError(`exportOrganizersAsExcel failed for the request having parameters ${JSON.stringify(query)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    deleteOrganizers: (ids: number[], callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(ids),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => {
                if (response.status === 200) {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.Delete, statusType: StatusType.Success });
                }
                else if(response.status === 400) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.DeleteDeliveredOrganizers, statusType: StatusType.Error, statusCode: response?.status });
                }
                else if(response.ok === false) {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Delete, statusType: StatusType.Error, statusCode: response.status });
                }
                callback();
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Delete, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`deleteOrganizers failed for the request having parameters ${JSON.stringify(ids)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestOrganizer: (id: number, callback?: (id: number, document: OrganizerDocument) => void, failureCallback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/GetOrganizer/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerDocument)
            .then(data => {
                if (!data.id) {
                    throw Error();
                }
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER,
                    model: data
                });
                callback && callback(id, data);
            })
            .catch((error) => {
                failureCallback && failureCallback();
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Organizer,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                HideLoader();
                logger?.trackError(`requestOrganizer failed for the request having parameters ${id} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestOrganizerPdfDocument: (documentGuid: string, documentId: number, year: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const appState = getState();
        const document = appState.organizerReports.model.documents.find(document => document.id === documentId);
        if (document && !document.document) {
            const fetchTask = fetch(`${apiPrefix}Document/GetDocumentLink/${documentGuid}/${year}`,
                {
                    method: 'GET',
                    credentials: 'include'
                })
                .then(handleResponse)
                .then(response => response as string)
                .then((documentUrl) => {
                    fileClient.download(documentUrl)
                        .then((data: any) => {
                            const document: IPdfDocumentFacade = new PdfDocumentFacade();
                            document.append(data, documentUrl);
                            dispatch({ type: actionTypes.RECEIVE_ORGANIZER_PDF_DOCUMENT, id: documentId, document: document });
                        }, () => {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: StoreConstants.Failure.OrganizerPdf,
                                statusType: StatusType.Error
                            })
                        });
                }).catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.OrganizerPdf,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    })
                    let errorText = error?.message === undefined ? error?.statusText : error?.message;
                    logger?.trackError(`requestOrganizerPdfDocument failed for the request having parameters ${documentGuid}/${year} with error ${errorText}`)
                });
            addTask(fetchTask);
        }
    },

    updateOrganizer: (model: OrganizerDocument, callback: (id: number) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/SaveOrganizer`, {
            method: 'POST',
            credentials: 'include',
            body: convertToJSON(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.SaveOrganizer,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER,
                    model: model
                });
                callback(model.id);
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SaveOrganizer, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`updateOrganizer failed for the request having parameters ${JSON.stringify({ ...model, document: null, questionsDocument: null })} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    deliverOrganizer: (model: OrganizerDocument, callback: (id: number) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/Deliver`, {
            method: 'POST',
            credentials: 'include',
            body: convertToJSON(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.Deliver,
                    statusType: StatusType.Success
                });
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER,
                    model: model
                });
                dispatch({
                    type: actionTypes.ORGANIZER_DELIVER,
                    id: model.id
                });
                callback(model.id);
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Deliver,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                HideLoader();
                logger?.trackError(`deliverOrganizer failed for the request having parameters ${JSON.stringify({ ...model, document: null, questionsDocument: null })} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateDocumentStatus: (documentId: number, status: ProcessStatus, isUpdateUserId: boolean = false): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/UpdateDocumentStatus/${documentId}/${status}/${isUpdateUserId}`, {
            method: 'POST',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.UPDATE_ORGANIZER_DOCUMENTSTATUS, processStatus: status, id: documentId })
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.UpdateStatus, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`updateDocumentStatus failed for the request having parameters ${documentId}/${status} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestOrganizerClients: (id: number, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_INPROCESS_ORGANIZER_DOCUMENT_CLIENTS, loading: true });
        const fetchTask = fetch(`${apiPrefix}Details/Clients/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerClient[])
            .then((data) => {
                dispatch({ type: actionTypes.RECEIVE_INPROCESS_ORGANIZER_DOCUMENT_CLIENTS, id: id, clients: data, loading: false });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.OrganizerClients, statusType: StatusType.Error, statusCode: error?.status });
                HideLoader();
                logger?.trackError(`requestOrganizerClients failed for the request having parameters ${id} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    saveClientInfo: (clientInfo: OrganizerClient[], clientId: string, documentId: number, callback: () => void, resendEmail: boolean = false, resendEmailClientType?: OrganizerClientType):
        AppThunkAction<KnownActions> => (dispatch, getState) => {
            dispatch({ type: actionTypes.INPROCESS_ORGANIZER_POPUP_LOADER, loading: true });
            const formData = JSON.stringify({
                documentId: documentId,
                clientId: clientId,
                resendEmail: resendEmail,
                resendEmailClientType: resendEmailClientType,
                clientInfo: clientInfo
            });

            let fetchTask = fetch(`${apiPrefix}Details/Clients`, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleResponse)
                .then(() => {
                    const taxpayer = clientInfo.find(client => client.clientType == OrganizerClientType.Taxpayer);
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.ClientInfo, statusType: StatusType.Success });
                    dispatch({ type: actionTypes.INPROCESS_ORGANIZER_DOCUMENT_CLIENTS_SAVED, clientId: clientId, name: taxpayer ? taxpayer.name : "", documentId: documentId, clients: clientInfo })
                    dispatch({ type: actionTypes.INPROCESS_ORGANIZER_POPUP_LOADER, loading: false });
                    callback();
                })
                .catch((error) => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.ClientInfo, statusType: StatusType.Error, statusCode: error?.status });
                    logger?.trackError(`saveClientInfo failed for the request having parameters ${documentId}/${clientId}/${resendEmail}/${resendEmailClientType} with error ${error.message}`)
                });
            addTask(fetchTask);
        },

    generateTaxpayerView: (model: OrganizerDocument, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getstate) => {
        const fetchTask = fetch(`${apiPrefix}Preview/TaxPayerPreview`, {
            method: 'POST',
            credentials: 'include',
            body: convertToJSON(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then((data) => {
                dispatch({ 
                    type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENTS,
                    additionalEsignDocuments: data.additionalDocs,
                    id: data.processInfoGuid });
                dispatch(validateTaxpayerPreview(data.url, data.token));
                callback();
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerViewError, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`generateTaxpayerView failed for the request having parameters ${model.id} with error ${error.message}`);
                callback();
            });

        addTask(fetchTask);
    },

    generateTaxpayerClientView: (model: OrganizerDocument, clientType: OrganizerClientType, url: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getstate) => {
        const fetchTask = fetch(`${url}/${clientType}`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ ...model, document: null, questionsDocument: null }),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(response => response.json())
            .then((data) => {
                HideLoader();
                dispatch(validateTaxpayerClientView(data.url, data.token));
                callback();
            })
            .catch((error) => {
                HideLoader();
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: FinishProcessReturn.StatusMessage.GenerateTaxpayerClientViewError, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`generateTaxpayerClientView failed for the request having parameters ${model.id} with error ${error.message}`);
                callback();
            });

        addTask(fetchTask);
    },  
   
    requestAdditionalEsignDocs: (documentGuid: string, taxYear: number, callback?: (esignDocs: AdditionalEsignModel[]) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}Organizer/GetAdditionalEsignDocuments/${documentGuid}/${taxYear}`, { credentials: 'include' })
            .then(handleResponse)
            .then(response => response as AdditionalEsignModel[])
            .then(function (data) {
                dispatch({ type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_PDF, additionalEsignDocuments: data, id: documentGuid });
                if (callback) {
                    callback(data);
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionalEsignFetchFailed,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
                logger?.trackError(`requestAdditionalEsignDocs failed for the request having parameters with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    deleteAdditionalEsignDocs: (documentIds: number[], callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getstate) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/DeleteAdditionalEsignDocument`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(documentIds),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(() => {
                callback && callback();
            })
            .catch((error) => {
                logger?.trackError(`deleteAdditionalEsignDocs failed for the request having parameters ${documentIds} with error ${error.message}`);

            });

        addTask(fetchTask);
    },
}

export const reducer: Reducer<OrganizerStoreState> = (state: OrganizerStoreState = unloadedOrganizerStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.REQUEST_ORGANIZER_DOCUMENTS:
            return {
                ...unloadedOrganizerStoreState,
                loading: action.loading
            }
        case actionTypes.RECEIVE_ORGANIZER_DOCUMENTS:
            return {
                ...state,
                model: action.model,
                loading: action.loading
            }
        case actionTypes.RECEIVE_ORGANIZER: {
            const receiveOrganizer = { ...state };
            receiveOrganizer.model.documents = receiveOrganizer.model.documents.map(org => {
                if (org.id === action.model.id) {
                    const taxpayer = action.model.organizerClients.find(client => client.clientType === OrganizerClientType.Taxpayer);
                    return {
                        ...action.model,
                        taxpayerName: taxpayer ? taxpayer.name : org.taxpayerName,
                        document: org.document,
                        questionsDocument: org.questionsDocument,
                        eroUserName: org.eroUserName,
                        assignedToUser: org.assignedToUser
                    }
                }
                return org;
            });
            return receiveOrganizer;
        }

        case actionTypes.RECEIVE_ORGANIZER_PDF_DOCUMENT: {
            const pdfDocument = { ...state };
            pdfDocument.model.documents = pdfDocument.model.documents.map(org => {
                if (org.id === action.id) {
                    return {
                        ...org,
                        document: action.document,
                        questionsDocument: org.questionsDocument
                    }
                }
                return org;
            });
            return pdfDocument;
        }

        case actionTypes.ORGANIZER_DELIVER: {
            const delivered = { ...state };
            delivered.model.documents = delivered.model.documents.map(org => {
                if (org.id === action.id) {
                    return {
                        ...org,
                        processStatus: ProcessStatus.PreparingForDelivery,
                        deliveredOn: new Date()
                    }
                }
                return org;
            });
            return delivered;
        }

        case actionTypes.UPDATE_ORGANIZER_DOCUMENTSTATUS: {
            const documentStatus = { ...state };
            documentStatus.model.documents = documentStatus.model.documents.map(org => {
                if (org.id === action.id) {
                    return {
                        ...org,
                        processStatus: action.processStatus
                    }
                }
                return org;
            })
            return documentStatus;
        }

        case actionTypes.RECEIVE_INPROCESS_ORGANIZER_DOCUMENT_CLIENTS: {
            const clients = { ...state };
            clients.model.documents = clients.model.documents.map(org => {
                if (org.id === action.id) {
                    return {
                        ...org,
                        organizerClients: action.clients
                    }
                }
                return org;
            });
            clients.popupLoading = false;
            return clients;
        }
        case actionTypes.REQUEST_INPROCESS_ORGANIZER_DOCUMENT_CLIENTS:
            return {
                ...state,
                popupLoading: action.loading
            }

        case actionTypes.INPROCESS_ORGANIZER_POPUP_LOADER:
            return {
                ...state,
                popupLoading: action.loading
            }

        case actionTypes.INPROCESS_ORGANIZER_DOCUMENT_CLIENTS_SAVED: {
            const clientSaved = { ...state };
            clientSaved.model.documents = clientSaved.model.documents.map(org => {
                if (org.id === action.documentId) {
                    return {
                        ...org,
                        clientId: action.clientId,
                        taxpayerName: action.name,
                        organizerClients: action.clients

                    }
                }
                return org;
            });
            return clientSaved;
        }

        case actionTypes.RECEIVE_ADDITIONAL_ESIGN_PDF: {
            const pdfDocument = { ...state };
            pdfDocument.model.documents = pdfDocument.model.documents.map(org => {
                if (org.processInfoGuid === action.id) {
                    return {
                        ...org,
                        additionalEsignDocuments: action.additionalEsignDocuments
                    }
                }
                return org;
            });
            return pdfDocument;
        }
        case actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENTS: {
            const pdfDocument = { ...state };
            pdfDocument.model.documents = pdfDocument.model.documents.map(org => {
                if (org.processInfoGuid === action.id) {
                    return {
                        ...org,
                        additionalEsignDocuments: action.additionalEsignDocuments
                    }
                }
                return org;
            });
            return pdfDocument;
        }

    }
    return state || unloadedOrganizerStoreState;
}