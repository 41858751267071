export const emailUpdatedConfirmation = "You have updated the email address on file, would you like to resend the Document to the new email address?";
export const organizerSubmitSuccess = "The following organizer(s) were successfully submitted";
export const organizerTemplateSubmitSuccess = "The following organizer template(s) were successfully submitted";
export const closeOrganizerProcessMessage = "Your changes will be discarded, Are you sure you want to close the organizer?";
export const closeBatchProcessMessage = "Your changes will be discarded, Are you sure you want to close the batch?";
export const closeOrganizerTemplateProcessMessage = "Your changes will be discarded, Are you sure you want to close the organizer template?";
export const closeAdditionalQuestionProcessMessage = "Your changes will be discarded, Are you sure you want to close the additional question?";

export const ValidationWarnings = {
    OpenCloseOrganizersRecordsSelections: "Please select organizer(s) to Open or close!"
}

export const ControllerName = {
    DeliveredOrganizerController: "DeliveredOrganizer",
    ArchivedOrganizerController: "ArchivedOrganizer",
}

export const ApiActionName = {
    ReopenIndividualOrganizerByFirm: "ReopenOrganizerByFirm",
    CloseIndividualOrganizerByFirm: "CloseOrganizerByFirm",
    CloseOpenOrganizersByFirmInBulk: "CloseOpenOrganizersByFirm"
}

export const DeleteConstants = {
    Title: "Delete Organizer",
    SelectDocumentWarning: "Please select document(s) to delete!",
    ConfirmDeletion: "<p>You are about to delete the selected organizer(s). Do you want to proceed?</p>"
        + "<p class='please-note-msg'>Note: Any scheduled reminders will be turned off for recycled organizers(s).</p>",
    ArchivedConfirmDeletion: "<p>You are about to delete the selected organizer(s). Do you want to proceed?</p>",
}


export const DeleteInProcessConstants = {
    Title: "Delete In-Process Organizer",
    SelectDocumentWarning: "Please select document(s) to delete!",
    ConfirmDeletion: "<p>You are about to delete the selected organizer(s). Do you want to proceed?</p>",
}

export const DeleteOrganizerFromBatchConstants = {
    ModalTitle: "Delete In-Process Organizer",
    ModalBody: "You are about to delete this organizer from the uploaded batch. Do you want to proceed?",
}

export const ClientTrackingEvents = {
    InitialEmail: 'Initial Email ',
    ReminderEmail: 'Organizer Reminder Email Sent'
}
export enum DocumentEventValue {
    "None" = 0
}
export const HelpTextConstant = {
    ClientSignature: "Drag and drop to place client's signature",
    SignatureDate: "Drag and drop to place date of signature",
    Initial: "Drag and drop to place Initials control",
    Name: "Drag and drop to place Name control",
    Title: "Drag and drop to place Title control",
    Email: "Drag and drop to place Email Address control",
    CustomQuestion: "Drag and drop to place Custom Question control",
    Text: "Drag and drop to place Text control",
    Checkbox: "Drag and drop to place Checkbox control",
    RadioButton: "Drag and drop to place Radio Button control"
}

export const StoreConstants = {
    Success: {
        Delete: "Successfully deleted the selected organizer report.",
        DeleteBatch: "Successfully deleted the selected Batch.",
        DeleteBatchOrganizer: "Successfully deleted the selected organizer from Batch.",
        SaveEmail: "Successfully saved the email message.",
        UpdateEmail: "Successfully updated the email message.",
        DeleteEmail: "Successfully deleted the email message.",
        SendReminder: "Reminder has been sent successfully for the selected organizer(s).",
        UnlockOrganizer: "Organizer request unlocked successfully!",
        ResendAccessLink: "Access link has been sent successfully.",
        ReopenOrganizer: "Organizer has been reopened for selected client organizer.",
        CloseOrganizerByFirm: "Selected Organizer(s) is closed successfully.",
        ReopenOrganizerByFirm: "Closed Organizer(s) is re-opened successfully.",
        ClientInfo: "Successfully saved Client Info",
        BatchInfo: "Successfully saved Batch Info",
        Deliver: "Organizer delivered successfully",
        BatchDeliver: "Batch delivered successfully",
        SaveOrganizer: "Your changes are saved successfully",
        UpdateCompanySettings: "Successfully updated organizer company settings.",
        SaveEmailMessage: 'Instruction added successfully',
        UpdateEmailMessage: 'Instruction Updated successfully',
        DeleteEmailMessage: 'Instruction deleted successfully',
        SavePreparerMessage: 'Message added successfully',
        UpdatePreparerMessage: 'Message updated successfully',
        DeletePreparerMessage: 'Message deleted successfully',
        TemplateStatus: "Template saved successfully.",
        DeleteTemplate: "Template deleted successfully",
        UpdateAdditionalQuestion: 'Custom questions updated successfully',
        DownloadAllNotification: 'Download process may take some time, you will receive an email notification when your files are ready to download.',
        SendReminderBulkWaitMessage: 'We are processing the reminders for emailing. This may take several minutes',
        ManualCompletion: "Status has been successfully changed for the Organizer(s).",
        ReopenCompletedDocument: "Source Document upload reopened for selected organizer.",
        SourceDocumentNotificationDefault: "Please note the Source Document Notification option has been reset to the default, review and change if needed.",
        ArchiveOrganizers: "Successfully archived selected organizer(s).",
        CloseOpenOrganizersByFirm: "Successfully closed/opened organizer(s).",
        BulkProcessing: "This may take several minutes. Thank you for your patience.",
        RestoreOrganizers: "Successfully restored selected organizer(s) to delivered report.",
        RecycleOrganizers: "Successfully deleted selected organizer(s) to Recycle Bin",
        PermentDeleteOrganizer: "Successfully deleted recycled Organizer(s)",
        UpdateAutoReminder: "Automatic Reminder successfully updated for selected Organizer(s)",
        UnlockOneHub: "Client Portal has been unlocked!"
    },
    Failure: {
        Delete: "Failed to delete the selected organizer report",
        DeleteBatch: "Failed to delete the selected Batch",
        DeleteBatchOrganizer: "Failed to delete the selected organizer from Batch",
        Export: "Failed to export reports",
        AllOrganizers: "Failed to fetch reports",
        OrganizerLinkError: 'Failed to fetch organizer link.',
        SubmitOrganizer: 'Failed to submit organizer.',
        AllEmailMessages: "Failer to fetch email messages",
        SaveEmail: "Failed to save email message.",
        UpdateEmail: "Failed to update email message.",
        DeleteEmail: "Failed to delete email message",
        CompanySettings: "Failed to fetch company settings.",
        UpdateCompanySettings: "Failed to update organizer company settings.",
        SendReminder: "Failed to send reminder for the selected organizer(s).",
        UnlockOrganizer: "Failed to Unlock document(s).",
        ResendAccessLink: "Failed to send access link.",
        ReopenOrganizer: "Failed to reopen organizer for selected client organizer.",
        CloseOrganizerByFirm: "Failed to close organizer.",
        ReopenOrganizerByFirm: "Failed to reopen organizer.",
        OrganizerClients: "Failed to fetch Client info.",
        OrganizerDelivery: "Failed to fetch Delivery options.",
        ClientInfo: "Failed to save Client Info",
        BatchInfo: "Failed to save Batch Info",
        Error: "Failed to fetch Errors",
        CheckErrors: "Failed to get Errors",
        Deliver: "Organizer delivery failed",
        BatchDeliver: "Batch delivery failed",
        Organizer: "Failed to fetch organizer",
        Batch: "Failed to fetch Batch",
        OrganizerPdf: "Failed to fetch organizer pdf.",
        UpdateStatus: "Failed to update document status.",
        SaveOrganizer: "Error occured while saving your changes.",
        SaveEmailMessage: "Failed to save client instruction",
        UpdateEmailMessage: "Failed to update client instruction",
        DeleteEmailMessage: "Failed to delete client instruction",
        FetchEmailMessage: "Failed to fetch client instructions",
        SavePreparerMessage: "Failed to save Message",
        UpdatePreparerMessage: "Failed to update Message",
        DeletePreparerMessage: "Failed to delete Message",
        FetchPreparerMessage: "Failed to fetch Messages",
        TemplatePdf: "Failed to fetch template pdf.",
        DeleteTemplate: "Failed to delete Template",
        TemplateStatus: "Failed to save template.",
        Templates: "Failed to fetch Template(s).",
        ClientTracking: "Failed to fetch Client Tracking",
        FilledOrganizer: "Failed to download filled Organizer.",
        CSVTemplate: "Failed to download CSV Template.",
        UndeliveredCSV: "Failed to download Undelivered organizers CSV.",
        SignedEngagementLetter: "Failed to download signed Engagement Letter.",
        SourceDocument: "Failed to download uploaded document.",
        AdditionalQuestionLink: 'Failed to fetch Additional Question link.',
        AdditionQuestions: "Failed to fetch addition questions",
        SaveAdditionQuestions: "Failed to save custom questions",
        AccessCode: "Failed to fetch access code",
        SaveAccessCode: "Failed to generate access code",
        SourceFiles: "Failed to fetch source documents",
        DownloadDocuments: "Failed to fetch downloadable documents",
        DownloadAll: "Failed to download documents",
        SendReminderError: 'We are unable to send reminder for these documents',
        AdditionalEsignUploadFailed: 'Failed to upload esign documents',
        AdditionalEsignFetchFailed: 'Failed to fetch esign documents',
        ManualCompletionFailed: "Failed to change status for the Organizer(s).",
        ReopenCompletedDocument: "Failed to reopen Source Document upload for selected organizer.",
        ArchiveOrganizers: "Failed to archive the Organizer(s).",
        CloseOpenOrganizersByFirm: "Failed to close/open organizer(s).",
        RestoreOrganizers: "Failed to restore the Organizer(s).",
        RecycleOrganizers: "Failed to delete the Organizer(s) to Recycle bin.",
        PermentDeleteOrganizer: "Failed to delete recycled Organizer(s)",
        UpdateAutoReminderFailed: "Failed to update Auto Reminder",
        OrganizerReminderSettings: "Failed to fetch Reminder settings.",
        InvalidEmail: "Invalid email id for",
        UnlockOneHub: "Failed to unlock Client Portal.",
        DeleteDeliveredOrganizers: "Some of the organizers are already Delivered.\n Due to that unable to delete the selected organizers.\n Please click on refresh button and try again."
    }
}
export const UserAutoLogoutConstants = {
    UserRoleChanged: 'User Role Changed',
    AccessRemoved: 'Access Removed',
    FeatureDisabled: 'Feature Disabled',
    CompanyDisable: 'CompanyDisable'
}
export const UserAutoLogoutMessageConstants = {
    UserPermissionChange: 'Your firm administrator has changed your role. You must login again for the changes to take effect.',
    UserDelete: 'Your firm administrator has removed your account. You will be logged out and have no further access.',
    ProductTierChange: 'Your firm administrator has disabled this feature and it can no longer be accessed.',
    CompanyDisable: ''
}
export const PreparerMessageConstants = {
    SaveMessageNameError: 'Name already exists',
    ValidateName: 'Please enter a message name.',
    DangerousContentWarning: 'Unable to save the content as it contains invalid HTML tags or scripts.',
    ValidateSubject: 'Please enter a message subject.',
    ValidateBody: 'Please enter a message body.',
    DeleteMessageWarning: 'You cannot delete the default message',
    SavedMessageWarning: "Message details",
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    SelectDefaultMessageWarning: "Please select a default message first",
    AddMessagePopUpTitle: "Add Message from Tax Preparer",
    EditMessagePopUpTitle: "Edit Message from Tax Preparer",
    AddMessageInfo: "Click on '+' button to create new template message",
    UploadFileWarning: " Please Note: Do not upload encrypted, password-protected, or locked documents."
}

export const EmailMessageConstants = {
    ValidateName: 'Please enter instruction name.',
    DangerousContentWarning: 'Unable to save the content as it contains invalid HTML tags or scripts.',
    ValidateHeader: 'Please enter instruction header.',
    ValidateText: 'Please enter instruction text.',
    ValidateSubject: 'Please enter instruction subject.',
    DeleteInstructionWarning: 'You cannot delete the default client instruction.',
    SetDefaultMessageWarning: "This message will be set as default message",
    RemoveDefaultMessageWarning: "This message will be removed as default message",
    SaveMessageNameError: 'Name already exists'
}


export const createSendReminderText = (isOrganizer: boolean, isEngagement: boolean, isSource: boolean): string => {
    let reminderCount = 0;

    const organierStatus = '"Delivered", "Partially Completed"';
    const engagementStatus = '"Awaiting Signature", "Reviewed", "Partially Signed"';
    const sourceStatus = '“Awaiting Upload”,” Uploaded”, "Downloaded"';
    const sourceDocumentInfo = `<a class="help-icon"
                                        style="vertical-align: middle; cursor: pointer;">
                                        <i
                                        title='If the status is Downloaded but the TP already Finished the upload step, a reminder will not be sent.'
                                        class="glyphicon glyphicon-question-sign"></i>
                                        </a>`;

    if (isEngagement) {
        reminderCount++;
    }
    if (isOrganizer) {
        reminderCount++;
    }
    if (isSource) {
        reminderCount++;
    }
    let reminderString = "Reminders will be sent only for the following";
    if (reminderCount >= 2) {
        let reminderStringList = "";
        if (isEngagement) {
            reminderString += " engagement letter and/or";
            reminderStringList += `<b>Engagement letter</b>: ${engagementStatus} </br>`
        }
        if (isOrganizer) {
            reminderString += " organizer and/or";
            reminderStringList += `<b>Organizer</b>: ${organierStatus} </br>`
        }
        if (isSource) {
            reminderString += " source document";
            reminderStringList += `<b>Source Document</b>: ${sourceStatus} ${sourceDocumentInfo}</br>`
        }
        reminderString += " statuses.</br>";
        reminderStringList += `</br>Do you wish to continue ?`
        return reminderString + reminderStringList;
    } else {

        if (isEngagement) {
            reminderString += ` engagement letter statuses.</br >
                    ${engagementStatus}</br></br>
                    Do you wish to continue ?`;
            return reminderString;
        }

        if (isOrganizer) {
            reminderString += ` organizer statuses.</br >
                    ${organierStatus}</br></br>
                    Do you wish to continue ?`;
            return reminderString;
        }

        if (isSource) {
            reminderString += ` source document statuses.</br >
                    ${sourceStatus} ${sourceDocumentInfo}</br></br>
                    Do you wish to continue ?`;
            return reminderString;
        }
    }
    return "";
}

export const BatchColumnValues = {
    0: "None",
    1: "Client Id",
    2: "Taxpayer Email",
    3: "Taxpayer First Name",
    4: "Taxpayer Last Name",
    5: "Taxpayer Country Code",
    6: "Taxpayer Mobile Number",
    7: "Spouse Email",
    8: "Spouse First Name",
    9: "Spouse Last Name",
    10: "Spouse Country Code",
    11: "Spouse Mobile Number",
    12: "Missing organizer pdf for this Client ID",
    13: "Missing engagement letter pdf for this Client ID"
}

export const ValidationMessageValues = {
    0: "None",
    1: "Missing",
    2: "Improperly formatted",
    3: "Duplicate",
    4: "Encrypted document not supported"
}

export const AdditionQuestionConstants = {
    FirstQuestionError: "Please enter the first question",
    NewQuestionError: [
        "Please enter the first sub question",
        "Please enter the second sub question"
    ],
    SectionError: "Please enter section name",
    NoQuestionsError: "No questions to preview",
    SectionEmptyError: "Please delete any section(s) without question(s) from the 'Custom Questions' to continue"
}

export const CustomQuestionConstants = {
    DeleteSectionTitle: "Confirm delete section",
    DeleteSectionMessage: "Deleting the section will remove all questions created under the section. Do you wish to continue?",
    OnSaveTitle: "Changes not saved",
    OnSaveMessage: "Any changes made to Custom questions will be lost on navigating without saving.Do you wish to continue?",
    PromptMessage: "Any changes made to Custom questions will be lost on navigating without saving. Do you wish to continue?"
}

export const AddQuestionPopup = {
    defaultQuestion: {
        Width: 540
    },
    defaultSubQuestion: {
        Width: 506
    },
    customSectionQuestion: {
        Width: 515
    },
    customSectionSubQuestion: {
        Width: 461
    },
    isRequiredWidth: 2
}


export const DeliveryInfo = "Please select whom you want to sign the Signatures Documents first.";

export const EsignHelpTextConstant = {
    ClientSignature: "Drag and drop to place client's signature",
    SignatureDate: "Drag and drop to place date of signature",
    NameAndTitle: "Drag and drop to place Name and Title",
    EroSignatureStamp: "Drag and drop to place ERO signature stamp",
    Initial: "Drag and drop to place Initials control",
    Text: "Drag and drop to place Text control",
    Company: "Drag and drop to place Company control",
    Name: "Drag and drop to place Name control",
    Title: "Drag and drop to place Title control",
    PrintName: "Drag and drop to place Print Name control"
}

export const CustomEvents = {
    ProformaUpload: "Proforma Upload-",
    BatchUpload: "Batch Upload-"
}

export const BookMarkTitleConstants = {
    OrganizerTab: {
        RequiresourceDocument: "Require source document",
        RequireSignature: "Require Signature from client",
        SignToggleDisabledTitle: "Enable to e-Sign Organizer page.",
        SignToggleLabel: "Organizer page require Signature",
        ESignDisabled: "This page has been identified as a source document page. E-signing is not available for this page."
    }
}

export const ClientManagementConstants = {
    ClientManagementComparisonFailed: 'Failed to compare Client Management details.',
    ClientManagementSaveFailed: 'Failed to save Client Management details.'
}
export const OrganizerDocumentConstants = {
    Menu: {
        UnlockOrganizer: "Unlock Organizer",
        ResendAccessLink: "Resend Access Link",
        ClientView: "Client View",
        ClientTracking: "Client Tracking",
        SendReminder: "Send Reminder",
        ViewAccessCode: "View Access Code",
        ReopenOrganizer: "Reopen Organizer",
        ReopenDocUpload: "Reopen Doc. Upload",
        ReportAProblem: "Report a Problem",
        ReopenByFirm: "Reopen by Firm",
        CloseByFirm: "Close by Firm",
    },
    EditClientInfo: "Edit Client Info",
    NotAvailableForClosed: "Not available while organizer is closed",
    NotAvailableForOldCQ: "Not available for organizer with old custom question",
    ReopenSourceDocUpload: "Reopen Source Document Upload",
    DocumentUploadDisabled: "Document upload disabled. Contact System Admin.",
}

export const ClientInfoTabConstants = {

    OrganizerInformation: {
        Label: {
            OrganizerInformation: 'Organizer Information',
            ClientID: 'Client ID',
            EngagementType: 'Engagement Type',
            TaxYear: 'Tax Year',
            OfficeLocation: 'Office Location',
            EROSigner: 'ERO / Signer'
        },
        Warning: {
            LocationRequired: 'Please select office location.',
            ERORequired: 'Please select ERO / Signer.'
        },
    },
}
