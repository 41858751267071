import React, { FC } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { OrganizerDocument } from "src/Organizer/models/OrganizerComponentModels";

interface IUnlockModalProps {
    show: boolean;
    organizer: OrganizerDocument;
    onUnlockOrganizer: (documentId: number) => void;
    onCancel: () => void;
    onOneHubUnlock: (email: string) => void;
}
const UnlockModal: FC<IUnlockModalProps> = ({ show, organizer, onUnlockOrganizer, onCancel, onOneHubUnlock }) => {
    const { isTPOneHubLocked, isSPOneHubLocked, isDocumentLocked, taxpayerEmail, spouseEmail } = organizer;

    return <Modal className="unlock-modal" show={show} onHide={onCancel}>
        <Modal.Header closeButton onClick={onCancel} data-test-auto="C98D7824-8C63-4A74-95E6-4D3C73B08677">
            <Modal.Title><span className='text-secondary fas fa-unlock' style={{ color: 'grey', marginRight: '5px' }}>
            </span>Unlock Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {isDocumentLocked && <Row className="mx-0">
                <Col>
                    Direct Document
                </Col>
                <Button variant="info" onClick={() => onUnlockOrganizer(organizer.id)}>
                    <i className='fas fa-unlock'></i>Unlock
                </Button>
            </Row>}
            {isTPOneHubLocked && <Row className="mx-0">
                <Col>
                    Taxpayer Client Portal
                </Col>
                <Button variant="info" onClick={() => onOneHubUnlock(taxpayerEmail)}>
                    <i className='fas fa-unlock'></i>Unlock
                </Button>
            </Row>}
            {isSPOneHubLocked && <Row className="mx-0">
                <Col>
                    Spouse Client Portal
                </Col>
                <Button variant="info" onClick={() => onOneHubUnlock(spouseEmail)}>
                    <i className='fas fa-unlock'></i>Unlock
                </Button>
            </Row>}
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="default"
                data-test-auto="DAE73B5C-9114-46EB-BDE7-0EC8BA0D4B0D"
                onClick={onCancel}
                style={{ float: 'right' }}>
                <i className='glyphicon glyphicon-remove'></i>Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}

export default UnlockModal;