import * as React from "react";
import { useEffect } from "react";
import PdfViewer from "awesome-pdf-viewer";
import Main from "awesome-pdf-viewer/dist/layout/Main";
import Zoom from "awesome-pdf-viewer/dist/toolbar/Zoom";
import Header from "awesome-pdf-viewer/dist/layout/Header";
import Toolbar from "awesome-pdf-viewer/dist/toolbar/Toolbar";
import ViewPanel from "awesome-pdf-viewer/dist/layout/ViewPanel";
import Pagination from "awesome-pdf-viewer/dist/toolbar/Pagination";
import { PdfSource } from "awesome-pdf-viewer/dist/viewer/ViewerBase";
import DocViewer from "@cyntler/react-doc-viewer";
interface FileViewerProps {
    url: string;
    fileType: string | undefined;
}

export const FileViewer: React.FC<FileViewerProps> = (props) => {
    let _viewPanel: any;
    let _toolbar: any;

    useEffect(() => {
        if (props.fileType && props.fileType.toLowerCase() === "pdf") {
            setToolbarReferences();
        }
    }, [props.fileType]);

    const setToolbarReferences = () => {
        _toolbar.setViewerReference(_viewPanel);
        _viewPanel.setToolbarReference(_toolbar);
    };

    return props.fileType && props.fileType.toLowerCase() === "pdf" ? (
        // @ts-ignore
        <PdfViewer>
            <Header>
                {/* @ts-ignore */}
                <Toolbar ref={(ref: any) => (_toolbar = ref)} hideLeftPanel hideRightPanel>
                    <Pagination />
                    <Zoom />
                </Toolbar>
            </Header>
            <Main>
                {/* @ts-ignore */}
                <ViewPanel ref={(ref: any) => (_viewPanel = ref)} pdfSource={PdfSource.createFromUrl(props.url)}></ViewPanel>
            </Main>
        </PdfViewer>
    ) : (
        
        <DocViewer
             style={{ height: 500 }}
            documents={[{ uri: props.url, fileType: props.fileType }]}
            theme={{
                primary: "#F1F1F1",
                tertiary: "#323639",
                textPrimary: "#F1F1F1",
                disableThemeScrollbar: true
            }}
            config={{
                header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true
                }
            }}
        />
    );
};
