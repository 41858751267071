import { connect } from 'react-redux';
import { ApplicationState } from './../../../store';
import { OrganizerDeliveredReport } from './DeliveredOrganizer/OrganizerDeliveredReports';
import { actionCreators as DeliveredOrganizerStore } from './../../store/DeliveredOrganizerStore';
import { actionCreators as OrganizerFilterStore } from './../../store/OrganizerFilterStore';
import { actionCreators as OrganizerUploadStore } from './../../store/OrganizerUploadStore';
import { actionCreators as CompanyStore } from './../../../store/company/CompanyStore';
import { actionCreators as UserStore } from '../../../store/UserManagement/UserStore';
import { actionCreators as UserSettingStore } from '../../../store/UserManagement/UserSettingStore';
import { ActionCreators as OrganizerCompanySettingsStore } from './../../store/OrganizerCompanySettingsStore';
import { actionCreators as OrganizerPageSelectorStore } from './../../store/OrganizerPageSelectorStore';
import { actionCreators as CommonStore } from './../../store/CommonStore';
import { actionCreators as UserProfileStore } from '../../../store/UserManagement/UserProfileStore';
import { actionCreators as BulkOperationsStore } from './../../store/BulkOperationsStore';
import { actionCreators as ColumnSettingsStore } from './../../store/ColumnSettingsStore';
import { actionCreators as RoleBasedAccessControlStore } from './../../../store/roleBasedAccessControl/RoleBasedAccessControlStore';
import { actionCreators as UteMergedStore } from './../../store/UTEMergedDetailStore';


export default connect(
    (state: ApplicationState) => ({
        isArchived: true,
        pageTitle: "Organizers Archived Report",
        companyData: state.companyData,
        organizerModel: state.deliveredOrganizerReports,
        orgaizerFilter: state.organizerReportFilter,
        uploadState: state.organizerUpload,
        users: state.ssrUserList,
        partners: state.partners,
        userSettingStore: state.userSettings,
        companySettingsStore: state.organizerCompanySettings,
        userProfile: state.userProfile,
        columnSettings: state.columnSettings,
        resourceList: state.roleBasedAccessControl.resourceList,
        userLocations : state.userLocations,
        uteMergedDetails:state.uteMergedDetail
    }),
    {
        ...DeliveredOrganizerStore,
        ...OrganizerFilterStore,
        ...OrganizerUploadStore,
        ...CompanyStore,
        ...UserSettingStore,
        ...UserStore,
        ...OrganizerPageSelectorStore,
        ...CommonStore,
        ...UserProfileStore,
        ...BulkOperationsStore,
        ...OrganizerCompanySettingsStore,
        ...ColumnSettingsStore,
        ...RoleBasedAccessControlStore,
        ...UteMergedStore   
    }
)(OrganizerDeliveredReport as any);