import * as React from 'react';
import { actionCreators as DeliveredOrganizerStore } from './../../../store/DeliveredOrganizerStore';
import { actionCreators as OrganizerFilterStore } from './../../../store/OrganizerFilterStore';
import { actionCreators as OrganizerUploadStore } from './../../../store/OrganizerUploadStore';
import { actionCreators as CompanyStore } from './../../../../store/company/CompanyStore';
import { actionCreators as UserStore, ISSRUserData, IPartnerData, ILocationData } from '../../../../store/UserManagement/UserStore';
import { actionCreators as UserSettingStore, UserSettings } from '../../../../store/UserManagement/UserSettingStore';
import { ActionCreators as OrganizerCompanySettingsStore } from './../../../store/OrganizerCompanySettingsStore';
import { actionCreators as OrganizerPageSelectorStore } from './../../../store/OrganizerPageSelectorStore';
import { actionCreators as CommonStore } from './../../../store/CommonStore';
import { actionCreators as UserProfileStore } from '../../../../store/UserManagement/UserProfileStore';
import { actionCreators as BulkOperationsStore } from './../../../store/BulkOperationsStore';
import { actionCreators as ColumnSettingsStore } from './../../../store/ColumnSettingsStore';
import { actionCreators as RoleBasedAccessControlStore } from './../../../../store/roleBasedAccessControl/RoleBasedAccessControlStore';
import { OrganizerDeliveredReportToolbar } from './OrganizerDeliveredReportToolbar';
import { OrganizerUploadModalSelector } from './../UploadDocumentsModal/OrganizerUploadDocumentModal';
import { OrganizerDeliveredReportTable } from './OrganizerDeliveredReportTable';
import { RouteComponentProps } from 'react-router-dom';
import { IUteSettings, TaxSoftwareType } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ICompanyData } from './../../../../store/company/CompanyStore';
import ClientInfoModal from './../Modals/ClientInfoModal';
import { OrganizerClientTrackingModal } from './../Modals/OrganizerClientTrackingModal';
import { OrganizerDownloadModal } from './../Modals/OrganizerDownloadModal';
import { OrganizerResendAccessLinkModal } from './../Modals/OrganizerResendAccessLinkModal';
import { ReopenOrganizerModal } from './../Modals/ReopenOrganizerModal';
import { actionCreators as UTEMergedStore } from './../../../store/UTEMergedDetailStore';
import {
    apiPrefix,
    DeliveredOrganizerFilterStoreState,
    DeliveredOrganizerStoreState,
    IColumnSettingsState,
    OrganizerSettingsStoreState,
    OrganizerUploadState,
} from './../../../models/OrganizerStoreModels';
import {
    OrganizerTransaction, initialOrganizerDocument,
    OrganizerDocument, ProcessStatus, OrganizerPageOptions,
    OrganizerDocumentUploadType, OrganizerClient, OrganizerFilter,
    OrganizerFilterType, SortDirections, SourceDocumentStatus,
    EngagementLetterStatus, OrganizerStatus, OrganizerClientType,
    DownloadableDocuments, BulkOperationFilters, BulkOperationReminderRequest,
    SendReminderOption, IColumnSettings, BulkOperationAutoReminderRequest, initialOrganizerClient
} from './../../../models/OrganizerComponentModels'
import * as bootbox from 'bootbox';
import { ClientManagementConstants, createSendReminderText, DeleteConstants, ValidationWarnings } from './../../Helper/OrganizerConstants';
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import { OrganizerFilterConstants, SupportedFilePreviewTypes, ValidationContants } from './../../../../components/helper/Constants';
import { ShowLoader, HideLoader } from './../../../../components/helper/Loader';
import { GetNumberOfDays } from './../../../../components/helper/HelperFunctions';
import { formattedClientName, getTaxSoftwareType } from './../../Helper/OrganizerHelperFunctions';
import { IPartnerModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { ViewAccessCode } from '../Modals/ViewAccessCode';
import { IAccessViewModel, initialOneHubAccessCodeDetail, IOneHubAccessCodeDetail } from '../../../../components/common/TaxReturn';
import { SignedDetailsModal } from '../Modals/SignerDetailsModal';
import { SourceDocumentDownloadModal } from '../Modals/SourceDocumentDownloadModal';
import { FilePreviewModal } from "../Modals/FilePreviewModal";
import { ReportProblem } from '../../../../ReportProblem/ReportProblem';
import {
    ReportedStep, ReportProblemTypes, IReportProblemDetails,
    IReportProblemOrganizerData, initialReportProblemOrganizerData
} from '../../../../ReportProblem/ReportProblemModel';
import { IUserProfile } from '../../../../components/navigation/profile/ProfileObjects';
import ReminderOption from '../DeliveredBatchOrganizer/ReminderOption';
import { logger } from '../../../../routes';
import { ClientTypes } from './../../../../components/common/TaxReturn';
import { DeliveryStatusModal } from '../Modals/DeliveryStatusModal';
import { BaseDocumentViewModel } from '../../../viewmodels/DocumentViewModel';
import { ReopenSourceDocumentModal } from './../Modals/ReopenSourceDocumentModal';
import { AutomaticReminderOrganizerInfo, AutomaticReminderViewModel } from '../../../viewmodels/AutomaticReminderViewModel';
import moment from 'moment';
import { addNewClientInfo } from '../../../../modules/clientManagement/NewClientModal/newClientModel.apis'
import { compareClientInfo, getClientInfo } from '../../../../modules/clientManagement/clientManagement.apis'
import NewClientModal from '../../../../modules/clientManagement/NewClientModal';
import ExistingClientDataComparisonModal from '../../../../modules/clientManagement/ExistingClientDataComparisonModal';
import { ClientInfoMismatchType, IClientInfoComparisonResult, IClientInfoViewModel, initialClientInfoComparisonResult, initialClientInfoViewModel } from '../../../../modules/clientManagement/clientManagement.types';
import { createNewClientInfoComparisonResult } from '../../../../modules/clientManagement/NewClientModal/newClientModal.model';
import { updateExistingClientInfo } from '../../../../modules/clientManagement/ExistingClientDataComparisonModal/existingClientDataComparisonModal.apis';
import { CloseOrganizerModal } from '../Modals/CloseOrganizerModal';
import { removeUnwantedWhitespacesFromText } from 'src/components/helper/HelperFunctions';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { ILocations } from 'src/Core/ViewModels/Company/CompanyViewModel';
import { ResourceIdConstants } from 'src/components/helper/Rbac/RbacConstants';
import { ILinkedMergedDetails } from 'src/Organizer/store/UTEMergedDetailStore';
import UnlockModal from '../Modals/UnlockModal';
type OrganizerDeliveredReportProps = {
    companyData: ICompanyData;
    organizerModel: DeliveredOrganizerStoreState;
    orgaizerFilter: DeliveredOrganizerFilterStoreState;
    uploadState: OrganizerUploadState;
    userSettingStore: UserSettings;
    users: ISSRUserData;
    partners: IPartnerData;
    userProfile: IUserProfile;
    companySettingsStore: OrganizerSettingsStoreState;
    columnSettings: IColumnSettingsState;
    isArchived: boolean;
    pageTitle: string;
    resourceList: any;
    userLocations: ILocationData;
    uteMergedDetails: ILinkedMergedDetails
    showLocationMismatchActionModel: (show: boolean) => void;
}
    & typeof DeliveredOrganizerStore
    & typeof OrganizerFilterStore
    & typeof OrganizerUploadStore
    & typeof CompanyStore
    & typeof UserSettingStore
    & typeof UserStore
    & typeof OrganizerPageSelectorStore
    & typeof CommonStore
    & typeof UserProfileStore
    & typeof BulkOperationsStore
    & typeof ColumnSettingsStore
    & typeof OrganizerCompanySettingsStore
    & typeof RoleBasedAccessControlStore
    & typeof UTEMergedStore
    & RouteComponentProps<{}>

interface OrganizerDeliveredReportState {
    organizerClients: OrganizerClient[];
    showUpload: boolean;
    page: number;
    selectedRows: number[];
    filter: OrganizerFilter;
    sortName: string;
    sortOrder: string;
    taxSoftware: TaxSoftwareType;
    clientInfoModal: {
        documentId: number;
        model: OrganizerClient[];
        show: boolean;
        clientId: string;
        isDelivered: boolean;
        status?: EngagementLetterStatus;
        isMarriedFileJointly: boolean;
    };
    reportProblemModal: {
        documentId: number;
        organizerData: IReportProblemOrganizerData;
        show: boolean;
    };
    signerDetailsModal: {
        documentId: number;
        model: OrganizerClient[];
        show: boolean;
    };
    unlockModal: {
        show: boolean;
        organizer: OrganizerDocument
    };
    deliveryStatusModal: {
        documentId: number;
        model: OrganizerClient[];
        show: boolean;
    };
    clientTrackingModal: {
        model: OrganizerTransaction[];
        show: boolean;
        documentId: number;
    };
    resendAccessLinkModal: {
        model: OrganizerClient[];
        show: boolean;
        documentId: number;
        engagementLetterStatus: EngagementLetterStatus;
    };
    reopenOrganizerModal: {
        show: boolean;
        documentId: number;
    };
    closeOrganizerModal: {
        show: boolean;
        processId: number;
        isClosed: boolean;
    };
    downloadModal: {
        model: OrganizerDocument;
        show: boolean;
    };
    sourceDocumentDownloadModal: {
        model: DownloadableDocuments[];
        show: boolean;
        id: number;
    };
    filePreviewModal: {
        url: string,
        showPDFPreview: boolean;
        fileType: string | undefined;
        fileName: string;
    }
    saveFilterShow: boolean;

    filterTaxpayerName: string;
    filterClientId: string;
    filterDeliveredOn?: Date;
    filterEngagementLetterStatus: any;
    filterOrganizerStatus: any;
    filterSourceDocumentStatus: any;
    filterOrganizerReminder: any;
    filterSigningReminder: any;
    filterTaxYear: any;
    filterSentBy: string;
    filterEro: string;
    filterLocation: string;
    filterBatchName: string;
    filterTaxpayerEmail: string;
    filterSpouseEmail: string;
    uploadType: OrganizerDocumentUploadType;
    defaultERO: number;
    eroUsers: IPartnerModel[];
    accessCodeModal: {
        model: IAccessViewModel;
        show: boolean;
        documentId: number;
        oneHubModel: IOneHubAccessCodeDetail[];
        isUTELinked: boolean;
    };
    refreshDelay?: boolean;
    isBulkSelectionEnabled: boolean;
    deselectedRows: number[];
    showBulkSelectionMessage: boolean;
    selectedSendReminderActionRow: number[];
    reminderOptions: {
        isOrganizerReminder: boolean;
        isEngagementReminder: boolean;
        showReminder: boolean;
        isSourceReminder: boolean;
        disableEngagementReminder: boolean;
        disableOrganizerReminder: boolean;
        disableSourceReminder: boolean;
    };
    filterArchivedBy: string;

    reopenSourceDocumentUploadModal: {
        show: boolean;
        documentId: number;
    };
    columnSettings: IColumnSettings[];
    isDeleteAllIdFromCurrentPage: boolean;

    clientInfoDetails: {
        documentId: number,
        clients: OrganizerClient[],
        clientId: string,
        resendEmail: boolean,
        isTpEmailupdated?: boolean,
        isSpouseEmailupdated?: boolean,
        isMarriedFileJointly: boolean,
        isTpNameUpdated?: boolean,
        isSpouseNameUpdated?: boolean,
        isOfficeLocationUpdated?: boolean
    },
    showNewClientModal: boolean;
    showExistingClientDataComparisonModal: boolean;
    clientInfoComparisonResult: IClientInfoComparisonResult;
    hasOrganizerWithOldCustomQuestion: boolean;
    locations: ILocationData;
    locationsState: IDropdown[];
    loading: boolean;
    isShowLocationMismatchActionModel: boolean;
    isUteEnabled: boolean;
}

const PAGESIZE = 20;
const NO_INDEX = -1;

const disableEsignCodes = [23, 24, 25, 26, 28]; //“E-signed”, “Downloaded”, “NA”, “Manually Signed”
const disableOrgCodes = [3, 4, 6]; //“Completed”, “Downloaded”, “Manually Completed”

export class OrganizerDeliveredReport extends React.Component<OrganizerDeliveredReportProps, OrganizerDeliveredReportState> {
    private ReportType: OrganizerFilterType = OrganizerFilterType.DeliveredOrganizers;

    private ORGANIZER_REPORT_PAGE_NAME = 'Organizers Delivered Report';
    private editClientInfoResourceId: string = ResourceIdConstants.DeliveredOrganizerGirdEditClientBtn;

    constructor(props: OrganizerDeliveredReportProps) {
        super(props);

        if (props.isArchived) {
            this.ORGANIZER_REPORT_PAGE_NAME = "Archived Organizers Report";
            this.ReportType = OrganizerFilterType.ArchivedOrganizers
        }

        this.state = {
            organizerClients: [],
            showUpload: false,
            filter: {
                fields: {},
                name: "",
                searchText: "",
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                filterType: this.ReportType,
                isDefaultFilter: false
            },
            page: 1,
            selectedRows: [],
            sortName: "deliveredOn",
            sortOrder: "desc",
            clientInfoModal: {
                documentId: 0,
                model: [],
                show: false,
                clientId: '',
                isDelivered: false,
                status: EngagementLetterStatus.AwaitingSignature,
                isMarriedFileJointly: false
            },
            reportProblemModal: {
                documentId: 0,
                organizerData: initialReportProblemOrganizerData,
                show: false
            },
            signerDetailsModal: {
                documentId: 0,
                model: [],
                show: false
            },
            unlockModal: {
                show: false,
                organizer: initialOrganizerDocument
            },
            deliveryStatusModal: {
                documentId: 0,
                model: [],
                show: false
            },
            clientTrackingModal: {
                model: [],
                show: false,
                documentId: 0
            },
            downloadModal: {
                model: initialOrganizerDocument,
                show: false
            },
            resendAccessLinkModal: {
                model: [],
                show: false,
                documentId: 0,
                engagementLetterStatus: EngagementLetterStatus.NA
            },
            reopenOrganizerModal: {
                show: false,
                documentId: 0,
            },
            closeOrganizerModal: {
                show: false,
                processId: 0,
                isClosed: false
            },
            filePreviewModal: {
                url: "",
                showPDFPreview: false,
                fileType: "",
                fileName: "",
            },
            saveFilterShow: false,
            filterClientId: '',
            filterDeliveredOn: undefined,
            filterEngagementLetterStatus: '',
            filterOrganizerStatus: '',
            filterSourceDocumentStatus: '',
            filterOrganizerReminder: undefined,
            filterSigningReminder: undefined,
            filterSentBy: "",
            filterEro: "",
            filterLocation: "",
            filterTaxpayerName: '',
            filterTaxYear: '',
            filterBatchName: '',
            filterTaxpayerEmail: '',
            filterSpouseEmail: '',
            taxSoftware: TaxSoftwareType.None,
            uploadType: OrganizerDocumentUploadType.Proforma,
            defaultERO: 0,
            eroUsers: this.props.partners?.partners || [],
            accessCodeModal: {
                model: {
                    accessCodeDetails: [],
                    clientEvents: []
                },
                oneHubModel: initialOneHubAccessCodeDetail,
                show: false,
                documentId: 0,
                isUTELinked: false
            },
            sourceDocumentDownloadModal: {
                id: 0,
                model: [],
                show: false
            },
            isBulkSelectionEnabled: false,
            deselectedRows: [],
            showBulkSelectionMessage: false,
            selectedSendReminderActionRow: [],
            reminderOptions: {
                isEngagementReminder: false,
                isOrganizerReminder: false,
                isSourceReminder: false,
                showReminder: false,
                disableSourceReminder: false,
                disableOrganizerReminder: false,
                disableEngagementReminder: false
            },
            filterArchivedBy: '',
            reopenSourceDocumentUploadModal: {
                show: false,
                documentId: 0,
            },
            columnSettings: [],
            isDeleteAllIdFromCurrentPage: false,

            clientInfoDetails: {
                documentId: 0,
                clients: [],
                clientId: '',
                resendEmail: false,
                isMarriedFileJointly: false
            },
            showNewClientModal: false,
            showExistingClientDataComparisonModal: false,
            clientInfoComparisonResult: initialClientInfoComparisonResult,
            hasOrganizerWithOldCustomQuestion: false,
            locations: this.props.userLocations || [],
            locationsState: [],
            loading: false,
            isShowLocationMismatchActionModel: false
        }
    }

    componentDidMount() {
        this.props.requestDeliveredOrganizers(this.buildQuery(this.state.page));
        this.props.requestOrganizerReportFilter(this.ReportType);
        this.props.requestUserSettings(true);
        this.props.requestAllSSRUsers(true);
        this.props.requestUserProfile();
        this.props.requestOrganizerCompanySettings();
        if (!this.props.isArchived) {
            this.props.requestColumnSettings(OrganizerFilterType.DeliveredOrganizers);
        }
        this.props.requestUserOfficeLocations();
        this.props.getUTESettings((data: IUteSettings)=>{
            this.setState({isUteEnabled: data.uteEnabled});
        })
    }

    componentDidUpdate(prevProps: Readonly<OrganizerDeliveredReportProps>, prevState: Readonly<OrganizerDeliveredReportState>, snapshot?: any): void {
        if (this.props.organizerModel?.sourceDocumentMetaData?.path != prevProps.organizerModel?.sourceDocumentMetaData?.path) {
            if (SupportedFilePreviewTypes.includes(this.props.organizerModel.sourceDocumentMetaData?.extension?.toLowerCase())) {
                this.setState({
                    filePreviewModal: {
                        ...this.state.filePreviewModal,
                        url: this.props.organizerModel.sourceDocumentMetaData.path,
                        showPDFPreview: true,
                        fileType: this.props.organizerModel.sourceDocumentMetaData?.extension?.substring(1),
                        fileName: this.props.organizerModel.sourceDocumentMetaData.fileName
                    }
                })
            } else if (this.props.organizerModel.sourceDocumentMetaData?.extension) {
                VenusNotifier.Warning("Preview not supported. Downloading file...", "", "");
            }
        }
    }

    componentWillReceiveProps(nextProps: OrganizerDeliveredReportProps) {
        if (this.state.clientInfoModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.clientInfoModal.documentId);
            if (organizer && organizer.organizerClients) {

                this.setState({
                    clientInfoModal: {
                        ...this.state.clientInfoModal,
                        model: organizer.organizerClients,
                        isMarriedFileJointly: organizer.organizerClients.some(s => s.clientType === OrganizerClientType.Spouse)
                    }
                });
            }
        }

        if (this.state.reportProblemModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.reportProblemModal.documentId);
            if (organizer && organizer.organizerClients) {
                this.setState({
                    reportProblemModal: {
                        ...this.state.reportProblemModal,
                        organizerData: { ...this.state.reportProblemModal.organizerData, clients: organizer.organizerClients }
                    }
                });
            }
        }

        if (this.state.signerDetailsModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.signerDetailsModal.documentId);
            if (organizer && organizer.organizerClients) {
                this.setState({
                    signerDetailsModal: {
                        ...this.state.signerDetailsModal,
                        model: organizer.organizerClients
                    }
                });
            }

        }

        if (this.state.deliveryStatusModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.deliveryStatusModal.documentId);
            if (organizer && organizer.organizerClients) {
                this.setState({
                    deliveryStatusModal: {
                        ...this.state.deliveryStatusModal,
                        model: organizer.organizerClients
                    }
                });
            }

        }

        if (this.state.sourceDocumentDownloadModal.id) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.sourceDocumentDownloadModal.id);
            if (organizer && organizer.downloadDocuments && organizer.downloadDocuments.length) {
                this.setState({
                    sourceDocumentDownloadModal: {
                        ...this.state.sourceDocumentDownloadModal,
                        model: organizer.downloadDocuments
                    }
                });
            }

        }

        if (this.props.partners?.partners !== nextProps.partners?.partners && nextProps.partners.partners.length > 0) {
            this.setState({
                eroUsers: nextProps.partners.partners
            });
        }

        if (this.props.userLocations !== nextProps.userLocations) {
            this.setState({
                locations: nextProps.userLocations
            });
        }

        if (nextProps.userSettingStore.settings && nextProps.userSettingStore.settings.defaultUserSettings) {
            this.setState({
                defaultERO: nextProps.userSettingStore.settings.defaultUserSettings.eroUser
            });
        }

        if (this.state.resendAccessLinkModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.resendAccessLinkModal.documentId);
            if (organizer && organizer.organizerClients) {
                this.setState({
                    resendAccessLinkModal: {
                        ...this.state.resendAccessLinkModal,
                        model: organizer.organizerClients
                    }
                });
            }
        }

        if (this.state.clientTrackingModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.clientTrackingModal.documentId);
            if (organizer && organizer.clientTracking) {
                this.setState({
                    clientTrackingModal: {
                        ...this.state.clientTrackingModal,
                        model: organizer.clientTracking
                    }
                });
            }
        }

        if (this.state.downloadModal.model.id) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.downloadModal.model.id);
            if (organizer && ((organizer.downloadDocuments && organizer.downloadDocuments.length > 0)
                || (organizer.clientTracking && organizer.clientTracking.length > 0))) {
                this.setState({
                    downloadModal: {
                        ...this.state.downloadModal,
                        model: organizer
                    }
                });
            }
        }

        if (this.state.accessCodeModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.accessCodeModal.documentId);
            if (organizer && organizer.accessCode) {
                let accessCodeDetails = organizer.accessCode?.accessCodeDetails;
                let organizerClients = organizer.organizerClients;
                accessCodeDetails?.forEach((code) => {
                    if (organizerClients) {
                        organizerClients.forEach((client) => {
                            if (code.name === client.name) {
                                code.isDeceased = client.isDeceased;
                                code.email = client.email;
                            }
                        })
                    } else {
                        code.email = organizer.spouseEmail;
                    }
                })


                this.setState({
                    accessCodeModal: {
                        ...this.state.accessCodeModal,
                        model: organizer.accessCode
                    }
                });
            }
        }
        if (this.state.isBulkSelectionEnabled) {
            let selectedRows: number[] = [];
            nextProps.organizerModel.model.documents.forEach((value, index) => {
                if (this.state.deselectedRows.findIndex(a => a == value.id) == -1) {
                    selectedRows.push(index);
                }
            })

            this.setState({ selectedRows: selectedRows });
        }
        if (nextProps.columnSettings) {
            if (nextProps.columnSettings.deliveredOrganizersColumnSettings && !nextProps.isArchived) {
                this.setState({
                    columnSettings: nextProps.columnSettings.deliveredOrganizersColumnSettings
                });
            }
        }

    }

    buildQuery = (page: number) => {
        const { filterClientId, filterDeliveredOn,
            filterEngagementLetterStatus, filterSentBy, filterEro,
            filterTaxpayerName, filterTaxYear,
            sortName, sortOrder, filterOrganizerStatus, filterSourceDocumentStatus,
            filterBatchName, filterArchivedBy, filterOrganizerReminder, filterSigningReminder,
            filterTaxpayerEmail, filterSpouseEmail, filterLocation
        } = this.state;

        const { isArchived } = this.props;

        return `?pageNo=${page}&pageSize=${PAGESIZE}&sortBy=${sortName}&sortOrder=${sortOrder}&filterClientId=${filterClientId}` +
            `${filterDeliveredOn ? `&filterDeliveredOn=${filterDeliveredOn}` : ''}` +
            `&filterTaxpayerName=${filterTaxpayerName}&filterTaxYear=${filterTaxYear}&filterSentBy=${filterSentBy}&filterEro=${filterEro}&filterEngagementLetterStatus=${filterEngagementLetterStatus}` +
            `&filterOrganizerStatus=${filterOrganizerStatus}&filterSourceDocumentStatus=${filterSourceDocumentStatus}&filterBatchName=${filterBatchName}&isArchived=${isArchived}&filterArchivedBy=${filterArchivedBy}` +
            `${filterOrganizerReminder ? `&filterOrganizerReminder=${filterOrganizerReminder}` : ''}` +
            `${filterSigningReminder ? `&filterSigningReminder=${filterSigningReminder}` : ''}` +
            `&filterTaxpayerEmail=${filterTaxpayerEmail}` +
            `&filterSpouseEmail=${filterSpouseEmail}&filterLocationId=${filterLocation}`
    }

    getBulkOperationFilter = (): BulkOperationFilters => {
        const { filterClientId, filterDeliveredOn,
            filterEngagementLetterStatus, filterSentBy, filterEro,
            filterTaxpayerName, filterTaxYear,
            filterOrganizerStatus, filterSourceDocumentStatus, deselectedRows, filterBatchName
        } = this.state;

        let bulkFilter: BulkOperationFilters = {
            clientId: filterClientId,
            deliveredOn: filterDeliveredOn,
            engLetterStatus: filterEngagementLetterStatus,
            organizerStatus: filterOrganizerStatus,
            sourceDocStatus: filterSourceDocumentStatus,
            taxPayerName: filterTaxpayerName,
            taxYear: filterTaxYear,
            ero: filterEro,
            sentBy: filterSentBy,
            deselectedIds: deselectedRows,
            batchName: filterBatchName
        }
        return bulkFilter;
    }

    getBulkOperationReminderRequest = (): BulkOperationReminderRequest => {
        let bulkReminderRequest: BulkOperationReminderRequest = {
            ...this.getBulkOperationFilter(),
            sourceDocReminder: this.state.reminderOptions.isSourceReminder,
            organizerReminder: this.state.reminderOptions.isOrganizerReminder,
            engLetterReminder: this.state.reminderOptions.isEngagementReminder
        };
        return bulkReminderRequest;

    }

    getSendReminderOptions = (): SendReminderOption => {
        let sendReminderOptions: SendReminderOption = {
            engagementLetterReminder: this.state.reminderOptions.isEngagementReminder,
            organizerReminder: this.state.reminderOptions.isOrganizerReminder,
            sourceDocumentReminder: this.state.reminderOptions.isSourceReminder
        };

        return sendReminderOptions
    }

    onRowSelect = (row: any, isSelected: any, e: any) => {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.onRowSelectForBulkSelection(row, isSelected);
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    onRowSelectForBulkSelection = (row: any, isSelected: any) => {

        if (this.state.isBulkSelectionEnabled) {
            let deselectedRows = this.state.deselectedRows;
            if (isSelected) {
                var index = deselectedRows.indexOf(row.id);
                deselectedRows.splice(index, 1);
            }
            else {
                deselectedRows.push(row.id);
            }
            this.setState({ deselectedRows: deselectedRows })
        }
    }

    onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        let showbulkSelection: boolean = false;
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
            showbulkSelection = true;
        }
        this.setState({
            selectedRows: selectedRows,
            deselectedRows: [],
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: showbulkSelection
        });
    }

    onPageChange = (page: number, sizePerPage: number) => {
        if (this.state.isBulkSelectionEnabled) {
            this.setState({
                page: page
            }, this.loadDocuments);
        }
        else {
            this.setState({
                page: page,
                selectedRows: [],
                showBulkSelectionMessage: false
            }, this.loadDocuments);
        }
    }

    onSortChange = (sortName: string, sortOrder: string, column: number) => {
        let temp: OrganizerFilter = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc",
            selectedRows: [],
            showBulkSelectionMessage: false
        }, () => this.fetchDocuments(1));
    }

    onFilterNameChange = (event: any) => {
        let temp: OrganizerFilter = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }

    onPageReload = () => {
        this.setState(
            { refreshDelay: true }, () => {
                const query = this.buildQuery(this.state.page);
                this.props.requestDeliveredOrganizers(query, () => {
                    this.setState({
                        refreshDelay: false,
                        selectedRows: [],
                        isBulkSelectionEnabled: false,
                        showBulkSelectionMessage: false
                    });
                });
            });
    }

    onFilterChange = (dataField: any) => {

        const newState = {
            filterClientId: '',
            filterDeliveredOn: undefined,
            filterEngagementLetterStatus: '',
            filterTaxpayerName: '',
            filterEro: '',
            filterLocation: '',
            filterSentBy: '',
            filterOrganizerStatus: '',
            filterSourceDocumentStatus: '',
            filterBatchName: '',
            filterTaxpayerEmail: '',
            filterSpouseEmail: '',
            filterArchivedBy: '',
            filterOrganizerReminder: undefined,
            filterSigningReminder: undefined
        } as OrganizerDeliveredReportState;
        let isClearFilter = true;
        let filterSourceStatus: any;
        let filterEngagementStatus: any;
        let filterOrganizerStatus: any;
        let filterTaxYear: any;
        let updated = false;
        let filterLocationState: any;
        const dict: { [columnName: string]: string } = {};

        for (const field of Object.keys(dataField)) {
            const data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            if (data !== "-1") {
                dict[field.valueOf().toString()] = data;
            }

            switch (field) {

                case 'taxpayerName':
                    newState.filterTaxpayerName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'taxpayerEmail':
                    newState.filterTaxpayerEmail = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'spouseEmail':
                    newState.filterSpouseEmail = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'ero':
                    newState.filterEro = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'locationName':
                    filterLocationState = dataField[field].value ? dataField[field].value : dataField[field];
                    filterLocationState = (filterLocationState === "-1") ? "" : filterLocationState;
                    this.setState({ filterLocation: filterLocationState });
                    newState.filterLocation = filterLocationState;
                    updated = true;
                    break;
                case 'batchName':
                    newState.filterBatchName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'sentBy':
                    newState.filterSentBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;

                case 'archivedBy':
                    newState.filterArchivedBy = dataField[field].value ? dataField[field].value : dataField[field];
                    break;

                case 'deliveredOn':
                    newState.filterDeliveredOn = GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;

                case 'engagementLetterStatus':
                    filterEngagementStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterEngagementStatus = (filterEngagementStatus === "-1") ? "" : filterEngagementStatus;
                    this.setState({ filterEngagementLetterStatus: filterEngagementStatus });
                    newState.filterEngagementLetterStatus = filterEngagementStatus;
                    updated = true;
                    break;

                case 'organizerStatus':
                    filterOrganizerStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterOrganizerStatus = (filterOrganizerStatus === "-1") ? "" : filterOrganizerStatus;
                    this.setState({ filterOrganizerStatus: filterOrganizerStatus });
                    newState.filterOrganizerStatus = filterOrganizerStatus;
                    updated = true;
                    break;

                case 'sourceDocumentStatus':
                    filterSourceStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterSourceStatus = (filterSourceStatus === "-1") ? "" : filterSourceStatus;
                    this.setState({ filterSourceDocumentStatus: filterSourceStatus });
                    newState.filterSourceDocumentStatus = filterSourceStatus;
                    updated = true;
                    break;

                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = (filterTaxYear === "-1") ? "" : filterTaxYear;
                    this.setState({ filterTaxYear: filterTaxYear });
                    newState.filterTaxYear = filterTaxYear;
                    updated = true;
                    break;

                case 'organizerReminder':
                    newState.filterOrganizerReminder = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY');
                    break;

                case 'signingReminder':
                    newState.filterSigningReminder = moment(dataField[field].value ? dataField[field].value : dataField[field]).format('MM/DD/YYYY');
                    break;

                case 'deliveredOn':
                    newState.filterDeliveredOn = GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
            }
        }
        const tempfilter: OrganizerFilter = { ...this.state.filter };
        tempfilter.fields = dict;

        if (this.isFilterChanged(newState) || updated) {
            this.resetBulkSelection();
            this.setState({ ...newState, filter: tempfilter, page: 1, selectedRows: [] },
                () => {
                    const organizerReportTable: any = this.refs.organizerReportTable;
                    if (!organizerReportTable.getAppliedFilter())
                        this.props.requestDeliveredOrganizers(this.buildQuery(this.state.page))

                })
        }
    }

    private isFilterChanged(newState: OrganizerDeliveredReportState): boolean {
        return (
            newState.filterSentBy !== this.state.filterSentBy ||
            newState.filterArchivedBy !== this.state.filterArchivedBy ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterEro !== this.state.filterEro ||
            newState.filterTaxpayerName !== this.state.filterTaxpayerName ||
            newState.filterBatchName !== this.state.filterBatchName ||
            newState.filterOrganizerReminder != this.state.filterOrganizerReminder ||
            newState.filterSigningReminder != this.state.filterSigningReminder ||
            newState.filterTaxpayerEmail !== this.state.filterTaxpayerEmail ||
            newState.filterSpouseEmail !== this.state.filterSpouseEmail ||
            newState.filterLocation != this.state.filterLocation
        )
    }

    //Used for office location dropdown on grid
    getUserLocationData = () => {
        var companyLocations = this.props.companyData.companyProfile.locations;
        var userLocations = this.props.userLocations?.locations;
        if (companyLocations && userLocations) {
            const commonUserLocations = companyLocations.reduce(
                (result, item) =>
                    userLocations.some(el => el === item.locationId)
                        ? [...result, item]
                        : result,
                []
            );
            let locationList: IDropdown[] = [];
            if (commonUserLocations) {
                commonUserLocations?.forEach((location: ILocations) => {
                    const locationVal: IDropdown = {
                        label: location.name,
                        value: location.locationId
                    }
                    locationList.push(locationVal);
                })
            }
            locationList.push({
                label: 'Blanks',
                value: -2
            });
            return locationList;
        }
    }

    loadDocuments = () => {
        let queryString = this.buildQuery(this.state.page);
        this.requestDeliveredOrganizers(queryString);
    }
    requestDeliveredOrganizers = (queryString: string) => {
        this.props.requestDeliveredOrganizers(queryString, () => {
            this.setState({
                refreshDelay: false,
                selectedRows: [],
                isBulkSelectionEnabled: false,
                showBulkSelectionMessage: false
            });
        })
    }
    fetchDocuments = (page: number) => {
        let queryString = this.buildQuery(page);
        this.setState({ page: page }, () => { this.props.requestDeliveredOrganizers(queryString) });
    }

    onUploadReturnsOpen = (taxSoftware: TaxSoftwareType) => {
        if (this.state.uploadType === OrganizerDocumentUploadType.Proforma) {
            logger?.trackPageView("Upload proforma document");
        }
        else {
            logger?.trackPageView("Upload batch document");
        }
        this.setState({
            showUpload: true,
            taxSoftware: taxSoftware
        });
    }

    onUploadDocumentHide = () => {
        logger?.trackPageView(this.ORGANIZER_REPORT_PAGE_NAME);
        this.setState({
            showUpload: false,
            taxSoftware: TaxSoftwareType.None
        })
    }

    onEditClientInfoOpen = (index: number) => {
        logger?.trackPageView('Edit Client Info Modal');
        const organizer = this.props.organizerModel.model.documents[index];
        if (organizer) {
            this.props.requestOrganizerClients(organizer.id);

            this.setState({
                clientInfoModal: {
                    documentId: organizer.id,
                    model: organizer.organizerClients,
                    show: true,
                    clientId: organizer.clientId,
                    isDelivered: organizer.processStatus == ProcessStatus.Delivered ? true : false,
                    status: organizer.engagementLetterStatus,
                    isMarriedFileJointly: organizer.organizerClients?.some(s => s.clientType === OrganizerClientType.Spouse) ?? false
                }
            })
        }
    }

    onReportProblemOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        if (organizer) {
            this.props.requestOrganizerClients(organizer.id);
            this.setState({
                reportProblemModal: {
                    ...this.state.reportProblemModal,
                    documentId: organizer.id,
                    show: true,
                    organizerData: {
                        ...this.state.reportProblemModal.organizerData,
                        taxYear: organizer.taxYear,
                        clientId: organizer.clientId,
                        signatureStatus: organizer.engagementLetterStatus.toString(),
                        organizerStatus: organizer.organizerStatus.toString(),
                        sourceDocumentStatus: this.getSourceDocumentStatusForReportProblem(organizer.sourceDocumentStatus),
                        taxpayerName: formattedClientName(organizer.taxpayerName),
                        clients: organizer.organizerClients,
                        documentId: organizer.id,
                        taxSoftware: getTaxSoftwareType(organizer.taxSoftware)
                    }
                }
            });
        }
    }

    getSourceDocumentStatusForReportProblem = (status: SourceDocumentStatus) => {
        return this.getSourceDocumentUploadEnabled() ?
            status.toString() :
            (status == SourceDocumentStatus.AwaitingUpload ? SourceDocumentStatus.NA.toString() : status.toString());
    }

    onReportProblemCancel = () => {
        logger?.trackPageView(this.ORGANIZER_REPORT_PAGE_NAME);
        this.setState({ reportProblemModal: { ...this.state.reportProblemModal, organizerData: initialReportProblemOrganizerData, documentId: 0, show: false } })
    }

    onReportProblemSave = (problemDetails: IReportProblemDetails) => {
        logger?.trackPageView(this.ORGANIZER_REPORT_PAGE_NAME);

        let url = this.props.isArchived ? `${apiPrefix}ArchivedOrganizer/SaveHelpReportProblem` : `${apiPrefix}DeliveredOrganizer/SaveHelpReportProblem`;

        this.props.reportProblemSave(
            problemDetails,
            url,
            (data) => {
                this.setState({
                    reportProblemModal: {
                        ...this.state.reportProblemModal,
                        organizerData: initialReportProblemOrganizerData,
                        documentId: 0,
                        show: false
                    }
                });
                VenusNotifier.Success("Problem reported successfully. Your Tracking ID is " + data + ".", "Report a Problem");
            });
    }

    onEditClientInfoClose = () => {
        logger?.trackPageView(this.ORGANIZER_REPORT_PAGE_NAME);

        this.setState({
            clientInfoModal: {
                documentId: 0,
                model: [],
                show: false,
                clientId: '',
                isDelivered: false,
                isMarriedFileJointly: false
            }
        })
    }

    onClientTrackingModalOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        this.props.requestOrganizerClientTracking(organizer.id);
        this.setState({
            clientTrackingModal: {
                model: [],
                show: true,
                documentId: organizer.id
            }
        })
    }

    onClientTrackingModalClose = () => {
        logger?.trackPageView(this.ORGANIZER_REPORT_PAGE_NAME);
        this.setState({
            clientTrackingModal: {
                model: [],
                show: false,
                documentId: 0
            }
        })
    }

    onDownloadModalOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        this.props.getDownloadableDocuments(organizer.id, organizer.clientId, organizer.batchId, organizer.batchName, organizer.taxYear);
        this.props.requestOrganizerClientTracking(organizer.id);
        this.setState({
            downloadModal: {
                model: organizer,
                show: true,
            }
        })
    }

    refreshOnDownload = (id: number) => {
        const organizer = this.props.organizerModel.model.documents.find(item => item.id == id);
        if (organizer) {
            this.props.getDownloadableDocuments(organizer.id, organizer.clientId, organizer.batchId, organizer.batchName, organizer.taxYear);
            this.props.requestOrganizerClientTracking(organizer.id);
        }
    }

    onDownloadModalClose = () => {
        this.setState({
            downloadModal: {
                model: initialOrganizerDocument,
                show: false,
            }
        })
    }

    private onUnlockOrganizer = (documentId: number) => {
        ShowLoader();
        let url = `${apiPrefix}DeliveredOrganizer/UnlockDocument`
        this.props.unlockDocument(documentId, url, ()=> {
            this.setState({
                unlockModal: {
                    ...this.state.unlockModal,
                    show: false
                }
            });
            this.reloadTableData()
        });
    }

    private onOneHubUnlock = (email: string) => {
        ShowLoader();
        this.props.unlockOneHub(email, ()=> {
            this.setState({
                unlockModal: {
                    ...this.state.unlockModal,
                    show: false
                }
            });
            this.reloadTableData()
        });
    }

    private onClientViewLinkClick = (rowIndex: number, clientType: OrganizerClientType, callback: () => void) => {
        ShowLoader();
        let document = this.props.organizerModel.model.documents[rowIndex];
        document.clientType = clientType;
        let url = this.props.isArchived ? `${apiPrefix}ArchivedOrganizer/TaxPayerClientView` : `${apiPrefix}Preview/TaxPayerClientView`
        this.props.generateClientView(document, clientType, url, callback);
    }

    private onRequestOrganizerClients = (rowIndex: number) => {
        let organizerId = this.props.organizerModel.model.documents[rowIndex].id;
        this.props.requestOrganizerClients(organizerId, this.setOrganizerClients);
    }

    private onCancelOrganizerClients = () => {
        this.setState({ organizerClients: [] });
    }

    private setOrganizerClients = (clients: OrganizerClient[]) => {
        this.setState({ organizerClients: clients });
    };

    private reloadTableData = () => {
        HideLoader();
        this.setState({ selectedRows: [] }, this.onPageReload);
    }

    onResendAccessLinkModalOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        this.props.requestOrganizerClients(organizer.id);
        this.setState({
            resendAccessLinkModal: {
                model: organizer.organizerClients,
                show: true,
                documentId: organizer.id,
                engagementLetterStatus: organizer.engagementLetterStatus
            }
        });
    }

    onReopenOrganizerLinkModalOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        this.setState({
            reopenOrganizerModal: {
                show: true,
                documentId: organizer.id,
            }
        });
    }
    onReopenOrganizerLinkModalClose = () => {
        this.setState({
            reopenOrganizerModal: {
                show: false,
                documentId: 0
            }
        })
    }

    onCloseOrganizerLinkModalOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        this.setState({
            closeOrganizerModal: {
                show: true,
                processId: organizer.id,
                isClosed: organizer.organizerStatus == OrganizerStatus.ClosedByFirm
            }
        });
    }
    onCloseOrganizerLinkModalClose = () => {
        this.setState({
            closeOrganizerModal: {
                show: false,
                processId: 0,
                isClosed: false
            }
        })
    }

    onResendAccessLinkModalClose = () => {
        this.setState({
            resendAccessLinkModal: {
                model: [],
                show: false,
                documentId: 0,
                engagementLetterStatus: EngagementLetterStatus.NA
            }
        })
    }

    onReopenSourceDocumentModalOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        this.setState({
            reopenSourceDocumentUploadModal: {
                show: true,
                documentId: organizer.id
            }
        })
    }

    onReopenSourceDocumentModalClose = () => {
        this.setState({
            reopenSourceDocumentUploadModal: {
                show: false,
                documentId: 0
            }
        })
    }

    reopenSourceDocumentUpload = () => {
        ShowLoader();
        let documentId = this.state.reopenSourceDocumentUploadModal.documentId;
        this.onReopenSourceDocumentModalClose();
        this.props.reopenSourceDocumentUpload(documentId, this.reloadTableData);
    }

    onDeleteModalOpen = (index: number) => {
        const selectedRowcount = this.getSelectedRowCount();

        const ids = this.onPopupOpen(index);

        if (selectedRowcount == 0 && ids.length == 0) {
            VenusNotifier.Warning("Please select organizer(s) to delete!", null);
            return;
        }

        const { organizerModel: {
            model: { count: totalOrganizers,
                documents: { length: currentPageOrganizers }
            } },
            recycleOrganizers,
            recycleOrganizersBulk,
            isArchived
        } = this.props;

        const { page, isBulkSelectionEnabled } = this.state;

        const totalPages = Math.ceil(totalOrganizers / PAGESIZE)

        if (!isBulkSelectionEnabled &&
            page === totalPages &&
            totalPages !== 1 &&
            ids.length === currentPageOrganizers) {
            this.setState({ isDeleteAllIdFromCurrentPage: true });
        }

        bootbox.confirm({
            title: DeleteConstants.Title,
            message: isArchived ? DeleteConstants.ArchivedConfirmDeletion : DeleteConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove" data-test-auto="b1a000f5-c894-4484-9a83-09b48eba5218"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok" data-test-auto="9735e7cf-dda2-468c-9805-3fc4a939e71e"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader();
                    isBulkSelectionEnabled ?
                        recycleOrganizersBulk(this.getBulkOperationFilter(), this.onDeleteCompletion)
                        :
                        recycleOrganizers(this.props.isArchived ? "RecycledOrganizers/archived/Recycle" : "RecycledOrganizers/delivered/Recycle", ids, this.onDeleteCompletion);
                }
            }
        });
    }

    onDeleteCompletion = () => {
        if (this.state.isDeleteAllIdFromCurrentPage) {
            this.setState({ page: this.state.page - 1, isDeleteAllIdFromCurrentPage: false });
        }
        HideLoader();
        this.onPageReload()
    }

    onSaveFilterHide = () => {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow = () => {
        this.setState({
            saveFilterShow: true
        });
    }

    ValidateFilterName = (filterName: string) => {
        return /[#?//\\]/.test(filterName) == true;
    }
    onFilterSave = (onApplyFilter: (filter: OrganizerFilter) => void) => {
        const { orgaizerFilter: { filters }, saveOrganizerReportFilter } = this.props;
        const { filter } = this.state;
        if (filter.name?.trim() != '') {
            filter.name = removeUnwantedWhitespacesFromText(filter.name);

            const duplicateFilter = filters.find(x => x.name.toLowerCase() == filter.name.toLowerCase() && x.filterType == this.ReportType);

            if (this.ValidateFilterName(filter.name)) {
                VenusNotifier.Warning(OrganizerFilterConstants.FilterNameIsNotValid, null);
            }
            else if (duplicateFilter) {
                VenusNotifier.Warning(OrganizerFilterConstants.FilterNameAlreadyExists, null);
            }
            else {
                saveOrganizerReportFilter(filter.name, filter, () => {
                    onApplyFilter(filter);
                    this.onSaveFilterHide();
                });
            }
        }
        else {
            VenusNotifier.Warning(OrganizerFilterConstants.PleaseEnterFilterName, null);
        }
    }

    onFilterUpdate = (name: string, actionName: string) => {
        const { orgaizerFilter: { filters }, updateOrganizerReportFilter } = this.props;
        const { filter } = this.state;
        const duplicateFilter = filters.find(x => x.name == name && x.filterType == this.ReportType);
        if (!duplicateFilter) {
            VenusNotifier.Warning(OrganizerFilterConstants.FilterDoesNotExists, null);
        }
        else {
            filter.name = name;
            updateOrganizerReportFilter(name, actionName, filter, () => {
                this.onSaveFilterHide();
            });
        }
    }

    onSetDefaultFilter = (name: string, actionName: string) => {
        const { orgaizerFilter: { filters }, updateOrganizerReportFilter } = this.props;
        let oldDefaultFilter = filters.find(x => x.isDefaultFilter && x.filterType == this.ReportType);
        if (oldDefaultFilter && oldDefaultFilter.name != name) {
            oldDefaultFilter.isDefaultFilter = false;
            updateOrganizerReportFilter(oldDefaultFilter.name, actionName, oldDefaultFilter);
        }

        const filter = filters.filter(x => x.name == name && x.filterType == this.ReportType);
        if (filter) {
            filter[0].isDefaultFilter = true;
            updateOrganizerReportFilter(name, actionName, filter[0], undefined, true);

        }
    }

    onRemoveDefaultFilter = (name: string, actionName: string) => {
        const { orgaizerFilter: { filters }, updateOrganizerReportFilter } = this.props;
        let filter = filters.find(x => x.name == name);
        if (filter) {
            filter.isDefaultFilter = false;
            updateOrganizerReportFilter(name, actionName, filter, undefined, true);
        }
        let organizerReportTable: any = this.refs.organizerReportTable;
        organizerReportTable.refs?.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload();
    }

    onFilterDelete = (filterName: string) => {
        this.props.removeOrganizerReportFilter(filterName, this.ReportType);
    }

    onExportToExcel = (callback: () => void) => {
        let url = this.props.isArchived ? `${apiPrefix}ArchivedOrganizer/ExportOrganizers` : `${apiPrefix}DeliveredOrganizer/ExportOrganizers`;
        this.props.exportOrganizersAsExcel(this.buildQuery(this.state.page), url, callback);
    }

    getselectedDocumentViewModels = () => {
        const docs: BaseDocumentViewModel[] = [];
        const { selectedRows } = this.state;
        const { organizerModel: { model: { documents } } } = this.props;
        for (var i in selectedRows) {
            let row = selectedRows[i];
            let selectedDocument = documents[row];
            if (selectedDocument) {
                docs.push(new BaseDocumentViewModel(selectedDocument.id,
                    selectedDocument.processInfoGuid,
                    selectedDocument.clientId,
                    selectedDocument.processStatus,
                    selectedDocument.taxYear));
            }
        }
        return docs;
    }

    selectedDocuments = () => {
        const docs: OrganizerDocument[] = [];
        const { selectedRows } = this.state;
        const { organizerModel: { model: { documents } } } = this.props;
        for (var i in selectedRows) {
            let row = selectedRows[i];
            let selectedDocument = documents[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    selectedDocumentIds = () => {
        const ids: number[] = [];
        const { selectedRows, selectedSendReminderActionRow } = this.state;
        const { organizerModel: { model: { documents } } } = this.props;
        const rowsSelected = selectedSendReminderActionRow.length ? selectedSendReminderActionRow : selectedRows;
        rowsSelected.map((row, i) => {
            let selectedDocument = documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.id);
            }
        });
        return ids;
    }

    selectedDocumentIdsForCloseOpenAction = () => {
        const ids: number[] = [];
        const { selectedRows, selectedSendReminderActionRow } = this.state;
        const { organizerModel: { model: { documents } } } = this.props;
        const rowsSelected = selectedSendReminderActionRow.length ? selectedSendReminderActionRow : selectedRows;
        rowsSelected.forEach((row) => {
            let selectedDocument = documents[row];
            if (selectedDocument && !selectedDocument.hasOrganizerWithOldCustomQuestion) {
                ids.push(selectedDocument.id);
            }
        });
        return ids;
    }

    getSelectedAutoremidnerInfo = () => {
        const autoReminderList: AutomaticReminderOrganizerInfo[] = [];
        const { selectedRows, selectedSendReminderActionRow } = this.state;
        const { organizerModel: { model: { documents } } } = this.props;
        const rowsSelected = selectedSendReminderActionRow.length ? selectedSendReminderActionRow : selectedRows;
        rowsSelected.map((row, i) => {
            let selectedDocument = documents[row];
            if (selectedDocument) {
                const { id, deliveredOn, engagementLetterStatus, organizerStatus, signingReminder, organizerReminder } = selectedDocument;
                if (!disableEsignCodes.includes(engagementLetterStatus) || this.isPartiallySigned(selectedDocument) || !disableOrgCodes.includes(organizerStatus)) {
                    autoReminderList.push(new AutomaticReminderOrganizerInfo(id, deliveredOn, engagementLetterStatus, organizerStatus, signingReminder, organizerReminder));
                }
            }
        });
        return autoReminderList;
    }

    isPartiallySigned = (selectedDocument: OrganizerDocument) => {
        if (selectedDocument.engagementLetterStatus === EngagementLetterStatus.PartiallySigned || selectedDocument.engagementLetterStatus === EngagementLetterStatus.PartiallyReviewed) {
            return true;
        }

        return false;
    }

    onSendReminderClick = () => {
        const selectedRowcount = this.getSelectedRowCount();

        if (selectedRowcount == 0) {
            alert("Error: Please select one or more recipients");
        } else {
            const selectedRowNo = this.state.selectedRows?.[0];
            this.updateStateOnSendReminder(selectedRowcount, selectedRowNo);
        }
    }

    onSendReminderActionClick = (index: number) => {
        this.setState({ selectedSendReminderActionRow: [index] });
        this.updateStateOnSendReminder(1, index);
    }

    getSelectedRecords = () => {
        let documents = this.props.organizerModel.model.documents;
        return this.state.selectedRows.map(x => {
            return documents[x];
        });
    }

    updateStateOnSendReminder = (selectedRowcount: number, selectedRowNo: number) => {
        let isValid = true;
        let isOrganizerDisabled = false;
        let isELDisabled = false;
        let isSourceDocumentsDisabled = !this.props.companySettingsStore.companySettings.sourceDocumentSettings.enabledSourceDocuments;

        if (selectedRowcount == 1) {
            const selectedDocument = this.props.organizerModel.model.documents[selectedRowNo];

            if (selectedDocument.organizerStatus === OrganizerStatus.ClosedByFirm) {
                HideLoader();
                VenusNotifier.Error(ValidationContants.MultiSelectWarning, null)
                return;
            }
            if ((selectedDocument.engagementLetterStatus === EngagementLetterStatus.NA ||
                selectedDocument.engagementLetterStatus === EngagementLetterStatus.EngagementLetterDownloaded ||
                selectedDocument.engagementLetterStatus === EngagementLetterStatus.ESigned ||
                selectedDocument.engagementLetterStatus === EngagementLetterStatus.ManuallySigned) &&
                (selectedDocument.organizerStatus === OrganizerStatus.Completed ||
                    selectedDocument.organizerStatus === OrganizerStatus.OrganizerDownloaded ||
                    selectedDocument.organizerStatus === OrganizerStatus.ManuallyCompleted)
                && (isSourceDocumentsDisabled == true || selectedDocument.isSourceDocumentUploadCompleted)
            ) {
                isValid = false;
            }


            if (isValid) {
                isELDisabled = (selectedDocument.engagementLetterStatus === EngagementLetterStatus.NA ||
                    selectedDocument.engagementLetterStatus === EngagementLetterStatus.ESigned ||
                    selectedDocument.engagementLetterStatus === EngagementLetterStatus.EngagementLetterDownloaded ||
                    selectedDocument.engagementLetterStatus === EngagementLetterStatus.ManuallySigned ||
                    selectedDocument.engagementLetterStatus == EngagementLetterStatus.Reviewed) ? true : false;

                isOrganizerDisabled = (selectedDocument.organizerStatus === OrganizerStatus.Completed ||
                    selectedDocument.organizerStatus === OrganizerStatus.OrganizerDownloaded ||
                    selectedDocument.organizerStatus === OrganizerStatus.ManuallyCompleted) ? true : false;

                isSourceDocumentsDisabled = isSourceDocumentsDisabled || selectedDocument.isSourceDocumentUploadCompleted;
            }


        }
        else if (this.getSelectedRecords().every(x => x.organizerStatus === OrganizerStatus.ClosedByFirm)) {
            HideLoader();
            VenusNotifier.Error(ValidationContants.MultiSelectWarning, null)
            return;
        }
        if (!isValid) {
            HideLoader();
            VenusNotifier.Error("Error : We cannot send reminder email notification for the selected organizer(s).", null)
        }
        else if (selectedRowcount > 0 && isValid)
            this.setState({
                reminderOptions: {
                    ...this.state.reminderOptions,
                    disableEngagementReminder: isELDisabled,
                    disableOrganizerReminder: isOrganizerDisabled,
                    showReminder: true,
                    disableSourceReminder: isSourceDocumentsDisabled
                }
            });
    }

    OnSendClickReminderPopup = () => {
        this.closeReminderPopUp();
        let selectedIds = this.selectedDocumentIds();
        if (!this.state.isBulkSelectionEnabled && selectedIds.length == 1) {
            this.sendReminderActionCall(selectedIds);
        } else {
            let actionCall = this.state.isBulkSelectionEnabled ? this.sendReminderBulkActionCall : this.sendReminderActionCall;
            actionCall(selectedIds);
        }
    }

    sendReminderBulkActionCall = () => {
        this.props.sendReminderBulkAsync(this.getBulkOperationReminderRequest(), () => {
            this.clearReminderOptions();
            HideLoader();
        });
    }

    sendReminderActionCall = (ids: number[]) => {
        this.props.sendReminderAsync(ids, this.getSendReminderOptions(), () => {
            this.clearReminderOptions();
            HideLoader();
        });
    }

    getReminderWarningText = () => {
        const { reminderOptions: { isEngagementReminder, isOrganizerReminder, isSourceReminder } } = this.state;
        return createSendReminderText(isOrganizerReminder, isEngagementReminder, isSourceReminder);
    }

    updateReminderActionCall = (autoReminderViewmodel: AutomaticReminderViewModel) => {

        this.props.updateAutoReminder(autoReminderViewmodel, () => {
            this.clearReminderOptions();
            HideLoader();
        });
    }

    updateBulkReminderActionCall = (bulkAutoReminderRequest: BulkOperationAutoReminderRequest) => {

        this.props.updateBulkAutoReminder(bulkAutoReminderRequest, () => {
            this.clearReminderOptions();
            HideLoader();
        });
    }

    onPopupOpen = (rowIndex: number) => {
        if (rowIndex !== NO_INDEX) {
            return [this.props.organizerModel.model.documents[rowIndex].id];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    onCloseOpenPopupOpen = (rowIndex: number) => {
        if (rowIndex !== NO_INDEX) {
            return [this.props.organizerModel.model.documents[rowIndex].id];
        }
        if (this.state.selectedRows.length == 0) {

            return [];
        }
        return this.selectedDocumentIdsForCloseOpenAction();
    }

    clientInfoSave = (isShowLoader: boolean = true) => {
        let url = `${apiPrefix}DeliveredOrganizer/Clients`

        if (isShowLoader) ShowLoader();

        this.props.saveClientInfo(url,
            this.state.clientInfoDetails.clients,
            this.state.clientInfoDetails.clientId,
            this.state.clientInfoDetails.documentId,
            () => {
                this.resetClientManagementDetails();
                this.props.refreshDeliveredOrganizers();
            },
            this.state.clientInfoDetails.resendEmail,
            this.state.clientInfoDetails.isTpEmailupdated,
            this.state.clientInfoDetails.isSpouseEmailupdated,
            this.state.clientInfoDetails.isTpNameUpdated,
            this.state.clientInfoDetails.isSpouseNameUpdated,
            this.state.clientInfoDetails.isOfficeLocationUpdated
        );

        logger?.trackPageView(this.ORGANIZER_REPORT_PAGE_NAME);
    }

    resendAccessLink = (client: OrganizerClient) => {
        let documentId = this.state.resendAccessLinkModal.documentId;
        let url = this.props.isArchived ?
            `${apiPrefix}ArchivedOrganizer/ResendAccessLink/${documentId}` :
            `${apiPrefix}Mail/ResendAccessLink/${documentId}`;
        this.props.resendAccessLink(documentId, url, client);
    }

    onReopenOrganizerModalConfirmClick = () => {
        this.onReopenOrganizerLinkModalClose();
        this.props.onReopenOrganizerModalConfirmClick(this.state.reopenOrganizerModal.documentId, this.onReopenedOrganizer);
    }

    onCloseOrganizerModalConfirmClick = () => {
        this.onCloseOrganizerLinkModalClose();
        this.props.onCloseOrganizerModalConfirmClick(this.state.closeOrganizerModal.processId, this.props.isArchived, this.state.closeOrganizerModal.isClosed, this.onClosedOrganizer);
    }

    onReopenedOrganizer = () => {
        HideLoader();
        this.loadDocuments();
    }

    onClosedOrganizer = () => {
        HideLoader();
        this.loadDocuments();
    }

    onChangeUploadType = (option: any) => {
        this.setState({
            uploadType: option.value
        });
    }

    onViewAccessCodeOpen = (rowIndex: number = NO_INDEX) => {
        const organizer = this.props.organizerModel.model.documents[rowIndex];
        let emails: string[] = [];
        if(organizer) {
            organizer.taxpayerEmail && emails.push(organizer.taxpayerEmail);
            organizer.spouseEmail && emails.push(organizer.spouseEmail);
        } 
        this.props.requestOrganizerAccessCode(organizer.id);
        this.props.requestOneHubAccessCode(emails, (data: IOneHubAccessCodeDetail[])=>{
            this.setState({
                accessCodeModal: {
                ...this.state.accessCodeModal,
                oneHubModel: data
                }
            });
        })
        this.setState({
            accessCodeModal: {
                ...this.state.accessCodeModal,
                show: true,
                documentId: organizer.id,
                isUTELinked: organizer?.isUTELinked || false
            }
        });
    }

    onSignedDetailsOpen = (rowIndex: number = NO_INDEX) => {
        const organizer = this.props.organizerModel.model.documents[rowIndex];
        this.props.requestOrganizerClients(organizer.id);
        this.setState({
            signerDetailsModal: {
                ...this.state.signerDetailsModal,
                show: true,
                documentId: organizer.id
            }
        });
    }

    onUnlockOpen = (rowIndex: number = NO_INDEX) => {
        const organizer = this.props.organizerModel.model.documents[rowIndex];
        this.setState({
            unlockModal: {
                show: true,
                organizer
            }
        })
    }

    onDeliveryStatusOpen = (rowIndex: number = NO_INDEX) => {
        const organizer = this.props.organizerModel.model.documents[rowIndex];
        this.props.requestDeliveryStatus(organizer.id);
        this.setState({
            deliveryStatusModal: {
                ...this.state.deliveryStatusModal,
                show: true,
                documentId: organizer.id
            }
        });
    }

    onSignedDetailsClose = () => {
        this.setState({
            signerDetailsModal: {
                model: [],
                show: false,
                documentId: 0
            }
        });
    }

    onDeliveryStatusClose = () => {
        this.setState({
            deliveryStatusModal: {
                model: [],
                show: false,
                documentId: 0
            }
        });
    }

    sourceDocumentDownloadModalOpen = (rowIndex: number = NO_INDEX) => {
        const organizer = this.props.organizerModel.model.documents[rowIndex];
        this.props.getDownloadableDocuments(organizer.id, organizer.clientId, organizer.batchId, organizer.batchName, organizer.taxYear);
        this.setState({
            sourceDocumentDownloadModal: {
                ...this.state.sourceDocumentDownloadModal,
                show: true,
                id: organizer.id
            }
        });
    }

    sourceDocumentDownloadModalClose = () => {
        this.setState({
            sourceDocumentDownloadModal: {
                model: [],
                show: false,
                id: 0
            }
        });
    }

    onGenerateOTP = (documentId: number, clientGUID: string, clientType: ClientTypes, callBack: () => void) => {
        let url = this.props.isArchived ? `${apiPrefix}ArchivedOrganizer/AccessCode/${documentId}/${clientGUID}/${clientType}` : `${apiPrefix}Document/AccessCode/${documentId}/${clientGUID}/${clientType}`;
        this.props.generateOTP(documentId, clientGUID, url, callBack);
    }

    onGenerateOTPForOneHub = (email: string, callback: () => void) => {
        this.props.generateOneHubOTP(email, (data)=>{
        let onehubdata = this.state.accessCodeModal.oneHubModel;
        onehubdata.map(item => {
        if (item.clientEmail === data.clientEmail) {
          item.accessCode = data.accessCode;
          item.otpDate = data.otpDate;
          item.accessCodeStatus = data.accessCodeStatus;
          return item;
        } else {
          return item;
        }
      })

      this.setState({
        accessCodeModal: {
          ...this.state.accessCodeModal,
          oneHubModel: onehubdata
        }
      });
      callback();
     })
    };

    onViewAccessCodeCancel = () => {
        this.setState({
            accessCodeModal: {
                ...this.state.accessCodeModal,
                model: {
                    accessCodeDetails: [],
                    clientEvents: []
                },
                show: false,
                documentId: 0
            }
        });
    }

    onClickBulkSelection = () => {
        this.setState({ isBulkSelectionEnabled: true, showBulkSelectionMessage: false });
    }

    onLinkClick = (emailAddress:string) => {
        this.props.requestUteMergedDetails(emailAddress)
    }

    getSelectedRowCount = () => {
        if (this.state.isBulkSelectionEnabled) {
            let count = this.props.organizerModel.model.count - this.state.deselectedRows.length;
            return count > 0 ? count : 0
        }
        else
            return this.state.selectedRows.length;
    }

    resetBulkSelection = () => {
        this.setState({
            isBulkSelectionEnabled: false,
            showBulkSelectionMessage: false
        })
    }

    OnChangeSourceReminder = (sourceReminder: boolean) => {
        this.setState({
            reminderOptions: {
                ...this.state.reminderOptions,
                isSourceReminder: sourceReminder
            }
        });
    }

    OnChangeEngagementReminder = (engagementReminder: boolean) => {
        this.setState({
            reminderOptions: {
                ...this.state.reminderOptions,
                isEngagementReminder: engagementReminder
            }
        });
    }

    OnChangeOrganizerReminder = (organizerReminder: boolean) => {
        this.setState({
            reminderOptions: {
                ...this.state.reminderOptions,
                isOrganizerReminder: organizerReminder
            }
        });
    }

    clearReminderOptions = () => {
        this.setState({
            selectedSendReminderActionRow: [],
            reminderOptions: {
                showReminder: false,
                isEngagementReminder: false,
                isOrganizerReminder: false,
                isSourceReminder: false,
                disableOrganizerReminder: false,
                disableEngagementReminder: false,
                disableSourceReminder: false
            }
        });
    }

    closeReminderPopUp = () => {
        this.setState({
            reminderOptions: {
                ...this.state.reminderOptions,
                showReminder: false
            }
        });
    }

    getSourceDocumentUploadEnabled = () => {
        const { companySettingsStore: { companySettings } } = this.props;
        return companySettings ? companySettings.sourceDocumentSettings ? companySettings.sourceDocumentSettings.enabledSourceDocuments : false : false
    }

    onHidePDFPreview = () => {
        this.setState({
            filePreviewModal: {
                ...this.state.filePreviewModal,
                showPDFPreview: false
            }
        })
    }

    updateStatusToManual = (updateEngagementLetter: boolean, updateOrganizer: boolean) => {
        ShowLoader("Updating...Please wait");
        this.props.updateStatusToManual(this.getselectedDocumentViewModels(), updateEngagementLetter, updateOrganizer, this.onUpdatedToManualProcess);
    }

    onUpdatedToManualProcess = () => {
        HideLoader();
        this.loadDocuments();
    }

    onCloseOpenByFirm = () => {
        const selectedRowcount = this.getSelectedRowCount();
        const ids = this.onCloseOpenPopupOpen(NO_INDEX);

        if (selectedRowcount == 0 && ids.length == 0) {
            VenusNotifier.Warning(ValidationWarnings.OpenCloseOrganizersRecordsSelections, null);
            return;
        }

        const { organizerModel: {
            model: { count: totalOrganizers,
                documents: { length: currentPageOrganizers }
            } },
            closeOpenOrganizers
        } = this.props;

        const { page, isBulkSelectionEnabled } = this.state;

        const selectedDocuments = this.selectedDocuments();

        if (selectedDocuments && selectedDocuments.length > 0) {
            if (selectedDocuments.every(x => x.organizerStatus === OrganizerStatus.ClosedByFirm && x.hasOrganizerWithOldCustomQuestion)) {
                VenusNotifier.Error(ValidationContants.MultiSelectOldCQWarning, null)
                return;
            }
        }

        const totalPages = Math.ceil(totalOrganizers / PAGESIZE)

        if (!isBulkSelectionEnabled &&
            page === totalPages &&
            totalPages !== 1 &&
            ids.length === currentPageOrganizers) {
            this.setState({ page: totalPages - 1 });
        }

        bootbox.confirm({
            title: '<i className="text-secondary fas fa-archive"></i>Close/Open Organizer',
            message: "<p>All closed organizers selected will reopen to allow taxpayer access. All open organizers selected will close to restrict taxpayer access. Do you wish to proceed? </p>",
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader();
                    if (!isBulkSelectionEnabled) {
                        closeOpenOrganizers(ids, this.props.isArchived, () => {
                            this.onPageReload();
                            HideLoader();
                        });
                    }
                }
            }
        });
    }

    onArchive = () => {

        const selectedRowcount = this.getSelectedRowCount();

        const ids = this.onPopupOpen(NO_INDEX);

        if (selectedRowcount == 0 && ids.length == 0) {
            VenusNotifier.Warning("Please select organizer(s) to archive!", null);
            return;
        }

        const { organizerModel: {
            model: { count: totalOrganizers,
                documents: { length: currentPageOrganizers }
            } },
            archiveOrganizers,
            archiveOrganizersBulk
        } = this.props;

        const { page, isBulkSelectionEnabled } = this.state;

        const totalPages = Math.ceil(totalOrganizers / PAGESIZE)

        if (!isBulkSelectionEnabled &&
            page === totalPages &&
            totalPages !== 1 &&
            ids.length === currentPageOrganizers) {
            this.setState({ page: totalPages - 1 });
        }

        bootbox.confirm({
            title: '<i className="text-secondary fas fa-archive"></i>Archive Organizer',
            message: "<p>You are about to archive the selected organizer(s). Do you want to proceed? </p>"
                + "<p class='please-note-msg'> Note: Any scheduled reminders will be turned off for archived organizer(s).</p>",
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader();
                    isBulkSelectionEnabled ?
                        archiveOrganizersBulk(this.getBulkOperationFilter(), () => {
                            this.onPageReload();
                            HideLoader();
                        })
                        :
                        archiveOrganizers(ids, () => {
                            this.onPageReload();
                            HideLoader();
                        });
                }
            }
        });
    }

    onRestore = (rowIndex: any) => {
        const ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning("Please select organizer(s) to restore!", null);
            return;
        }

        const { organizerModel: {
            model: { count: totalOrganizers,
                documents: { length: currentPageOrganizers }
            } },
            restoreArchivedOrganizers
        } = this.props;

        const totalPages = Math.ceil(totalOrganizers / PAGESIZE)

        if (this.state.page === totalPages &&
            totalPages !== 1 &&
            ids.length === currentPageOrganizers) {
            this.setState({ page: totalPages - 1 });
        }

        bootbox.confirm({
            title: '<i className="text-secondary"></i>Restore Organizer',
            message: "<p>Are you sure you want to restore the selected organizer(s)?</p>"
                + "<p class='please-note-msg'>Note: Any previously scheduled reminders will be turned on for restored organizer(s).</p>",
            buttons: {
                cancel: {
                    label: '<i data-test-auto="d491182f-ecd7-4eca-b687-c914b927d89a" class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i data-test-auto="35e85ddd-623f-49cb-9c09-d68f62fab6b2" class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader();
                    restoreArchivedOrganizers(ids, () => {
                        this.onPageReload();
                        HideLoader();
                    });
                }
            }
        });
    }

    onUpdateScheduleReminder = (options: any) => {
        this.closeReminderPopUp();
        if (this.state.isBulkSelectionEnabled) {
            let bulkAutoReminderRequest: BulkOperationAutoReminderRequest = {
                ...this.getBulkOperationFilter(),
                reminderSettings: options
            };

            this.updateBulkReminderActionCall(bulkAutoReminderRequest);
        }
        else {
            let selectedAutoReminderlist = this.getSelectedAutoremidnerInfo();
            let autoReminderViewModel = new AutomaticReminderViewModel(selectedAutoReminderlist, options);
            this.updateReminderActionCall(autoReminderViewModel);
        }
    }

    //-----------Client Management Start----------//
    onClientManagementComparisonFailed = () => {
        VenusNotifier.Warning(ClientManagementConstants.ClientManagementComparisonFailed, null);
        this.clientInfoSave(false);
        return;
    }

    setShowNewClientModal = (show: boolean) => {
        this.setState({
            showNewClientModal: show
        });
    }

    onCancelClientInfoSave = () => {
        this.setShowNewClientModal(false);
        this.clientInfoSave();
    }

    onCloseClientInfoSave = () => {
        this.setShowNewClientModal(false);
        this.clientInfoSave();
    }

    onCancelClientInfoUpdate = () => {
        this.setShowExistingClientDataComparisonModal(false);
        this.clientInfoSave();
    }

    onCloseClientInfoUpdate = () => {
        this.setShowExistingClientDataComparisonModal(false);
        this.clientInfoSave();
    }

    setShowExistingClientDataComparisonModal = (show: boolean) => {
        this.setState({
            showExistingClientDataComparisonModal: show
        });
    }

    private getClientInfoModel = (
        clients?: OrganizerClient[],
        clientId: string = "",
        resendEmail: boolean = false,
        isTpEmailupdated?: boolean,
        isSpouseEmailupdated?: boolean,
        isTpNameUpdated?: boolean,
        isSpouseNameUpdated?: boolean,
        isOfficeLocationUpdated?: boolean,
        documentId: number = 0): IClientInfoViewModel => {

        let taxpayer: OrganizerClient | undefined = initialOrganizerClient;
        let spouse: OrganizerClient | undefined = initialOrganizerClient;

        if (clients) {
            this.setState({
                clientInfoDetails: {
                    documentId: documentId > 0 ? documentId : this.state.clientInfoModal.documentId,
                    clients: clients,
                    clientId: clientId,
                    resendEmail: resendEmail,
                    isTpEmailupdated: isTpEmailupdated,
                    isSpouseEmailupdated: isSpouseEmailupdated,
                    isTpNameUpdated: isTpNameUpdated,
                    isSpouseNameUpdated: isSpouseNameUpdated,
                    isOfficeLocationUpdated: isOfficeLocationUpdated,
                    isMarriedFileJointly: spouse != undefined
                }
            });

            taxpayer = clients.find(client => client.clientType === OrganizerClientType.Taxpayer);
            spouse = clients.find(client => client.clientType === OrganizerClientType.Spouse);
        }
        else {
            taxpayer = this.state.clientInfoDetails.clients.find(client => client.clientType === OrganizerClientType.Taxpayer);
            spouse = this.state.clientInfoDetails.clients.find(client => client.clientType === OrganizerClientType.Spouse);
        }

        let clientInfo: IClientInfoViewModel = initialClientInfoViewModel;
        clientInfo.clientId = clientId != "" ? clientId : this.state.clientInfoDetails.clientId.trim();

        clientInfo.name = taxpayer?.name?.trim() ?? "";
        clientInfo.emailAddress = taxpayer?.email?.trim() ?? "";
        clientInfo.isDeceased = taxpayer?.isDeceased ?? false;
        clientInfo.countryCode = taxpayer?.countryCode ?? "";
        clientInfo.mobileNumber = taxpayer?.mobileNumber ?? "";
        clientInfo.locationId = taxpayer?.locationId;

        const locationData = this.props.companyData.companyProfile.locations;
        const locationName = clientInfo.locationId && locationData.find(location => location.locationId === clientInfo.locationId)?.name;
        const ero = this.props.partners?.partners?.find(partnerUser => partnerUser.id === taxpayer?.ero);

        clientInfo.locationName = locationName ? locationName.toString() : "";
        clientInfo.ero = taxpayer?.ero.toString();
        clientInfo.eroFirstName = (ero && ero?.firstName) ? ero?.firstName : "";
        clientInfo.eroLastName = (ero && ero?.lastName) ? ero?.lastName : "";

        clientInfo.spouseName = spouse?.name?.trim() ?? "";
        clientInfo.spouseEmailAddress = spouse?.email?.trim() ?? "";
        clientInfo.spouseMobileNumber = spouse?.mobileNumber?.trim() ?? "";
        clientInfo.spouseCountryCode = spouse?.countryCode ?? "";
        clientInfo.spouseIsDeceased = spouse?.isDeceased ?? false;
        clientInfo.mfj = spouse != undefined ? true : false;

        return clientInfo;
    }

    private setClientInfoComparisonResult = (clientInfoComparisonResult: IClientInfoComparisonResult) => {
        if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.NewClient) {
            createNewClientInfoComparisonResult(
                this.getClientInfoModel(),
                (response) => {
                    clientInfoComparisonResult.clientInfoResultData = response;
                });
        }
        this.setState({ clientInfoComparisonResult }, () => {
            if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.NoDataMismatch) {
                if (clientInfoComparisonResult.clientInfoResultData.clientId.taxReturnValue) {
                    this.clientInfoSave(false);
                }
            }
            else {
                HideLoader();
                if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.NewClient) {
                    this.setShowNewClientModal(true);
                }
                else if (clientInfoComparisonResult.comparisonStatus == ClientInfoMismatchType.DataMismatch) {
                    this.setShowExistingClientDataComparisonModal(true);
                }
            }
        });
    }

    onSaveClick = (
        clients: OrganizerClient[],
        clientId: string,
        resendEmail: boolean = false,
        isTpEmailupdated?: boolean,
        isSpouseEmailupdated?: boolean,
        isTpNameUpdated?: boolean,
        isSpouseNameUpdated?: boolean,
        isOfficeLocationUpdated?: boolean) => {
        ShowLoader();
        this.setState({
            clientInfoDetails: {
                documentId: this.state.clientInfoModal.documentId,
                clients,
                clientId,
                resendEmail,
                isTpEmailupdated,
                isSpouseEmailupdated,
                isTpNameUpdated,
                isSpouseNameUpdated,
                isOfficeLocationUpdated,
                isMarriedFileJointly: this.state.clientInfoModal.isMarriedFileJointly
            }
        },
            () => {
                this.onEditClientInfoClose();
                compareClientInfo(
                    this.getClientInfoModel(),
                    this.editClientInfoResourceId,
                    this.setClientInfoComparisonResult,
                    () => {
                        this.clientInfoSave();
                    },
                    this.onClientManagementComparisonFailed);
            });

    }

    onAddNewClientInfo = () => {
        addNewClientInfo(this.getClientInfoModel(), this.editClientInfoResourceId,
            () => {
                this.clientInfoSave();
                this.setState({
                    showNewClientModal: false,
                    showExistingClientDataComparisonModal: false
                });
            },
            this.onClientManagementSaveFailed
        );
    }

    onUpdateExistingClientInfo = () => {

        getClientInfo(
            this.state.clientInfoDetails.clientId,
            this.editClientInfoResourceId,
            (response) => {
                var clientInfoViewModel = this.getClientInfoModel();
                clientInfoViewModel.clientBasicInfoId = response.clientBasicInfoId;
                updateExistingClientInfo(clientInfoViewModel, this.editClientInfoResourceId,
                    () => {
                        this.clientInfoSave();
                        this.setState({
                            showNewClientModal: false,
                            showExistingClientDataComparisonModal: false
                        });
                    },
                    this.onClientManagementSaveFailed
                );
            },
            this.onClientManagementSaveFailed
        );

    }
    onClientManagementSaveFailed = () => {
        VenusNotifier.Warning(ClientManagementConstants.ClientManagementSaveFailed, null);
        this.setState({
            showNewClientModal: false,
            showExistingClientDataComparisonModal: false
        });
        this.clientInfoSave();
    }
    resetClientManagementDetails = () => {
        HideLoader();
        this.setState({
            clientInfoDetails: {
                documentId: 0,
                clients: [],
                clientId: '',
                resendEmail: false,
                isSpouseEmailupdated: false,
                isTpEmailupdated: false,
                isMarriedFileJointly: false,
                isTpNameUpdated: false,
                isSpouseNameUpdated: false,
                isOfficeLocationUpdated: false
            }
        });
    }

    private onCloseLocationMismatchActionModal = () => {
        this.setState({ loading: false, isShowLocationMismatchActionModel: false });
    };

    private onContinueLocationMismatchActionModal = (
        clients?: OrganizerClient[],
        clientId: string = "",
        resendEmail: boolean = false,
        isTpEmailupdated?: boolean,
        isSpouseEmailupdated?: boolean,
        isTpNameUpdated?: boolean,
        isSpouseNameUpdated?: boolean,
        isOfficeLocationUpdated?: boolean,
        documentId?: number) => {
        this.setState({ isShowLocationMismatchActionModel: false }, () => {
            compareClientInfo(
                this.getClientInfoModel(
                    clients?.filter(r => r.clientGuid != ''),
                    clientId,
                    resendEmail,
                    isTpEmailupdated,
                    isSpouseEmailupdated,
                    isTpNameUpdated,
                    isSpouseNameUpdated,
                    isOfficeLocationUpdated,
                    documentId), this.editClientInfoResourceId, this.setClientInfoComparisonResult,
                () => {
                    this.clientInfoSave();
                },
                this.onClientManagementComparisonFailed);
        });
    };

    getOfficeLocations = (locations: ILocations[]) => {

        let locationList: IDropdown[] = [];

        locations?.forEach((location: ILocations) => {
            const locationVal: IDropdown = {
                label: location.name,
                value: location.locationId
            }

            locationList.push(locationVal);
        })

        this.setState({ locationsState: locationList })
    }

    isEmailupdateRequired = (client: OrganizerClient, unChangedTpEmail: string, unChangedSpouseEmail: string) => {

        if (client.clientType == OrganizerClientType.Taxpayer && client.email) {
            return (client.email != unChangedTpEmail);
        }

        if (client.clientType == OrganizerClientType.Spouse && client.email) {
            return (client.email != unChangedSpouseEmail);
        }
    }

    onLocationChange = (locationId: number) => {
        this.state.clientInfoModal.model[0].locationId = locationId;
        this.setState({ clientInfoModal: this.state.clientInfoModal });
    }

    //-----------Client Management End----------//
    render() {

        const { showUpload, selectedRows, page, clientInfoModal, clientTrackingModal, downloadModal, resendAccessLinkModal, reopenOrganizerModal, closeOrganizerModal,
            saveFilterShow, filter, taxSoftware, uploadType, eroUsers, defaultERO, accessCodeModal, signerDetailsModal, deliveryStatusModal,
            sourceDocumentDownloadModal, reminderOptions, deselectedRows, organizerClients, isBulkSelectionEnabled,
            filterClientId, filterDeliveredOn, filterEngagementLetterStatus, filterOrganizerStatus, filterSourceDocumentStatus,
            filterTaxpayerName, filterTaxYear, filterEro, filterSentBy, filePreviewModal, filterArchivedBy, reopenSourceDocumentUploadModal, filterBatchName, filterOrganizerReminder,
            filterSigningReminder, selectedSendReminderActionRow,
            filterTaxpayerEmail, filterSpouseEmail
        } = this.state;

        const { companyData: { companyProfile: { countryStates, locations } },
            organizerModel: { loading, model, popupLoading },
            orgaizerFilter: { filters },
            getUploadLink,
            submitOrganizer,
            submitOrganizerWithSeperateEL,
            deleteUploadedDocument,
            downloadFilledOrganizer,
            downloadSignedEngagementLetter,
            deleteDocument, downloadSourceDocument,
            changeDeliveredOrganizerOptions, downloadCSVTemplate, downloadAll,
            companySettingsStore, getSourceDocumentMetadataAsync, downloadSourceDocuments,
            pageTitle, isArchived, resourceList, userLocations
        } = this.props;

        const defaultFilter = filters.find(x => x.isDefaultFilter);

        const defaultFilterName = defaultFilter?.name;

        return (
            <div className='organizer-delivered-content'>
                <OrganizerDeliveredReportToolbar
                    pageTitle={pageTitle}
                    onSendReminder={this.onSendReminderClick}
                    selectedDocumentCount={this.getSelectedRowCount()}
                    onUploadClick={this.onUploadReturnsOpen}
                    taxSoftwares={this.props.companyData.commonSettings?.taxSoftwareSetting ? this.props.companyData.commonSettings.taxSoftwareSetting.taxSoftware : []}
                    selectedUploadTypeId={uploadType}
                    onChangeUploadType={this.onChangeUploadType}
                    selectedRows={selectedRows}
                    documents={model.documents}
                    isBulkSelectionEnabled={isBulkSelectionEnabled}
                    filterClientId={filterClientId}
                    filterDeliveredOn={filterDeliveredOn}
                    filterEngagementLetterStatus={filterEngagementLetterStatus}
                    filterOrganizerStatus={filterOrganizerStatus}
                    filterSourceDocumentStatus={filterSourceDocumentStatus}
                    filterOrganizerReminder={filterOrganizerReminder}
                    filterSigningReminder={filterSigningReminder}
                    filterTaxpayerName={filterTaxpayerName}
                    filterTaxYear={filterTaxYear}
                    filterEro={filterEro}
                    filterSentBy={filterSentBy}
                    filterBatchName={filterBatchName}
                    filterTaxpayerEmail={filterTaxpayerEmail}
                    filterSpouseEmail={filterSpouseEmail}
                    deselectedRows={deselectedRows}
                    updateStatusToManual={this.updateStatusToManual}
                    isArchived={isArchived}
                    onArchive={this.onArchive}
                    onCloseOpenByFirm={this.onCloseOpenByFirm}
                    onDelete={() => this.onDeleteModalOpen(NO_INDEX)}
                    onRestore={this.onRestore}
                    filterArchivedBy={filterArchivedBy}
                    requestDeliveredOrganizers={this.requestDeliveredOrganizers}
                />

                <OrganizerDeliveredReportTable
                    ref={'organizerReportTable'}
                    filterType={this.ReportType}
                    documents={model.documents}
                    isLoading={loading}
                    pageReload={this.onPageReload}
                    onFilterChange={this.onFilterChange}
                    filterList={filters}
                    onPageChange={this.onPageChange}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    onSortChange={this.onSortChange}
                    pageNo={page}
                    pageSize={PAGESIZE}
                    saveFilterShow={saveFilterShow}
                    selectedRows={selectedRows}
                    totalRows={model.count}
                    onEditClientInfoOpen={this.onEditClientInfoOpen}
                    onReportProblemOpen={this.onReportProblemOpen}
                    onUnlockOrganizer={this.onUnlockOpen}
                    onClientViewLinkClick={this.onClientViewLinkClick}
                    onRequestOrganizerClients={this.onRequestOrganizerClients}
                    onCancelOrganizerClients={this.onCancelOrganizerClients}
                    organizerClients={organizerClients}
                    onResendAccessLinkModalOpen={this.onResendAccessLinkModalOpen}
                    onReopenOrganizerLinkModalOpen={this.onReopenOrganizerLinkModalOpen}
                    onCloseOrganizerLinkModalOpen={this.onCloseOrganizerLinkModalOpen}
                    onDownloadModalOpen={this.onDownloadModalOpen}
                    onClientTrackingModalOpen={this.onClientTrackingModalOpen}
                    onSendReminderActionClick={this.onSendReminderActionClick}
                    onDeleteModalOpen={this.onDeleteModalOpen}
                    currentFilter={filter}
                    defaultFilter={defaultFilterName}
                    onExportToExcel={this.onExportToExcel}
                    onFilterDelete={this.onFilterDelete}
                    onFilterNameChange={this.onFilterNameChange}
                    onFilterSave={this.onFilterSave}
                    onFilterUpdate={this.onFilterUpdate}
                    onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                    onSaveFilterHide={this.onSaveFilterHide}
                    onSaveFilterShow={this.onSaveFilterShow}
                    onSetDefaultFilter={this.onSetDefaultFilter}
                    onViewAccessCodeOpen={this.onViewAccessCodeOpen}
                    onUnlockOpen={this.onUnlockOpen}
                    onSignedDetailsOpen={this.onSignedDetailsOpen}
                    onDeliveryStatusOpen={this.onDeliveryStatusOpen}
                    changeOrganizerOptions={changeDeliveredOrganizerOptions}
                    onSourceDocumentsDownloadOpen={this.sourceDocumentDownloadModalOpen}
                    allowGatheringSourceDocuments={companySettingsStore ? companySettingsStore.companySettings.sourceDocumentSettings ? companySettingsStore.companySettings.sourceDocumentSettings.enabledSourceDocuments : false : false}
                    refreshDelay={this.state.refreshDelay}
                    onClickBulkSelection={this.onClickBulkSelection}
                    selectedRowCount={this.getSelectedRowCount()}
                    showBulkSelectionMessage={this.state.showBulkSelectionMessage}
                    onSourceDocumentsUploadOpen={this.onReopenSourceDocumentModalOpen}
                    isArchived={isArchived}
                    onRestore={this.onRestore}
                    columnSettings={this.state.columnSettings}
                    saveColumnSettings={this.props.saveColumnSettings}
                    resourcesList={resourceList}
                    selectedOrganizerOption={OrganizerPageOptions.Organizer}
                    userLocationList={this.getUserLocationData()}
                    onLinkClick={this.onLinkClick}

                />

                <OrganizerUploadModalSelector
                    show={showUpload}
                    getUploadLink={getUploadLink}
                    onHide={this.onUploadDocumentHide}
                    pageReload={this.onPageReload}
                    submitDocuments={submitOrganizer}
                    taxSoftware={taxSoftware}
                    deleteDocument={deleteUploadedDocument}
                    uploadType={uploadType}
                    deleteBatchDocument={deleteDocument}
                    defaultERO={defaultERO}
                    eroUsers={eroUsers}
                    downloadCSVTemplate={downloadCSVTemplate}
                    submitSingleDocuments={submitOrganizerWithSeperateEL}
                    enableEngagementLetter={companySettingsStore.companySettings.defaultSettings.enableEngagementLetter}
                    userLocations={[]}
                />

                <ClientInfoModal
                    clientId={clientInfoModal.clientId}
                    isDelivered={clientInfoModal.isDelivered}
                    model={clientInfoModal.model}
                    onClose={this.onEditClientInfoClose}
                    onSave={this.onSaveClick}
                    states={countryStates}
                    show={clientInfoModal.show}
                    loading={popupLoading}
                    status={clientInfoModal.status}
                    unDeliveredReport={false}
                    companyLocations={locations}
                    userLocations={userLocations}
                    onCloseLocationMismatchActionModal={this.onCloseLocationMismatchActionModal}
                    onContinueLocationMismatchActionModal={this.onContinueLocationMismatchActionModal}
                    onClientManagementComparisonFailed={this.onClientManagementComparisonFailed}
                    dcoumentId={clientInfoModal.documentId}
                    onLocationChange={this.onLocationChange}
                    showOfficeLocation={true}
                />

                <OrganizerClientTrackingModal
                    clientTracking={clientTrackingModal.model}
                    onClose={this.onClientTrackingModalClose}
                    show={clientTrackingModal.show}
                    loading={popupLoading}
                />

                <OrganizerDownloadModal
                    onClose={this.onDownloadModalClose}
                    show={downloadModal.show}
                    loading={popupLoading}
                    isArchived={isArchived}
                    model={downloadModal.model}
                    downloadFilledDocument={downloadFilledOrganizer}
                    downloadSignedEngagementLetter={downloadSignedEngagementLetter}
                    downloadUploadedFile={downloadSourceDocument}
                    refreshOnDownload={this.refreshOnDownload}
                    downloadAll={downloadAll}
                    downloadSourceDocuments={downloadSourceDocuments}
                    getSourceDocumentMetadataAsync={getSourceDocumentMetadataAsync}
                />

                <OrganizerResendAccessLinkModal
                    onClose={this.onResendAccessLinkModalClose}
                    show={resendAccessLinkModal.show}
                    loading={popupLoading}
                    model={resendAccessLinkModal.model}
                    resendAccessLink={this.resendAccessLink}
                    engagementLetterStatus={resendAccessLinkModal.engagementLetterStatus}
                />

                <ReopenOrganizerModal
                    onCancel={this.onReopenOrganizerLinkModalClose}
                    show={reopenOrganizerModal.show}
                    documentId={reopenOrganizerModal.documentId}
                    onReopenOrganizerModalConfirmClick={this.onReopenOrganizerModalConfirmClick}
                />

                <CloseOrganizerModal
                    onCancel={this.onCloseOrganizerLinkModalClose}
                    show={closeOrganizerModal.show}
                    processId={closeOrganizerModal.processId}
                    isClosed={closeOrganizerModal.isClosed}
                    onCloseOrganizerModalConfirmClick={this.onCloseOrganizerModalConfirmClick}
                />

                <ViewAccessCode
                    show={accessCodeModal.show}
                    model={accessCodeModal.model}
                    onCancel={this.onViewAccessCodeCancel}
                    onGenerateOTP={this.onGenerateOTP}
                    loading={popupLoading}
                    oneHubModel={accessCodeModal.oneHubModel}
                    onGenerateOneHubOTP={this.onGenerateOTPForOneHub}
                    isUTELinked={accessCodeModal.isUTELinked && this.state.isUteEnabled}
                />

                <SignedDetailsModal
                    model={signerDetailsModal.model}
                    onClose={this.onSignedDetailsClose}
                    show={signerDetailsModal.show}
                />

                <DeliveryStatusModal
                    model={deliveryStatusModal.model}
                    onClose={this.onDeliveryStatusClose}
                    show={deliveryStatusModal.show}
                />

                <SourceDocumentDownloadModal
                    model={sourceDocumentDownloadModal.model}
                    show={sourceDocumentDownloadModal.show}
                    onClose={this.sourceDocumentDownloadModalClose}
                />

                <FilePreviewModal
                    url={filePreviewModal.url}
                    fileName={filePreviewModal.fileName}
                    fileType={filePreviewModal.fileType}
                    onHidePDFPreview={this.onHidePDFPreview}
                    showPDFPreview={filePreviewModal.showPDFPreview}
                />

                <ReportProblem
                    onCancel={this.onReportProblemCancel}
                    onReportProblemSave={this.onReportProblemSave}
                    show={this.state.reportProblemModal.show}
                    loggedInCPA={this.props.userProfile}
                    companyName={this.props.companyData.companyProfile.companyInfo.companyName}
                    problemStep={ReportedStep.DeliveredOrganizer}
                    reportProblemType={ReportProblemTypes.ReturnSpecific}
                    organizerData={this.state.reportProblemModal.organizerData}
                    taxSoftwares={this.props.companyData.commonSettings?.taxSoftwareSetting ? this.props.companyData.commonSettings?.taxSoftwareSetting.taxSoftware : []}
                    disableTaxSoftware={true}
                />
                <UnlockModal
                    show={this.state.unlockModal.show}
                    organizer={this.state.unlockModal.organizer}
                    onUnlockOrganizer={this.onUnlockOrganizer}
                    onOneHubUnlock={this.onOneHubUnlock}
                    onCancel={()=>this.setState({unlockModal: {
                        ...this.state.unlockModal,
                        show: false
                    }})}/>
                {reminderOptions.showReminder &&
                    <ReminderOption
                        show={reminderOptions.showReminder}
                        onCancel={this.clearReminderOptions}
                        hideLoader={true}
                        onReminderSend={this.OnSendClickReminderPopup}
                        OnChangeSourceReminder={this.OnChangeSourceReminder}
                        OnChangeEngagementReminder={this.OnChangeEngagementReminder}
                        OnChangeOrganizerReminder={this.OnChangeOrganizerReminder}
                        isEngagementReminder={reminderOptions.isEngagementReminder}
                        isOrganizerReminder={reminderOptions.isOrganizerReminder}
                        isSourceDocumentReminder={reminderOptions.isSourceReminder}
                        disableEngagementReminder={reminderOptions.disableEngagementReminder}
                        disableOrganizerReminder={reminderOptions.disableOrganizerReminder}
                        disableSourceReminder={reminderOptions.disableSourceReminder}
                        documentList={this.props.organizerModel?.model.documents}
                        selectedRows={selectedSendReminderActionRow.length ? selectedSendReminderActionRow : selectedRows}
                        isBatchOrganizer={false}
                        selectedRowCount={selectedSendReminderActionRow.length ? selectedSendReminderActionRow.length : this.getSelectedRowCount()}
                        onUpdateScheduleReminder={this.onUpdateScheduleReminder}
                    />
                }
                <ReopenSourceDocumentModal
                    show={reopenSourceDocumentUploadModal.show}
                    onClose={this.onReopenSourceDocumentModalClose}
                    reopenSourceDocumentUpload={this.reopenSourceDocumentUpload}
                />

                {
                    this.state.showNewClientModal &&
                    <NewClientModal
                        onClose={this.onCloseClientInfoSave}
                        onCancel={this.onCancelClientInfoSave}
                        onAddNewClientInfo={this.onAddNewClientInfo}
                        isMarriedFileJointly={this.state.clientInfoDetails.isMarriedFileJointly}
                        clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                    />
                }
                {
                    this.state.showExistingClientDataComparisonModal &&
                    <ExistingClientDataComparisonModal
                        onClose={this.onCloseClientInfoUpdate}
                        onCancel={this.onCancelClientInfoUpdate}
                        onUpdate={this.onUpdateExistingClientInfo}
                        isMarriedFileJointly={this.state.clientInfoDetails.isMarriedFileJointly}
                        clientInfoComparisonResult={this.state.clientInfoComparisonResult}
                        partners={eroUsers}
                    />
                }
            </div>
        );
    }
}
