export interface MessageDetails {
    id: number;
    name: string;
    body: string;
    isAllowToEdit: boolean;
}
export interface IDefaultMessage {
    initialEmail: number;
    voucherReminders: number;
    downloadTaxDocuments: number;
    downloadSignedForms: number;
    k1Distribution: number;
    welcomeMessage: number;
    k1WelcomeMessage: number;
    downloadInstruction: number;
    paymentInstruction: number;
    savedMessage: number;
}

export const initialMessageDetails = {
    id: 0,
    name: "",
    body: "",
    isAllowToEdit: false
}

export const initialDefaultMessage = {
    initialEmail: 0,
    voucherReminders: 0,
    downloadTaxDocuments: 0,
    downloadSignedForms: 0,
    k1Distribution: 0,
    welcomeMessage: 0,
    k1WelcomeMessage: 0,
    downloadInstruction: 0,
    paymentInstruction: 0,
    savedMessage: 0
}