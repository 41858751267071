import { actionTypes } from './../../store/ActionTypes';
import {
    OrganizerDocument, OrganizerCompanySettings,
    OrganizerClient, OrganizerTransaction,
    OrganizerFilter, ProcessStatus, PreparerMessage, EmailMessage,
    AuthenticationOption, BlankOrganizerTemplateDocument,
    BlankOrganizerTemplateStatus, PagedBatchModelDictionary, PagedBatchOrganizerDocumentDictionary,
    BatchModel, OrganizerPageOptions, AdditionalQuestionModel, BatchError, BatchCSVData, DownloadableDocuments, AdditionalEsignModel, BulkOperationFilters, EngagementType, IColumnSettings, OrganizerFilterType, RecycledOrganizerDocument, ReminderSettings, BatchStatus} from './OrganizerComponentModels';
import { MessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { IAccessViewModel } from 'src/components/common/TaxReturn';
import { API_BASE_URL } from 'src/utils/constants';

export const apiPrefix = `${API_BASE_URL}api/Organizer/`;

export interface OrganizerUploadState {
    sas: string;
    guid: string;
}

export const unloadedOrganizerUploadState: OrganizerUploadState = {
    guid: '',
    sas: ''
}

export interface OrganizerTableModel {
    count: number;
    documents: OrganizerDocument[];
}
export interface RecycledTableModel {
    count: number;
    documents: RecycledOrganizerDocument[];
}


export interface DeliveredOrganizerStoreState {
    model: OrganizerTableModel;
    loading: boolean;
    popupLoading: boolean;
    sourceDocumentMetaData: any;
    query: string;
}
export interface RequestDeliveredOrganizerDocumentsAction {
    type: actionTypes.REQUEST_DELIVERED_ORGANIZER_DOCUMENTS;
    loading: boolean;
}

export interface ReceiveDeliveredOrganizerDocumentsAction {
    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_DOCUMENTS;
    model: OrganizerTableModel;
    loading: boolean;
    query: string;
}

export interface RequestRecycledOrganizerDocumentsAction {
    type: actionTypes.REQUEST_RECYCLED_ORGANIZER_DOCUMENTS;
    loading: boolean;
}

export interface ReceiveRecycledOrganizerDocumentsAction {
    type: actionTypes.RECEIVE_RECYCLED_ORGANIZER_DOCUMENTS;
    model: RecycledTableModel;
    loading: boolean;
    query: string;
}

export const unloadedDeliveredOrganizerStoreState: DeliveredOrganizerStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false,
    sourceDocumentMetaData: {},
    query: `?pageNo=1&pageSize=20&sortBy=deliveredOn&sortOrder=desc&filterClientId=&filterDeliveredOn=` +
        `&filterTaxpayerName=&filterTaxYear=&filterSentBy=&filterEro=&filterEngagementLetterStatus=` +
        `&filterOrganizerStatus=&filterSourceDocumentStatus=&filterBatchName=&isArchived=&filterArchivedBy=`
}

export interface DeliveredOrganizerFilterStoreState {
    filters: OrganizerFilter[];
    loading: boolean;
}

export const unloadedOrganizerFilterStoreState: DeliveredOrganizerFilterStoreState = {
    filters: [],
    loading: false
}

export interface RequestDeliveredOrganizerFilterAction {
    type: actionTypes.REQUEST_DELIVERED_ORGANIZER_FILTER;
    loading: boolean;
}

export interface ReceiveDeliveredOrganizerFilterAction {
    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_FILTER;
    filters: OrganizerFilter[];
    loading: boolean;
}

export interface SaveDeliveredOrganizerFilterAction {
    type: actionTypes.SAVE_DELIVERED_ORGANIZER_FILTER;
    filter: OrganizerFilter;
}

export interface UpdateDeliveredOrganizerFilterAction {
    type: actionTypes.UPDATE_DELIVERED_ORGANIZER_FILTER;
    filter: OrganizerFilter;
}

export interface DeleteDeliveredOrganizerFilterAction {
    type: actionTypes.DELETE_DELIVERED_ORGANIZER_FILTER;
    name: string;
}

export interface ReceiveOrganizerUploadLinkAction {
    type: actionTypes.RECEIVE_ORGANIZER_LINK;
    sasUrl: string;
    documentGuid: string;
}

export interface OrganizerSettingsStoreState {
    companySettings: OrganizerCompanySettings;
    loading: boolean;
    scheduleReminderSettings?: ReminderSettings;
}

export interface OrganizerReminderSettingsStoreState {
    reminderSettings: ReminderSettings;
    loading: boolean;
}

export const unloadedOrganizerSettingsStoreState: OrganizerSettingsStoreState = {
    companySettings: {
        defaultSettings: {
            savedMessage: 0,
            initialEmail: 0,
            engLetterReminderEmail: 0,
            organizerReminderEmail: 0,
            sourceDocReminderEmail: 0,
            downloadEngagementLetterEmail: 0,
            enableEngagementLetter: false
        },
        authenticationSettings: {
            authenticationOption: AuthenticationOption.None
        },
        sourceDocumentSettings: {
            enabledSourceDocuments: false,
            noAdditionalCPANotificationRequired: false,
            notifyEveryTimeUploadOrDeleteSrcDoc: false,
            schNotification1PerDayUploadOrDeleteSrcDoc: false
        },
        reminderSettings: {
            esignReminder: {
                enabled: false,
                reminderDays: 7,
                isReadOnly: false
            },
            organizerReminder: {
                enabled: false,
                reminderDays: 7,
                isReadOnly: false
            }
        },
        isTestCompany: true
    },
    loading: false,
}

export interface OrganizerCompanySettingsLoaderAction {
    type: actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER;
    loading: boolean;
}

export interface OrganizerReminderSettingsLoaderAction {
    type: actionTypes.ORGANIZER_REMINDER_SETTINGS_LOADER;
    loading: boolean;
}

export interface ReceiveOrganizerCompanySettings {
    type: actionTypes.RECEIVE_ORGANIZER_COMPANY_SETTINGS;
    data: OrganizerCompanySettings;
}

export interface ReceiveOrganizerScheduleReminderSettings {
    type: actionTypes.RECEIVE_SCHEDULE_ORGANIZER_REMINDER_SETTINGS;
    data: ReminderSettings;
}

export interface ReceiveOrganizerReminderSettings {
    type: actionTypes.RECEIVE_ORGANIZER_REMINDER_SETTINGS;
    data: ReminderSettings;
}

export interface UpdateOrganizerCompanySettings {
    type: actionTypes.UPDATE_ORGANIZER_COMPANY_SETTINGS;
    update: OrganizerCompanySettings;
}

export interface SendReminderOrganizerAction {
    type: actionTypes.SEND_REMINDER_ORGANIZER;
    ids: number[];
}

export interface DownloadFilledOrganizerAction {
    type: actionTypes.FILLED_ORGANIZER_DOWNLOAD;
    id: string;
}

export interface DownloadEngagementLetterAction {
    type: actionTypes.SIGNED_ENGAGEMENTLETTER_DOWNLOAD;
    id: string;
}

export interface DownloadSourceFileAction {
    type: actionTypes.SOURCE_FILE_DOWNLOAD;
    id: string;
}

export interface BatchDownloadFilledOrganizerAction {
    type: actionTypes.BATCH_FILLED_ORGANIZER_DOWNLOAD;
    id: string;
    batchGuid: string;
}

export interface BatchDownloadEngagementLetterAction {
    type: actionTypes.BATCH_SIGNED_ENGAGEMENTLETTER_DOWNLOAD;
    id: string;
    batchGuid: string;
}

export interface BatchDownloadSourceFileAction {
    type: actionTypes.BATCH_SOURCE_FILE_DOWNLOAD;
    id: string;
    batchGuid: string;
}

export interface UnlockOrganizerAction {
    type: actionTypes.UNLOCK_LOCKED_DOCUMENT;
    id: number;
}

export interface ResendAccessLinkOrganizerAction {
    type: actionTypes.RESEND_ACCESS_LINK_ORGANIZER;
    id: number;
}

export interface RequestOrganizerMessageListAction {
    type: actionTypes.REQUEST_ORGANIZER_SAVED_MESSAGES;
}

export interface ReceiveOrganizerMessageListAction {
    type: actionTypes.RECEIVE_ORGANIZER_SAVED_MESSAGES;
    messageDetails: MessageDetails;
    messageId: number;
}

export interface DeleteOrganizerMessageDetail {
    type: actionTypes.DELETE_ORGANIZER_SAVED_MESSAGE;
    messageDetail: MessageDetails;
}

export interface RequestOrganizerClient {
    type: actionTypes.REQUEST_ORGANIZER_DOCUMENT_CLIENTS;
    loading: boolean;
}

export interface ReceiveOrganizerClient {
    type: actionTypes.RECEIVE_ORGANIZER_DOCUMENT_CLIENTS;
    loading: boolean;
    clients: OrganizerClient[];
    id: number;
}

export interface BatchReceiveOrganizerClient {
    type: actionTypes.BATCH_RECEIVE_ORGANIZER_DOCUMENT_CLIENTS;
    clients: OrganizerClient[];
    id: number;
    batchGuid: string;
}

export interface OrganizeDelivered {
    type: actionTypes.ORGANIZER_DELIVER;
    id: number;
}

export interface OrganizerPopupLoaderShow {
    type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW;
    loading: boolean;
}

export interface OrganizerPopupLoaderClose {
    type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE;
    loading: boolean;
}

export interface BatchDeliveredOrganizerPopupLoader {
    type: actionTypes.BATCH_DELIVERED_ORGANIZER_POPUP_LOADER;
    loading: boolean;
}

export interface OrganizerClientsSaved {
    type: actionTypes.ORGANIZER_DOCUMENT_CLIENTS_SAVED;
    documentId: number;
    clientId: string;
    name: string;
    clients: OrganizerClient[];
}

export interface BatchOrganizerClientsSaved {
    type: actionTypes.BATCH_DELIVERED_ORGANIZER_DOCUMENT_CLIENTS_SAVED;
    documentId: number;
    clientId: string;
    name: string;
    clients: OrganizerClient[];
    batchGuid: string;
}

export interface ReceiveOrganizerClientTracking {
    type: actionTypes.RECEIVE_ORGANIZER_CLIENT_TRACKING;
    data: OrganizerTransaction[];
    id: number;
}

export interface BatchReceiveOrganizerClientTracking {
    type: actionTypes.BATCH_RECEIVE_ORGANIZER_CLIENT_TRACKING;
    data: OrganizerTransaction[];
    id: number;
    batchGuid: string;
}

export interface OrganizerStoreState {
    model: OrganizerTableModel;
    loading: boolean;
    popupLoading: boolean;
}
export const unloadedOrganizerStoreState: OrganizerStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false
}
export interface RequestOrganizerDocumentsAction {
    type: actionTypes.REQUEST_ORGANIZER_DOCUMENTS;
    loading: boolean;
}

export interface ReceiveOrganizerDocumentsAction {
    type: actionTypes.RECEIVE_ORGANIZER_DOCUMENTS;
    model: OrganizerTableModel;
    loading: boolean;
}

export interface RecieveOrganizerAction {
    type: actionTypes.RECEIVE_ORGANIZER;
    model: OrganizerDocument;
}

export interface ReceiveOrganizerPdfDocumentAction {
    type: actionTypes.RECEIVE_ORGANIZER_PDF_DOCUMENT;
    document: any;
    id: number;
}

export interface ReceiveAdditionalEsignPdfAction {
    type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_PDF;
    additionalEsignDocuments: AdditionalEsignModel[];
    id: string;
}

export interface ReceiveBatchAdditionalEsignPdfAction {
    type: actionTypes.RECEIVE_BATCH_ADDITIONAL_ESIGN_PDF;
    additionalEsignDocuments: AdditionalEsignModel[];
    id: string;
}

export interface ReceiveAdditionalEsignDocuments {
    type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENTS;
    additionalEsignDocuments: AdditionalEsignModel[];
    id: string;
}

export interface UpdateOrganizerDocumentStatus {
    type: actionTypes.UPDATE_ORGANIZER_DOCUMENTSTATUS;
    id: number;
    processStatus: ProcessStatus;
}

export interface UpdateOrganizer {
    type: actionTypes.UPDATE_ORGANIZER;
    id: number;
    document: OrganizerDocument;
}

export interface OrganizerEmailMessageStoreState {
    emailMessages: EmailMessage[];
    loading: boolean;
}

export const unloadedOrganizerEmailMessageStore: OrganizerEmailMessageStoreState = {
    emailMessages: [],
    loading: false
}

export interface OrganizerEmailMessageLoaderAction {
    type: actionTypes.ORGANIZER_EMAIL_MESSAGE_LOADER;
    loading: boolean;
}

export interface ReceiveOrganizerEmailMessageAction {
    type: actionTypes.RECEIVE_ORGANIZER_EMAIL_MESSAGES;
    data: EmailMessage[];
    loading: boolean;
}

export interface DeleteOrganizerEmailMessageAction {
    type: actionTypes.DELETE_ORGANIZER_EMAIL_MESSAGE;
    delete: EmailMessage;
}

export interface UpdateOrganizerEmailMessageAction {
    type: actionTypes.UPDATE_ORGANIZER_EMAIL_MESSAGE;
    update: EmailMessage;
}

export interface SaveOrganizerEmailMessageAction {
    type: actionTypes.SAVE_ORGANIZER_EMAIL_MESSAGE;
    save: EmailMessage;
}

export interface OrganizerPrepareMessageStoreState {
    preparerMessages: PreparerMessage[];
    loading: boolean;
}

export const unloadedOrganizerPreparerMessageStore: OrganizerPrepareMessageStoreState = {
    preparerMessages: [],
    loading: false
}

export interface OrganizerPreparerMessageLoader {
    type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER;
    loading: boolean;
}

export interface ReceiveOrganizerPreparerMessageAction {
    type: actionTypes.RECEIVE_ORGANIZER_PREPARER_MESSAGES;
    data: PreparerMessage[];
    loading: boolean;
}

export interface DeleteOrganizerPreparerMessageAction {
    type: actionTypes.DELETE_ORGANIZER_PREPARER_MESSAGE;
    delete: PreparerMessage;
}

export interface UpdateOrganizerPreparerMessageAction {
    type: actionTypes.UPDATE_ORGANIZER_PREPARER_MESSAGE;
    update: PreparerMessage;
}

export interface SaveOrganizerPreparerMessageAction {
    type: actionTypes.SAVE_ORGANIZER_PREPARER_MESSAGE;
    save: PreparerMessage;
}


export interface OrganizerBlankTemplateStoreState {
    templates: BlankOrganizerTemplateDocument[];
    loading: boolean;
}

export const unloadedOrganizerBlankTemplateStore: OrganizerBlankTemplateStoreState = {
    templates: [],
    loading: false
}

export interface OrganizerBlankTemplateLoaderAction {
    type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER;
    loading: boolean;
}

export interface ReceiveOrganizerBlankTemplatesAction {
    type: actionTypes.RECEIVE_ORGANIZER_BLANK_TEMPLATES;
    data: BlankOrganizerTemplateDocument[];
}

export interface ReceiveOrganizerBlankTemplateAction {
    type: actionTypes.RECEIVE_ORGANIZER_BLANK_TEMPLATE;
    data: BlankOrganizerTemplateDocument;
}

export interface DeleteOrganizerBlankTemplateAction {
    type: actionTypes.DELETE_ORGANIZER_BLANK_TEMPLATE;
    id: number;
}

export interface UpdateOrganizerBlankTemplateAction {
    type: actionTypes.SAVE_ORGANIZER_BLANK_TEMPLATE;
    update: BlankOrganizerTemplateDocument;
}

export interface UpdateOrganizerBlankTemplateStatusAction {
    type: actionTypes.UPDATE_BLANK_ORGANIZER_TEMPLATE_STATUS;
    id: number;
    status: BlankOrganizerTemplateStatus;
}

export interface ReceiveOrganizerBlankTemplatePdfDocumentAction {
    type: actionTypes.RECEIVE_BLANK_ORGANIZER_TEMPLATE_PDF_DOCUMENT;
    document: any;
    id: number;
}

export interface BatchTableModel {
    batches: BatchModel[];
    count: number;
}

export interface PagedBatchStoreState {
    query: string;
    batches: PagedBatchModelDictionary;
}

export const initialPagedBatchStoreState: PagedBatchStoreState = {
    query: "",
    batches: {}
}

export interface PagedBatchOrganizerStoreState {
    query: string;
    organizers: PagedBatchOrganizerDocumentDictionary;
}

export const initialPagedBatchOrganizerStoreState: PagedBatchOrganizerStoreState = {
    query: "",
    organizers: {}
}

export interface BatchStoreState {
    batchModel: BatchTableModel;
    loading: boolean;
    organizerTableLoading: boolean;
    popupLoader: boolean;
    csvLoader: boolean;
}

export const initialBatchStoreState: BatchStoreState = {
    batchModel: {
        batches: [],
        count: 0
    },
    loading: false,
    organizerTableLoading: false,
    popupLoader: false,
    csvLoader: false
};
export interface BatchBulkOperationFilters {
    batchName: string;
    deliveredOn?: Date;
    engagementType: string;
    taxYear: string;
    documentStatus: string;
    unSelectedIds: string[];
}
export interface BatchBulkOperationReminderRequest extends BatchBulkOperationFilters {
    isEngagementReminder: boolean;
    isOrganizerReminder: boolean;
    isSourceDocumentReminder: boolean;
}
export interface BatchPageLoaderAction {
    type: actionTypes.BATCH_PAGE_LOADER;
    loading: boolean;
}

export interface BatchCsvLoaderAction {
    type: actionTypes.BATCH_CSV_LOADER;
    loading: boolean;
}

export interface DeliveredBatchPageLoaderAction {
    type: actionTypes.DELIVERED_BATCH_PAGE_LOADER;
    loading: boolean;
}

export interface UndeliveredBatchPageLoaderAction {
    type: actionTypes.UNDELIVERED_BATCH_PAGE_LOADER;
    loading: boolean;
}

export interface UndeliveredBatchPopupLoaderAction {
    type: actionTypes.UNDELIVERED_BATCH_POPUP_LOADER;
    loading: boolean;
}

export interface BatchPopupLoaderAction {
    type: actionTypes.BATCH_POPUP_LOADER;
    loading: boolean;
}

export interface BatchOrganizerPageLoaderAction {
    type: actionTypes.BATCH_ORGANIZER_PAGE_LOADER;
    loading: boolean;
}

export interface BatchDeliveredOrganizerPageLoaderAction {
    type: actionTypes.BATCH_DELIVERED_ORGANIZER_PAGE_LOADER;
    loading: boolean;
}

export interface RecieveBatchAction {
    type: actionTypes.RECEIVE_ORGANIZER_BATCH;
    batch: BatchModel;
}

export interface DeliverBatchAction {
    type: actionTypes.DELIVER_BATCH;
    batch: BatchModel;
}

export interface RecieveOrganizerBatchAction {
    type: actionTypes.RECEIVE_ORGANIZER_BATCHES;
    batchModel: BatchTableModel;
}

export interface RecieveDeliveredOrganizerBatchAction {
    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_BATCHES;
    batchModel: BatchTableModel;
}

export interface RecieveUndeliveredOrganizerBatchAction {
    type: actionTypes.RECEIVE_UNDELIVERED_ORGANIZER_BATCHES;
    batchModel: BatchTableModel;
}

export interface RecievePagedOrganizerBatchAction {
    type: actionTypes.RECEIVE_PAGED_ORGANIZER_BATCHES;
    batchModel: BatchTableModel;
    query: string;
}

export interface ClearPagedBatchAction {
    type: actionTypes.CLEAR_PAGED_BATCHES;
}

export interface RecieveBatchOrganizersAction {
    type: actionTypes.RECEIVE_BATCH_ORGANIZERS;
    organizerModel: OrganizerTableModel;
    batchGuid: string;
}

export interface UpdateBatchInfoAction {
    type: actionTypes.UPDATE_BATCH_INFO;
    batchName: string;
    batchGuid: string;
}

export interface UpdateBatchStatus {
    type: actionTypes.UPDATE_BATCH_STATUS;
    batchStatus: BatchStatus;
    batchId: string;
}

export interface UpdateDeliveredBatchInfoAction {
    type: actionTypes.UPDATE_DELIVERED_BATCH_INFO;
    batchName: string;
    batchGuid: string;
}

export interface RecieveBatchDeliveredOrganizersAction {
    type: actionTypes.RECEIVE_BATCH_DELIVERED_ORGANIZERS;
    organizerModel: OrganizerTableModel;
    batchGuid: string;
}

export interface RecieveBatchOrganizerAction {
    type: actionTypes.RECEIVE_BATCH_ORGANIZER;
    batchGuid: string;
    organizer: OrganizerDocument;
}

export interface RecieveBatchOrganizerDocumentClientAction {
    type: actionTypes.RECEIVE_BATCH_ORGANIZER_DOCUMENT_CLIENTS;
    batchGuid: string;
    id: number;
    clients: OrganizerClient[];
}

export interface RecieveBatchOrganizerErrorAction {
    type: actionTypes.RECEIVE_ORGANIZER_BATCH_ERROR;
    batchGuid: string;
    page: number;
    errors: BatchCSVData[];
}

export interface UpdateBatchOrganizerErrorAction {
    type: actionTypes.UPDATE_ORGANIZER_BATCH_ERROR;
    batchGuid: string;
    csvWithErrorData: BatchCSVData[];
}

export interface RecievePagedBatchOrganizerAction {
    type: actionTypes.RECEIVE_PAGED_BATCH_ORGANIZERS;
    organizers: OrganizerTableModel;
    query: string;
}

export interface ClearPagedBatchOrganizerAction {
    type: actionTypes.CLEAR_PAGED_BATCH_ORGANIZERS;
}

export interface OrganizerPageSelectorStoreState {
    selectedDeliveredOrganizerOption: OrganizerPageOptions;
    selectedOrganizerOption: OrganizerPageOptions;
}

export const initialOrganizerPageSelectorStoreState: OrganizerPageSelectorStoreState = {
    selectedDeliveredOrganizerOption: OrganizerPageOptions.Organizer,
    selectedOrganizerOption: OrganizerPageOptions.Organizer
}

export interface ChangeOrganizerPageOptionsAction {
    type: actionTypes.CHANGE_ORGANIZER_PAGE_OPTIONS;
    selectedOrganizerOption: OrganizerPageOptions;
}

export interface ChangeDeliveredOrganizerPageOptionsAction {
    type: actionTypes.CHANGE_DELIVERED_ORGANIZER_PAGE_OPTIONS;
    selectedDeliveredOrganizerOption: OrganizerPageOptions;
}


export interface ReceiveBatchOrganizerPdfDocumentAction {
    type: actionTypes.RECEIVE_BATCH_ORGANIZER_PDF;
    document: any;
    id: number;
    batchGuid: string;
}

export interface ReceiveBatchPdfAction {
    type: actionTypes.RECEIVE_BATCH_PDF;
    document: any;
    batchGuid: string;
}

export interface AdditionalQuestionStoreState {
    questions: AdditionalQuestionModel[];
    loading: boolean;
}

export const unloadedAdditionalQuestionModelStoreState: AdditionalQuestionStoreState = {
    loading: false,
    questions: []
}

export interface AdditionalQuestionLoaderAction {
    type: actionTypes.ADDITIONAL_QUESTIONS_LOADER;
    loading: boolean;
}

export interface ReceiveAdditionalQuestionsAction {
    type: actionTypes.RECEIVE_ADDITIONAL_QUESTIONS;
    questions: AdditionalQuestionModel[];
}

export interface ReceiveAdditionalQuestionAction {
    type: actionTypes.RECEIVE_ADDITIONAL_QUESTION;
    question: AdditionalQuestionModel;
}

export interface SaveAdditionalQuestionAction {
    type: actionTypes.SAVE_ADDITIONAL_QUESTIONS;
    question: AdditionalQuestionModel;
}
export interface RequestInprocessOrganizerClient {
    type: actionTypes.REQUEST_INPROCESS_ORGANIZER_DOCUMENT_CLIENTS;
    loading: boolean;
}

export interface ReceiveInprocessOrganizerClient {
    type: actionTypes.RECEIVE_INPROCESS_ORGANIZER_DOCUMENT_CLIENTS;
    loading: boolean;
    clients: OrganizerClient[];
    id: number;
}



export interface InprocessOrganizerPopupLoaderAction {
    type: actionTypes.INPROCESS_ORGANIZER_POPUP_LOADER;
    loading: boolean;
}

export interface InprocessOrganizerClientsSaved {
    type: actionTypes.INPROCESS_ORGANIZER_DOCUMENT_CLIENTS_SAVED;
    documentId: number;
    clientId: string;
    name: string;
    clients: OrganizerClient[]
}

export interface InprocessOrganizerFilterStoreState {
    filters: OrganizerFilter[];
    loading: boolean;
}

export const unloadedInprocessOrganizerFilterStoreState: InprocessOrganizerFilterStoreState = {
    filters: [],
    loading: false
}

export interface RequestInprocessOrganizerFilterAction {
    type: actionTypes.REQUEST_INPROCESS_ORGANIZER_FILTER;
    loading: boolean;
}

export interface ReceiveInprocessOrganizerFilterAction {
    type: actionTypes.RECEIVE_INPROCESS_ORGANIZER_FILTER;
    filters: OrganizerFilter[];
    loading: boolean;
}

export interface SaveInprocessOrganizerFilterAction {
    type: actionTypes.SAVE_INPROCESS_ORGANIZER_FILTER;
    filter: OrganizerFilter;
}

export interface UpdateInprocessOrganizerFilterAction {
    type: actionTypes.UPDATE_INPROCESS_ORGANIZER_FILTER;
    filter: OrganizerFilter;
}

export interface DeleteInprocessOrganizerFilterAction {
    type: actionTypes.DELETE_INPROCESS_ORGANIZER_FILTER;
    name: string;
}

export interface ReceiveDownloadableDocumentsAction {
    type: actionTypes.RECEIVE_ORGANIZER_DOWNLOADABLE_DOCUMENTS;
    id: number;
    documents: DownloadableDocuments[];
}

export interface ReceiveBatchDownloadableDocumentsAction {
    type: actionTypes.RECEIVE_BATCH_ORGANIZER_DOWNLOADABLE_DOCUMENTS;
    id: number;
    documents: DownloadableDocuments[];
    batchGuid: string;
}

export interface ReceiveSourceFilesAction {
    type: actionTypes.RECEIVE_SOURCE_FILES;
    id: number;
    files: string[];
}

export interface BatchReceiveSourceFilesAction {
    type: actionTypes.BATCH_RECEIVE_SOURCE_FILES;
    id: number;
    files: string[];
    batchGuid: string;
}

export interface RequestOrganizerAccessCodeAction {
    type: actionTypes.REQUEST_ORGANIZER_ACCESSCODE;
    loading: boolean;
}

export interface ReceiveOrganizerAccessCodeAction {
    type: actionTypes.RECIEVE_ORGANIZER_ACCESSCODE;
    id: number;
    accessCodeViewModel: IAccessViewModel;
    loading: boolean;
}

export interface BatchReceiveOrganizerAccessCodeAction {
    type: actionTypes.BATCH_RECIEVE_ORGANIZER_ACCESSCODE;
    id: number;
    accessCode: IAccessViewModel;
    batchGuid: string;
}

export interface GenerateOTPAction {
    type: actionTypes.GENERATE_ORGANIZER_ACCESSCODE;
    id: number;
}

export interface OrganizerBatchExcelDownloadStoreState {
    loading: boolean;
}

export interface OrganizerBatchExcelDownloadLoaderAction {
    type: actionTypes.ORGANIZER_BATCH_EXCEL_DOWNLOAD_LOADER;
    loading: boolean;
}

export const initialOrganizerBatchExcelDownloadStoreState:
    OrganizerBatchExcelDownloadStoreState = {
    loading: false
}

export interface UpdateOrganizerBatchDownloadDetailsAction {
    type: actionTypes.UPDATE_ORGANIZER_BATCH_DOWNLOAD_DETAILS;
    id: string;
    batchGuid: string;
    userName: string;
}
export interface UpdateEngagementBatchDownloadDetailsAction {
    type: actionTypes.UPDATE_ENGAGEMENT_BATCH_DOWNLOAD_DETAILS
    id: string;
    batchGuid: string;
    userName: string;
}
export interface UpdateSourceBatchDownloadDetailsAction {
    type: actionTypes.UPDATE_SOURCE_BATCH_DOWNLOAD_DETAILS
    id: number;
    batchGuid: string;
    fileName: string;
    userName: string;
}

export interface UpdateOrganizerDownloadDetailsAction {
    type: actionTypes.UPDATE_ORGANIZER_DOWNLOAD_DETAILS
    id: string;
    userName: string;
}
export interface UpdateEngagementDownloadDetailsAction {
    type: actionTypes.UPDATE_ENGAGEMENT_DOWNLOAD_DETAILS
    id: string;
    userName: string;
}
export interface UpdateSourceDownloadDetailsAction {
    type: actionTypes.UPDATE_SOURCE_DOWNLOAD_DETAILS
    id: number;
    fileName: string;
    userName: string;
}

export interface DownloadOrganizerAsync {
    type:actionTypes.DOWNLOAD_ORGANIZER_ASYNC
    query:BulkOperationFilters,
    documentIds:number[]
}

export interface SetDownloadOrganizerAsync {
    type:actionTypes.SET_DOWNLOAD_ORGANIZER_ASYNC
    downloadOrganizerAsyncResponse:any
}

export interface SetDownloadOrganizerAsyncLoading { 
    type:actionTypes.SET_DOWNLOAD_ORGANIZER_ASYNC_LOADING,
    loading:boolean
}

export interface DownloadBulkOrganizerAsync {
    type:actionTypes.DOWNLOAD_BULK_ORGANIZER_ASYNC
    query:BatchBulkOperationFilters
}

export interface SetDownloadBulkOrganizerAsync {
    type:actionTypes.SET_DOWNLOAD_BULK_ORGANIZER_ASYNC,
    bulkOrganizerAsyncResponse:any
}

export interface SetDownloadBulkOrganizerAsyncLoading {
    type:actionTypes.SET_DOWNLOAD_BULK_ORGANIZER_ASYNC_LOADING
    loading:boolean
}

export interface DownloadOrganizerStoreState {
    downloadOrganizerAsync: {
        loading:boolean;
        data:any;
    },
    downloadBulkOrganizerAsync: {
        loading:boolean;
        data:any;
    }
}

export const downloadOrganizerStoreState:DownloadOrganizerStoreState = {
    downloadOrganizerAsync: {
        loading:false,
        data:{}
    },
    downloadBulkOrganizerAsync: {
        loading:false,
        data:{}
    }
}

export interface downloadQueryFilters {
    clientId: string;
    deliveredOn?: Date;
    organizerReminder?: Date;
    signingReminder?: Date;
    engLetterStatus: string;
    organizerStatus:string;
    sourceDocStatus:string;
    taxPayerName:string;
    taxYear: string;
    ero:string
    sentBy: string;
    batchName: string;
    deselectedIds: (number | undefined)[];
    archivedBy: string;
    isArchived: boolean;
    taxpayerEmail: string;
    spouseEmail: string;
}

export interface downloadBatchQueryFilters {
    batchName:string,
    engagementType:EngagementType,
    documentStatus:string,
    deliveredOn:Date | undefined,
    taxYear:string,
    unselectedIds:(string | undefined)[];
}

export interface RecycledOrganizerStoreState {
    model: RecycledTableModel;
    loading: boolean;
    popupLoading: boolean;
    query: string;
}
export const unloadedRecycledOrganizerStoreState: RecycledOrganizerStoreState = {
    loading: false,
    model: {
        count: 0,
        documents: []
    },
    popupLoading: false,
    query: ''
}

export interface SetDownloadBatchOrganizerAsync {
    type:actionTypes.SET_DOWNLOAD_BATCH_ORGANIZER_ASYNC,
    downloadBatchOrganizerAsyncResponse:any
}

export interface SetDownloadBatchOrganzierAsyncLoading {
    type:actionTypes.SET_DOWNLOAD_BATCH_ORGANIZER_ASYNC_LOADING,
    loading:boolean
}

export interface SetDownloadBulkBatchOrganizerAsync {
    type:actionTypes.SET_DOWNLOAD_BULK_BATCH_ORGANIZER_ASYNC,
    downloadBulkBatchOrganizerAsyncResponse:any
}

export interface SetDownloadBulkBatchOrganzierAsyncLoading {
    type:actionTypes.SET_DOWNLOAD_BULK_BATCH_ORGANIZER_ASYNC_LOADING,
    loading:boolean
}

export interface SetSourceDocumentMetaData {
    type:actionTypes.SET_SOURCE_DOCUMENT_META_DATA,
    sourceDocumentMetaData:any
}

export interface IColumnSettingsState {
    deliveredOrganizersColumnSettings: IColumnSettings[];
}

export const ColumnSettingsInitialState: IColumnSettingsState = {
    deliveredOrganizersColumnSettings: []
}

export interface RecieveColumnSettingsAction {
    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_COLUMN_SETTINGS;
    data: IColumnSettings[];
    reportType: OrganizerFilterType;
}

export interface UpdateAutoReminderOrganizerAction {
    type: actionTypes.UPDATE_AUTOMATIC_REMINDER;
}

export interface UpdateBulkAutoReminderOrganizerAction {
    type: actionTypes.UPDATE_BULK_AUTOMATIC_REMINDER;
}

export interface UpdateGlobalAutoReminderOrganizerAction {
    type: actionTypes.UPDATE_GLOBAL_AUTOMATIC_REMINDER;
}
