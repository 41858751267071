import { IUserProfile } from "../navigation/profile/ProfileObjects";
import { IUserBaseModel, IUserModel } from '../../Core/ViewModels/User/UserViewModel';
import { IFilters } from '../../components/reports/Filters'
import {
    ITaxReturn, DocumentStatus, IFormBase, isPartnership, isIndividual, IK1, IMarriedJointTaxReturn,
    isMutual, DocumentGroups, IGroup, ITaxingAuthority, ISignatureControl, IEFile, EngagementType, SignatureStatus, ClientTypes,
    getAllStateImages, StateUSA, ClientTypesNumber
} from '../common/TaxReturn';
import { VenusNotifier } from './VenusNotifier';
import { isValidEmailAddress, isValidSSN, isValidEIN, NullandEmptyCheck } from './Validations';
import { Moment } from 'moment';
import * as Constants from '../helper/Constants';
import { ICountryState, IPrimaryAdmin } from '../../Core/ViewModels/Company/CompanyViewModel';
import { TYPES } from '../../Startup/types';
import { container } from '../../Startup/inversify.config';
import { ISessionLocalStore } from '../../Core/Utilities/SessionStore';
import { Control, ControlType, DataType, Question, QuestionControl, QuestionType, RadioButton, SectionControl, SubQuestion, SubQuestionMapping, Textbox } from "../../Organizer/models/OrganizerComponentModels";
import { Guid } from "../../Core/Utilities/Guid";
import { RadioButtonProperties, TextBoxProperties } from "../../Organizer/components/Helper/PdfHelper";
import ControlBase from "awesome-pdf-viewer/dist/Controls/ControlBase";
import { API_BASE_URL, SSSUITE_API_BASE_URL } from "src/utils/constants";
import { IFormData, ISection, ISections } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { createCookie, getDomain } from "@sssuite-component-ui/session-timeout";

export const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

let moment = require('moment');
export const NO_INDEX = -1;
export const apiPrefix = `${API_BASE_URL}api/`;
export const sssuiteApiPrefix = `${SSSUITE_API_BASE_URL}api`;

const REGEX_VALUES = [
    { Key: '0', Value: /\d/ }
];
const MASK_PLACEHOLDER = '_';

export function iconType(fileName: any) {
    var fileImgIcon = { fileImg: "", fileIcon: "" };
    var fileImg = "";
    if (fileName.indexOf(".pdf") > -1 || fileName.indexOf(".PDF") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-pdf';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".jpg") > -1 || fileName.indexOf(".JPG") > -1) {
        fileImgIcon.fileImg += 'fas fa-file-image';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".png") > -1 || fileName.indexOf(".PNG") > -1) {
        fileImgIcon.fileImg += 'far fa-file-image';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".xlsx") > -1 || fileName.indexOf(".XLSX") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-excel';
        fileImgIcon.fileIcon = 'text-center text-success';
    }
    else if (fileName.indexOf(".docx") > -1 || fileName.indexOf(".DOCX") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-word';
        fileImgIcon.fileIcon = 'text-center text-primary';
    }
    else if (fileName.indexOf(".html") > -1 || fileName.indexOf(".HTML") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-code';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".txt") > -1 || fileName.indexOf(".TXT") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-alt btn-dark';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".csv") > -1 || fileName.indexOf(".CSV") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-csv';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".xml") > -1 || fileName.indexOf(".XML") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-code';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".tif") > -1 || fileName.indexOf(".TIF") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-image';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".xls") > -1 || fileName.indexOf(".XLS") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-excel';
        fileImgIcon.fileIcon = 'text-center text-success';
    }
    else if (fileName.indexOf(".jpeg") > -1 || fileName.indexOf(".JPEG") > -1) {
        fileImgIcon.fileImg += 'far fa-file-image';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".zip") > -1 || fileName.indexOf(".ZIP") > -1) {
        fileImgIcon.fileImg += 'fas fa-file-archive';
        fileImgIcon.fileIcon = 'text-center text-warning';
    }
    else if (fileName.indexOf(".exe") > -1 || fileName.indexOf(".EXE") > -1) {
        fileImgIcon.fileImg += 'fas fa-file';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".rtf") > -1 || fileName.indexOf(".RTF") > -1) {
        fileImgIcon.fileImg += 'fas fa-file-word';
        fileImgIcon.fileIcon = 'text-center text-primary';
    }
    else if (fileName.indexOf(".mp3") > -1 || fileName.indexOf(".MP3") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-audio';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".mp4") > -1 || fileName.indexOf(".MP4") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-video';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".log") > -1 || fileName.indexOf(".LOG") > -1) {
        fileImgIcon.fileImg = 'fas fa-file';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".doc") > -1 || fileName.indexOf(".DOC") > -1) {
        fileImgIcon.fileImg = 'far fa-file-word';
        fileImgIcon.fileIcon = 'text-center text-primary';
    }
    else if (fileName.indexOf(".tiff") > -1 || fileName.indexOf(".TIFF") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-image';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".gif") > -1 || fileName.indexOf(".GIF") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-image';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }
    else if (fileName.indexOf(".giff") > -1 || fileName.indexOf(".GIFF") > -1) {
        fileImgIcon.fileImg = 'fas fa-file-image';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }

    else {
        fileImgIcon.fileImg = 'fas fa-file';
        fileImgIcon.fileIcon = 'text-center text-danger';
    }

    return fileImgIcon;
}


export function getFileSize(fileSizeInBytes: number) {
    let file = parseInt((fileSizeInBytes / 1024).toString(), 10);
    let fileSize = "";
    fileSize = file < 1024 ? (file + " KB").toString() : (parseInt((file / 1024).toString(), 10) + " MB").toString()
    return fileSize;
}

export function getFileSizeFloatingPoint(fileSizeInBytes: number) {
    let file = Math.ceil(parseFloat((fileSizeInBytes / 1024).toString()));
    let fileSize = "";
    fileSize = file < 1024 ? (file + " KB").toString() : (Math.ceil(parseFloat((file / 1024).toString())) + " MB").toString()
    return fileSize;
}

export function phoneNumberDisplay(phoneNumber: string): string {
    if (!phoneNumber)
        return phoneNumber;
    return phoneNumber && phoneNumber.length == 10 ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10) : phoneNumber;
}

export function formatSSN(SSN: string): string {
    return isValidSSN(SSN) ? SSN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3") : SSN;
}

export function formatEIN(EIN: string): string {
    return isValidEIN(EIN) ? EIN.replace(/(\d{2})(\d{7})/, "$1-$2") : EIN;
}
export function getFileSizeBytes(fileSizeInUnit: string) {
    let fileSize = 0;
    if (fileSizeInUnit.indexOf("KB")) {
        fileSizeInUnit = fileSizeInUnit.replace("KB", "")
        fileSize = parseInt(fileSizeInUnit) * 1024;
    }
    else if (fileSizeInUnit.indexOf("MB")) {
        fileSizeInUnit = fileSizeInUnit.replace("MB", "")
        fileSize = parseInt(fileSizeInUnit) * 1024 * 1024;
    }
    else if (fileSizeInUnit.indexOf("GB")) {
        fileSizeInUnit = fileSizeInUnit.replace("GB", "")
        fileSize = parseInt(fileSizeInUnit) * 1024 * 1024 * 1024;
    }
    return fileSize;
}


export function toISO8601Format(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
}


export function myAccountFetch(): Promise<IUserProfile> {
    return fetch(`${sssuiteApiPrefix}/user-management/user-details`, {
        method: 'GET',
        credentials: 'include'
    })
        .then(response => response.json() as Promise<IUserProfile>)
        .then(data => {
            return data;
        });
}

export function GetFormatedDateTime(date: Date) {
    return moment.utc(date).local().format('MM/DD/YYYY hh:mm:ss A');
}


export function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export function getCurrentDate(): string {
    return moment(new Date()).format('MM/DD/YYYY');
}


export function getEncryptedFileName(file: File): string {
    var encryptedFileName = '';
    try {
        encryptedFileName = btoa(file.name);
    } catch (exp) {
        try {
            encryptedFileName = btoa(encodeURIComponent(file.name));
        } catch (exp) {
            encryptedFileName = file.name.replace(/\W/g, '');
            encryptedFileName = btoa(encryptedFileName);
        }
    }
    return encryptedFileName;
}

export function splitName(name: string) {
    let nameList: any;
    let result: string;

    if (name == null) {
        result = "";
    }
    else {
        nameList = name.split(' ').reverse();
        if (nameList.length > 2) {
            result = nameList[0] + ', ' + nameList.slice(1).reverse().join(' ');
        }
        else {
            result = nameList.join(', ');
        }
    }
    return result;
}

export function fullName(user: IUserBaseModel) {
    if (user.lastName) {
        return user.firstName + ' ' + user.lastName;
    }
    return user.firstName;
}

export function userName(user: IUserModel) {
    if (user.lastName) {
        return user.firstName + ' ' + user.lastName;
    }
    return user.firstName;
}

export function isClientInfoLoaded(model: ITaxReturn) {
    let result: boolean = false;
    if (!model) {
        return false;
    }
    if (isPartnership(model)) {
        return (model.partnership && model.partnership.ssn && model.partnership.name)
    }
    if (isMutual(model)) {
        return (model.spouse && model.spouse.ssn);
    }
    if (isIndividual(model)) {
        return (model.taxpayer && model.taxpayer.ssn);
    }
}


export function isFormGroupsLoaded(model: ITaxReturn) {
    if (!model) {
        return false;
    }
    if (!model.formGroups || model.formGroups.length === 0) {
        return false;
    }

    return true;
}


export function isMarriedJoint(model: ITaxReturn): boolean {
    if (isMutual(model)) {
        return (model.spouse !== null);
    }
    return false;
}

export function isLocked(model: ITaxReturn): boolean {
    return model && (model.documentStatus === DocumentStatus.PREPARINGFORDELIVERY ||
        model.documentStatus === DocumentStatus.DELIVERYFAILED || model.documentStatus === DocumentStatus.UPLOADED
        || model.documentStatus === DocumentStatus.DELIVERED || model.documentStatus === DocumentStatus.NONSUPPORTED
        || model.documentStatus === DocumentStatus.ERROR || model.documentStatus === DocumentStatus.REPROCESSING
        || model.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY || model.documentStatus === DocumentStatus.TAXCADDYDELIVERYFAILED
        || model.documentStatus === DocumentStatus.PREPARINGFORPDFDOWNLOAD
        || model.documentStatus === DocumentStatus.DOWNLOADPDF || model.documentStatus === DocumentStatus.DOWNLOADPDFDESCRIPTION);
}

export function validateFilter(filter: IFilters): boolean {
    let isValid: boolean = false;

    //Checking for empty filters
    for (let fieldKey of Object.keys(filter.fields)) {
        if (fieldKey != 'selectedTaxYear' && filter.fields[fieldKey] &&
            filter.fields[fieldKey].toString().length > 0) {
            isValid = true;
        }
    }

    if (filter.searchText.length > 0) {
        isValid = true;
    }

    return isValid;
}

export function validateDeliveryOption(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;
    if (taxReturn.documentSettings.deliverySettings.contactPerson === null || taxReturn.documentSettings.deliverySettings.contactPerson === 0) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InCorrectContactPerson, null);
        isValid = false;
    }
    //if (taxReturn.documentSettings.deliverySettings.preparerMessage.id === 0) {
    //    VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidMessageFromPreparer, null);
    //    isValid = false;
    //}
    if (taxReturn.documentSettings.deliverySettings.sender.senderId === null) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidSenderName, null);
        isValid = false;
    }
    if (taxReturn.documentSettings.deliverySettings.notifyUser === null || taxReturn.documentSettings.deliverySettings.notifyUser === 0) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidNotifyAboutSigningsEvents, null);
        isValid = false;
    }
    if (taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder
        && taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder == null) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysVoucherReminder, null);
        isValid = false;
    }
    if (taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder
        && taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder == null) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysSigningReminder, null);
        isValid = false;
    }
    return isValid;
}

export function validateClientInfo(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;
    if (taxReturn?.partner?.userId <= 0) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectPartner, null);
        return false;
    }
    if (isMutual(taxReturn)) {
        if ((taxReturn.taxpayer.ssn != null
            && !isValidSSN(taxReturn.taxpayer.ssn))) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inValidTaxpayerSSN, null);
            isValid = false;
        }
        else if ((taxReturn.spouse.ssn != null
            && !isValidSSN(taxReturn.spouse.ssn))) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inValidSpouseSSN, null);
            isValid = false;
        }
        return isValid;
    }
    if (isPartnership(taxReturn)) {
        if (!NullandEmptyCheck(taxReturn.partnership.name)) {
            VenusNotifier.Warning(Constants.CompanySettingsConstants.Validate.CompanyNameWarning, null);
            isValid = false;
        }
        else if (!isValidEIN(taxReturn.partnership.ssn)) {
            VenusNotifier.Warning(Constants.ValidationContants.EINWarning, null);
            isValid = false;
        }
        return isValid;
    }
    if (isIndividual(taxReturn)) {
        if ((taxReturn.taxpayer.ssn != null
            && !isValidSSN(taxReturn.taxpayer.ssn))) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inValidTaxpayerSSN, null);
            isValid = false;
        }
        return isValid;
    }
    return false;
}

export function validateClientEmailInfo(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;
    if (isMutual(taxReturn)) {
        if (taxReturn.documentSettings.deliverySettings.deliverTo === ClientTypes.Taxpayer) {
            if (!taxReturn.taxpayer.email || (!isValidEmailAddress(taxReturn.taxpayer.email))) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
                isValid = false;
            }
        }
        else if (taxReturn.documentSettings.deliverySettings.deliverTo === ClientTypes.Spouse) {
            if (!taxReturn.spouse.email && taxReturn.spouse.email != undefined || !isValidEmailAddress(taxReturn.spouse.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
                isValid = false;
            }
        }
        return isValid;
    }
    if (isPartnership(taxReturn)) {
        if (!taxReturn.partnership.email || (!isValidEmailAddress(taxReturn.partnership.email))) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectCompanyEmail, null);
            isValid = false;
        }
        return isValid;
    }
    if (isIndividual(taxReturn)) {
        if (!taxReturn.taxpayer.email || (!isValidEmailAddress(taxReturn.taxpayer.email))) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            isValid = false;
        }
        return isValid;
    }
    return false;
}

function isFirstSignerEmailValid(taxreturn: any, isTaxPayerEmailAvailable: boolean, isSpouseEmailAvailable: boolean) {
    let firstSigner: ClientTypes = taxreturn.documentSettings.deliverySettings.deliverTo;

    switch (firstSigner) {
        case ClientTypes.Taxpayer:
            return isTaxPayerEmailAvailable && isValidEmailAddress(taxreturn.taxpayer.email);
        case ClientTypes.Spouse:
            return isSpouseEmailAvailable && isValidEmailAddress(taxreturn.spouse.email);
        default:
            return isTaxPayerEmailAvailable && isValidEmailAddress(taxreturn.taxpayer.email);
    }
}


export function validateTaxPayerEmail(taxReturn: any): taxReturn is IMarriedJointTaxReturn {
    let isValid: boolean = true;
    if (!taxReturn.taxpayer.email || (!isValidEmailAddress(taxReturn.taxpayer.email))) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
        isValid = false;
    }
    return isValid;
}

export function validateSpouseEmail(taxReturn: any): taxReturn is IMarriedJointTaxReturn {
    let isValid: boolean = true;
    if (!taxReturn.spouse.email || (!isValidEmailAddress(taxReturn.spouse.email))) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
        isValid = false;
    }
    return isValid;
}

export function selectUsers(users: IUserModel[], ids: number[]): IUserModel[] {
    let selected: IUserModel[] = [];
    users.map((user, i) => {
        let found = ids.find((id) => id === user.id);
        if (found) {
            selected.push(user);
        }
    });
    return selected;
}


export function handleSelect(selected: IUserModel, event: any, stateSelect: IUserModel[], stateCollection: IUserModel[], lastSelect: number) {
    let select = [...stateSelect];
    if (event.ctrlKey) {//Ctrl Click: add with existing items
        if (!removeItem(selected, select)) {
            select.push(selected);
        }
    } else if (event.shiftKey) {//Shift Click: select all from the previous item, discard previous items
        let prevIndex = stateCollection.findIndex((s) => s.userId === lastSelect);
        let currIndex = stateCollection.findIndex((s) => s.userId === selected.userId);
        if (prevIndex > currIndex) {
            let temp = currIndex; currIndex = prevIndex; prevIndex = temp;
        }
        select = [];
        for (var i = prevIndex; i <= currIndex; i++) {
            select.push(stateCollection[i]);
        }
    } else {//Normal Click: Select only the clicked item
        select = [selected];
    }
    return select;
}

export function removeItem(item: IUserModel, list: IUserModel[]) {
    let pos = list.findIndex((u) => u.userId === item.userId);
    if (pos !== -1) {
        list.splice(pos, 1);
        return true;
    }
    return false;
}

export function removeItemById(itemId: number, list: IUserModel[]): IUserModel[] {
    let listExceptItem: IUserModel[] = [...list];
    let pos = listExceptItem.findIndex((u) => u.userId === itemId);
    if (pos !== -1) {
        listExceptItem.splice(pos, 1);
    }
    return listExceptItem;
}

export function intersect(a: any[], b: any[]) {
    var t;
    if (a && b && b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

export function intersection(arrays: any[]): any[] {
    if (arrays && arrays.length < 2) {
        return arrays[0];
    }
    let ret = arrays[0] as any[];
    for (var i = 1; i < arrays.length; i++) {
        ret = intersect(ret, arrays[i]);
    }
    return ret;
}

export function isDocumentAccessLoaded(taxReturns: ITaxReturn[]) {
    let ret = false;
    for (var i in taxReturns) {
        if (!taxReturns[i].documentAccess) {
            return false;
        }
        ret = true;
    }
    return ret;
}

export function taxReturnTitle(model: ITaxReturn) {
    {

        if (!model) {
            return "No TaxReturn Selected!";
        }
        if (isPartnership(model)) {

            return model.partnership.name + " - " + model.clientId;
        }
        if (isMutual(model) || isIndividual(model)) {
            let Name = model.taxpayer.name.split(" ");

            return ((Name.length > 1) ? Name[1] + ", " : "") + Name[0] + " - " + model.clientId;
        }

        return model.clientId;
    }
}

//export const TAX_FORMS: DocumentGroups[] = [
//    DocumentGroups.Transmittals,
//    DocumentGroups.TaxReturns,
//    DocumentGroups.EFile,
//    DocumentGroups.Vouchers,
//    DocumentGroups.Invoice,
//    DocumentGroups.K1,
//    DocumentGroups.Deleted
//];

//export function orderFormGroups(model: ITaxReturn): ITaxReturn {

//    if (model && model.formGroups && model.formGroups.length > 0) {
//        let formGroups: IGroup[] = [];
//        TAX_FORMS.map((groupType, i) => {
//            let groups = model.formGroups.filter((g) => g.group === groupType);
//            if (groups.length > 0) {
//                let group: IGroup = { group: groupType, forms: [] };
//                groups.map((g, j) => {
//                    //TODO type caste to appropriate form group form
//                    group.forms.push(...g.forms.sort((first: IFormBase, second: IFormBase) => {
//                        return (first.pageNo[0] - second.pageNo[0]);
//                    }));
//                });
//                formGroups[groupType] = group;
//            }
//        });
//        if (!formGroups[DocumentGroups.Deleted]) {
//            formGroups[DocumentGroups.Deleted] = {
//                group: DocumentGroups.Deleted,
//                forms:[]
//            };
//        }
//        model.formGroups = formGroups;
//    }
//    return model;
//}

export function removeForms(model: ITaxReturn, pages: number[], source?: DocumentGroups): IFormBase[] {
    let forms: IFormBase[] = [];
    if (model && model.formGroups) {
        model.formGroups.map((group, j) => {
            for (let i = 0; i < group.forms.length; i++) {
                let form = group.forms[i];
                let found = NO_INDEX;
                if (group.group == source || !source) {
                    form.pageNo.map((page, k) => {
                        found = pages.findIndex((n) => n === page);
                    });
                    if (found !== NO_INDEX) {
                        //Remove the form and add to the return
                        forms = forms.concat(group.forms.splice(i, 1));
                        i--;
                    }
                }
            }
        });
    }
    return forms;
}

export function removeSignatureControl(model: ITaxReturn, control: ISignatureControl, pageNo: number): void {
    if (model && model.formGroups) {

        let index = model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        if (model.formGroups[index]) {
            model.formGroups[index].forms.map((f: IFormBase) => {

                let form: IEFile = f as IEFile;
                let found = NO_INDEX;
                found = form.pageNo.findIndex((n) => n === pageNo);

                if (found != NO_INDEX) {
                    form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
                }
            });
        }
    }
}

export function addSignatureControl(model: ITaxReturn, control: ISignatureControl, pageNo: number): void {
    if (model && model.formGroups) {

        let index = model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        if (model.formGroups[index]) {

            model.formGroups[index].forms.map((f: IFormBase) => {

                let form: IEFile = f as IEFile;
                let found = NO_INDEX;
                found = form.pageNo.findIndex((n) => n === pageNo);
                if (found != NO_INDEX) {
                    form.signatureControls.push(control);
                }
            });
        }
    }
}

export function replaceSignatureControl(model: ITaxReturn, oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
    if (model && model.formGroups) {

        let index = model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
        if (model.formGroups[index]) {
            model.formGroups[index].forms.map((f: IFormBase) => {

                let form: IEFile = f as IEFile;
                let found = NO_INDEX;
                found = form.pageNo.findIndex((n) => n === pageNo);

                if (found != NO_INDEX) {

                    for (let i = 0; i < form.signatureControls.length; i++) {

                        let signControl = form.signatureControls[i];

                        if (signControl.controlGuid === oldControl.controlGuid) {

                            signControl.left = newControl.left;
                            signControl.top = newControl.top;
                            signControl.signatureControlRole = newControl.signatureControlRole;
                            signControl.type = newControl.type;

                            break;
                        }
                    }
                }
            });
        }
    }
}

export function isK1ShareHolder(engagementType: EngagementType): boolean {
    const e1065: any = EngagementType[EngagementType.E1065];
    let e1120s: any = EngagementType[EngagementType.E1120S];
    let e1041: any = EngagementType[EngagementType.E1041];
    return engagementType === e1065 || engagementType === e1120s || engagementType === e1041;
}

export function cloneTaxReturn(taxReturn: ITaxReturn): ITaxReturn {
    //TODO: complete deepCopy
    let ret = Object.assign({}, taxReturn);
    let formGroup: IGroup[] = [];
    taxReturn.formGroups.map((group, i) => {
        let forms: IFormBase[] = [];
        if (group.forms) {
            group.forms.map((form, j) => {
                let f = { ...form };
                f.pageNo = [...form.pageNo]
                forms.push(f);
            });

            if (group.group === DocumentGroups.K1) {
                let k1: IK1 = {} as IK1;
                k1.forms = forms;
                k1.group = group.group;
                k1.shareHolder = { ...(group as IK1).shareHolder };
                formGroup.push(k1);
            }
            else {
                let g: IGroup = {} as IGroup;
                g.forms = forms;
                g.group = group.group;
                formGroup.push(g);
            }
        }

    });
    ret.formGroups = formGroup;
    ret.assignedUser = Object.assign({}, taxReturn.assignedUser);
    ret.attachments = taxReturn.attachments ? [...taxReturn.attachments] : [];
    ret.clientTracking = taxReturn.clientTracking ? [...taxReturn.clientTracking] : [];
    ret.documentAccess = Object.assign({}, taxReturn.documentAccess);
    ret.documentSettings = Object.assign({}, taxReturn.documentSettings);
    ret.partner = Object.assign({}, taxReturn.partner);
    ret.refundInfo = taxReturn.refundInfo ? [...taxReturn.refundInfo] : [];
    if (isMutual(ret)) {
        ret.taxpayer = Object.assign({}, ret.taxpayer);
        ret.spouse = Object.assign({}, ret.spouse);
    }
    if (isIndividual(ret)) {
        ret.taxpayer = Object.assign({}, ret.taxpayer);
    }
    if (isPartnership(ret)) {
        ret.partnership = Object.assign({}, ret.partnership);
    }
    return ret;
}

export function removeLastComma(str: string) {
    return str.replace(/,(\s+)?$/, '');
}

export function printTextOnCanvas(text: string) {
    let canvas: any = null;
    canvas = document.querySelector('canvas');
    var ctx = canvas.getContext('2d');
    let stack: any = [];
    ctx.font = 'normal 100 200px "Journal",Georgia,Times,serif';
    ctx.fillStyle = '#145394';

    let state: any = []
    for (let property in ctx) {
        if (property == 'canvas')
            continue
        if (typeof ctx[property] == 'function')
            continue
        state[property] = ctx[property]
    }
    stack.push(state)

    let width = 0;
    if (text.length <= 6) {
        width = ctx.measureText(text).width / 2.5;
    }
    else if (text.length > 6 && text.length <= 15) {
        width = ctx.measureText(text).width / 3;
    }
    else {
        width = ctx.measureText(text).width / 3.5;
    }

    ctx.canvas.width = width ? width : canvas.width;
    ctx.canvas.height = 100 || canvas.height;

    let newState = stack.pop() || {}
    for (let property in newState) {
        ctx[property] = newState[property]
    }

    ctx.font = 'normal 100 50px "Journal",Georgia,Times,serif';
    ctx.fillStyle = '#145394';
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillText(text, 14, 59);
    return canvas.toDataURL("image/png");
}

export function formatCurency(amount: number) {
    return parseFloat((Math.round(amount * 100) / 100).toString()).toFixed(2);
}

export function PrepareTaxYear(minTaxYear?: number) {
    if (minTaxYear === undefined) {
        minTaxYear = 2020;
    }
    var years = [];
    for (let i = new Date().getFullYear(); i >= minTaxYear; i--) {
        years.push({
            value: i,
            label: i.toString()
        })
    }
    return years;
}


export function prepareStateDropdown(states: ICountryState[]) {
    var columnValues: any = [];
    states.map((state: ICountryState) => {
        columnValues.push({
            value: state.name,
            label: state.name
        })
    });
    return columnValues;
}

export function prepareAuthorityDropdown(states: ITaxingAuthority[]) {
    var columnValues: any = [{
        value: 0,
        label: "Select"
    }];
    states.map((state: any) =>
        columnValues.push({
            value: state.Id,
            label: state.AuthorityName
        }));

    return columnValues;
}

export function prepareCountryStateDropdown(states: ICountryState[]) {
    var columnValues: any = [];
    states.map((state: any) =>
        columnValues.push({
            value: state.id,
            label: state.name
        }));

    return columnValues;
}

export function prepareAdminUserDropdown(users: IPrimaryAdmin[]) {
    var columnValues: any = [];
    users.map((user: any) =>
        columnValues.push({
            value: user.id,
            label: user.firstName
        }));

    return columnValues;
}

export function prepareAuthorityStateDropdown(states: ITaxingAuthority[]) {
    var columnValues: any = [];
    states.map((state: any) =>
        columnValues.push({
            value: state.Id,
            label: state.Abbreviation
        }));

    return columnValues;
}

export function insertWhiteSpace(name: string) {
    return name.replace(/([A-Z][a-z]|[A-Z][0-9])/g, ' $1');
}

export function enumToNumbers(enums: any) {
    const keys = Object.keys(enums).filter(k => typeof enums[k] === "number");
    return keys.map(k => enums[k]);
}

export function isBusinessReturn(taxReturn: ITaxReturn) {
    let e1065: any = EngagementType[EngagementType.E1065]
    let e1120: any = EngagementType[EngagementType.E1120]
    let e1120s: any = EngagementType[EngagementType.E1120S]
    let e1041: any = EngagementType[EngagementType.E1041]

    return (taxReturn.engagementType === e1065 ||
        taxReturn.engagementType === e1041 ||
        taxReturn.engagementType === e1120 ||
        taxReturn.engagementType === e1120s)
}
export function numberFormatting(number: string) {
    return Number(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",").toString();
}

export function getStatus(value: number): string {
    switch (value) {
        case SignatureStatus.ESigned:
            return "E Signed";
        case SignatureStatus.ManuallySigned:
            return " Manually Signed";
        case SignatureStatus.Uploaded:
            return "Uploaded";
        case SignatureStatus.AwaitingESign:
            return "Awaiting E-Sign";
        case SignatureStatus.AwaitingUpload:
            return "Awaiting Upload";
        case SignatureStatus.Locked:
            return "Locked";
        case SignatureStatus.MailOrFax:
            return "Mail Or Fax";
        case SignatureStatus.SignedAndESigned:
            return "Signed And E-Signed";
        case SignatureStatus.AutoParsed:
            return "Auto Parsed";
        case SignatureStatus.Processing:
            return "Processing";
        case SignatureStatus.Delivering:
            return "Delivering";
        case SignatureStatus.Delivered:
            return "Delivered";
        case SignatureStatus.DeliveryFailed:
            return "Delivery Failed";
        case SignatureStatus.UploadInProgress:
            return "Upload In Progress";
        case SignatureStatus.DeliveredToTaxCaddy:
            return "Delivered To TaxCaddy";
        case SignatureStatus.TaxCaddyDeliveryFailed:
            return "TaxCaddy Delivery Failed";
    }
    return "";
}


export function filterSignatureByRole(signatureControls: any, prop: string, role: number) {
    var filtered = [];
    for (var i = 0; i < signatureControls.length; i++) {
        var obj = signatureControls[i];
        for (var key in obj) {
            if (typeof (obj[key] == "object")) {
                var item = obj[key];
                if (item[prop] == role) {
                    filtered.push(item);
                }
            }
        }
    }
    return filtered;
}

export function capitaliseString(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function handleEmailEventCase(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function CheckIfValueExist(data: any) {
    if (data !== null && data !== undefined) {
        return true;
    }
    return false;
}

export function CheckIfStringHasValue(data: string) {
    if (CheckIfValueExist(data) && data !== "") {
        return true;
    }
    return false;
}

function GetPreviousDate(value: number) {
    const d = new Date();
    d.setDate(d.getDate() - value);
    return moment(d.toString()).format('MM/DD/YYYY');

}

export function GetNumberOfDays(value: any) {
    switch (value) {
        case 'One_Day':
            return GetPreviousDate(1);

        case 'Two_Days':
            return GetPreviousDate(2);

        case 'Three_Days':
            return GetPreviousDate(3);

        case 'Five_Days':
            return GetPreviousDate(5);

        case 'Seven_Days':
            return GetPreviousDate(7);

        case 'Fifteen_Days':
            return GetPreviousDate(15);

        case 'Thirty_Days':
            return GetPreviousDate(30);
        default:
            return GetPreviousDate(1);
    }
}

export function FilterSSN(ssn: string): string {

    if (ssn && ssn.startsWith("SSN")) {
        return "";
    }
    else {
        return ssn;
    }
}

export function GetPagesToMatch(matchedForms: IFormBase[], pages: number[]): any {
    return pages.filter(x => matchedForms.some(y => y.pageNo[0] != x))
}

export function GetFileMagicNumber(file: any): Promise<any> {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file.slice(0, 4));
    return new Promise((resolve) => {
        reader.onload = function () {
            if (this.readyState == reader.DONE) {
                const arrayBuffer: any = this.result;
                const uint = new Uint8Array(arrayBuffer).subarray(0, 4)
                let bytes: any = []
                uint.forEach((byte) => {
                    bytes.push(byte.toString(16))
                })
                return resolve(bytes.join('').toUpperCase());
            }
        }
    });
}
export function ReplaceHelperVariableForEditor(message: string) {
    message = message.replace(/<COMPANYNAME>/g, "&amp;lt;COMPANYNAME&amp;gt;");
    message = message.replace(/<TAXPAYERNAME>/g, "&amp;lt;TAXPAYERNAME&amp;gt;");
    message = message.replace(/<SPOUSENAME>/g, "&amp;lt;SPOUSENAME&amp;gt;");
    message = message.replace(/<PREPARERNAME>/g, "&amp;lt;PREPARERNAME&amp;gt;");
    message = message.replace(/<DUEDATE>/g, "&amp;lt;DUEDATE&amp;gt;");
    message = message.replace(/<TAXYEAR>/g, "&amp;lt;TAXYEAR&amp;gt;");
    message = message.replace(/<NUMBEROFDAYS>/g, "&amp;lt;NUMBEROFDAYS&amp;gt;");
    message = message.replace(/<RECIPIENT>/g, "&amp;lt;RECIPIENT&amp;gt;");
    message = message.replace(/<HELPCONTACT>/g, "&amp;lt;HELPCONTACT&amp;gt;");
    message = message.replace(/<HELPPHONE>/g, "&amp;lt;HELPPHONE&amp;gt;");
    message = message.replace(/<HELPEMAIL>/g, "&amp;lt;HELPEMAIL&amp;gt;");
    message = message.replace(/<SENDERNAME>/g, "&amp;lt;SENDERNAME&amp;gt;");
    message = message.replace(/<ERONAME>/g, "&amp;lt;ERONAME&amp;gt;");

    return message;
}

export function ReplaceHelperVariable(message: string) {
    message = message.replace(/<COMPANYNAME>/g, "&lt;COMPANYNAME&gt;");
    message = message.replace(/<TAXPAYERNAME>/g, "&lt;TAXPAYERNAME&gt;");
    message = message.replace(/<SPOUSENAME>/g, "&lt;SPOUSENAME&gt;");
    message = message.replace(/<PREPARERNAME>/g, "&lt;PREPARERNAME&gt;");
    message = message.replace(/<DUEDATE>/g, "&lt;DUEDATE&gt;");
    message = message.replace(/<TAXYEAR>/g, "&lt;TAXYEAR&gt;");
    message = message.replace(/<NUMBEROFDAYS>/g, "&lt;NUMBEROFDAYS&gt;");
    message = message.replace(/<RECIPIENT>/g, "&lt;RECIPIENT&gt;");
    message = message.replace(/<HELPCONTACT>/g, "&lt;HELPCONTACT&gt;");
    message = message.replace(/<HELPPHONE>/g, "&lt;HELPPHONE&gt;");
    message = message.replace(/<HELPEMAIL>/g, "&lt;HELPEMAIL&gt;");
    message = message.replace(/<SENDERNAME>/g, "&lt;SENDERNAME&gt;");
    message = message.replace(/<ERONAME>/g, "&lt;ERONAME&gt;");
    return message;
}

export function padDigits(value: any, digits: number) {
    return Array(Math.max(digits - String(value).length + 1, 0)).join('0') + value;
}

export function getAuthorityData(authorityId: number, authorities: ITaxingAuthority[]): { name: string, image: string } {
    let voucherAuthority: ITaxingAuthority = authorities[authorities
        .findIndex(x => x.Id === authorityId)];
    let stateImage: { imageName: string, stateUSA: StateUSA } | undefined;
    let stateAbbreviation: any = voucherAuthority.Abbreviation
    stateImage = getAllStateImages().find(img => img.stateUSA === StateUSA[stateAbbreviation]);
    return {
        name: voucherAuthority.AuthorityName,
        image: stateImage !== undefined ? stateImage.imageName : "no-image.png"
    };
}

export function getMaskedValue(value: string, mask: string): string {
    if (value) {

        let maskedValue = '';

        const computedMask: any[] = mask.split('')
            .map((m) => {
                const index = REGEX_VALUES.findIndex(a => a.Key == m);
                if (index > -1) {
                    return REGEX_VALUES[index].Value;
                }
                else {
                    return m;
                }

            });

        let processedIndex = 0;
        const currentValue = getUnmaskedValue(value, mask);

        for (let index = 0; index < computedMask.length; index++) {

            if (computedMask[index] instanceof RegExp) {

                if (processedIndex < currentValue.length) {
                    let valueUpdated = false;

                    for (let i = processedIndex; i < currentValue.length; i++) {
                        if (computedMask[index].test(currentValue[processedIndex])) {
                            maskedValue += currentValue[processedIndex];
                            processedIndex += 1;
                            valueUpdated = true;
                            break;
                        }
                        else {
                            processedIndex += 1;
                        }
                    }

                    if (!valueUpdated) {
                        maskedValue += MASK_PLACEHOLDER;
                    }
                }
                else {
                    maskedValue += MASK_PLACEHOLDER;
                }

            }
            else {
                maskedValue += computedMask[index];
            }
        }

        if (getUnmaskedValue(maskedValue, mask) != '') {
            return maskedValue;
        }
    }

    return '';
}

export function getUnmaskedValue(value: string, mask: string): string {
    if (value) {
        const separators = getSeparators(mask);

        return value.split('')
            .filter((v) => { return separators.every((separator) => separator != v) && v != MASK_PLACEHOLDER })
            .join('');
    }
    else {
        return '';
    }
}

export function getSeparators(mask: string): string[] {
    return mask.split('')
        .filter((m, index, self) => {
            return REGEX_VALUES.findIndex(regex => regex.Key == m) == -1 && self.indexOf(m) == index;
        });
}

export function decodeText(text: string) {
    return text.replace(/\&nbsp;/g, ' ');
}


export function countryCodeDisplay(countryCode: string): string {
    return countryCode ? "(" + countryCode + ") " : "";
}

export function convertIPAddresstoNumber(ipAddress: any): number {
    return Number(
        ipAddress.split(".")
            .map((d: any) => ("000" + d).substr(-3))
            .join("")
    );
}

export function openWindowWithPostRequest(url: string, data: string, fieldName: string, scope?: string) {
    var winName = '_blank';
    var winURL = url;
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", winURL);
    form.setAttribute("target", winName);
    form.setAttribute("enctype", "application/json");
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = fieldName;
    input.value = JSON.stringify(data);
    form.appendChild(input);
    if (scope) {
        var iscope = document.createElement('input');
        iscope.type = 'hidden';
        iscope.name = 'scope';
        iscope.value = scope;
        form.appendChild(iscope);
        document.body.appendChild(form);
    }
    form.target = winName;
    form.submit();
    document.body.removeChild(form);
}

export function ConvertToQuestionControlsModel(question: Question) {
    const controls: QuestionControl[] = [] as QuestionControl[];
    controls.push(FillQuestionControlProps(question));
    if (question.subQuestions?.length > 0) {
        question.subQuestions.forEach((subQ) => {
            controls.push(FillQuestionControlProps(subQ, question.id));
        });
    }
    return controls;
}

export function FillQuestionControlProps(question: Question, parentId?: string) {
    const control: QuestionControl = {} as QuestionControl;
    control.signerId = 0;
    control.id = question.id;
    control.required = question.required;
    control.type = question.type;
    control.controlType = ControlType.QuestionControl;
    control.sectionId = question.sectionId;
    control.parentQuestionId = parentId ?? Guid.empty;
    if (question.subQuestions?.length > 0) {
        let subQuestions: SubQuestionMapping[] = [] as SubQuestionMapping[];
        let subQuestion: SubQuestionMapping = {} as SubQuestionMapping;
        subQuestion.condition = question.subQuestions[0].condition;
        subQuestion.subQuestionIds = [];
        subQuestion.subQuestionIds.push(question.subQuestions[0].id);
        subQuestions.push(subQuestion);
        if (question.subQuestions?.length > 1) {
            if (subQuestion.condition == question.subQuestions[1].condition) {
                subQuestion.subQuestionIds.push(question.subQuestions[1].id);
            }
            else {
                let subQuestion1: SubQuestionMapping = {} as SubQuestionMapping;
                subQuestion1.condition = question.subQuestions[1].condition;
                subQuestion1.subQuestionIds = [];
                subQuestion1.subQuestionIds.push(question.subQuestions[1].id);
                subQuestions.push(subQuestion1);
            }
        }
        control.subQuestions = subQuestions;
    }
    control.textbox = {
        id: Guid.newGuid().toString(),
        controlType: ControlType.Textbox,
        dataType: DataType.TextArea,
        controlData: {
            value: question.value.replace(new RegExp('<div><br></div>', 'g'), "\n")
        },
        boundingRectangle: {
            left: TextBoxProperties.Left,
            top: 0,
            width: TextBoxProperties.Width,
            height: question.height
        }
    } as Textbox;
    if (question.type == QuestionType.YesNo) {
        control.radioButtonGroup = {
            id: Guid.newGuid().toString(),
            controlType: ControlType.RadioButtonGroup,
            controlData: {
                items: []
            }
        } as any;
        control.radioButtonGroup.controlData.items = [
            {
                id: Guid.newGuid().toString(),
                controlType: ControlType.RadioButton,
                boundingRectangle: {
                    left: RadioButtonProperties.Left,
                    top: RadioButtonProperties.Top,
                    width: RadioButtonProperties.Width,
                    height: RadioButtonProperties.Height
                }
            } as RadioButton,
            {
                id: Guid.newGuid().toString(),
                controlType: ControlType.RadioButton,
                boundingRectangle: {
                    left: RadioButtonProperties.Left + 40,
                    top: RadioButtonProperties.Top,
                    width: RadioButtonProperties.Width,
                    height: RadioButtonProperties.Height
                }
            } as RadioButton
        ];
    }
    else {
        control.inputTextbox = {
            id: Guid.newGuid().toString(),
            controlType: ControlType.Textbox,
            dataType: DataType.TextArea,
            controlData: {
                value: ''
            },
            boundingRectangle: {
                left: TextBoxProperties.Left,
                top: 0,
                width: TextBoxProperties.Width,
                height: 20
            }
        } as Textbox;
    }
    return control;
}

export function ConvertToPopupQuestionModel(control: QuestionControl, subQuestions: QuestionControl[]) {
    let questionValue = control.textbox.controlData.value;
    if (control.required) {
        if (questionValue.charAt(questionValue.length - 1) === "*") {
            questionValue = questionValue.substring(0, questionValue.length - 1);
        }
        if (questionValue.endsWith("</div>")) {
            questionValue = questionValue.substring(0, questionValue.length - 7) + "</div>";
        }
    }
    let question = new Question
        (control.sectionId,
            control.type,
            control.required,
            subQuestions?.length > 0,
            (subQuestions?.length > 0 ? AddSubQuestions(control, subQuestions) : []),
            control.id,
            questionValue,
            control.textbox.boundingRectangle.height
        )
    return question;
}

export function AddSubQuestions(question: QuestionControl, subQuestions: QuestionControl[]) {
    let _subQuestions: SubQuestion[] = [] as SubQuestion[];
    question.subQuestions.forEach((subQMap) => {
        subQMap.subQuestionIds.forEach((subQ) => {
            let subQControl = subQuestions.find(a => a.id == subQ);
            if (subQControl) {
                let subQuestion = new SubQuestion
                    (subQMap.condition,
                        subQControl.sectionId,
                        subQControl.type,
                        subQControl.required,
                        false,
                        [],
                        subQControl.id,
                        (subQMap.condition) ?
                            subQControl.textbox.controlData.value.replace("If Yes, ", "") :
                            subQControl.textbox.controlData.value.replace("If No, ", ""),
                        subQControl.textbox.boundingRectangle.height
                    );
                _subQuestions.push(subQuestion);
            }
        });
    });
    return _subQuestions;
}

export function getControlTopPositionPDFJS(page: number, top: number, scale: number): number {
    const pageElement: any = ControlBase.getPageElement(page);
    const AWESOME_PDF_VIEWER_LETTER_HEIGHT = 1056;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4 = 842;
    const AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER = 792;
    const pageHeight = pageElement ? pageElement.offsetHeight : AWESOME_PDF_VIEWER_LETTER_HEIGHT;
    const baseHeight = pageHeight > AWESOME_PDF_VIEWER_LETTER_HEIGHT ? AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_A4
        : AWESOME_PDF_VIEWER_BASE_HEIGHT_DOCTYPE_LETTER;
    const topRatio = (pageHeight * scale) / baseHeight;

    top = pageHeight - top;
    top = (top / topRatio) - 5;

    return top;

}

export function getRadioButtonLeftAdjustmentValue(question: QuestionControl): number {
    if (question.parentQuestionId && question.parentQuestionId !== Guid.empty && question.sectionId && question.sectionId !== Guid.empty)
        return 20;
    else if (question.parentQuestionId && question.parentQuestionId !== Guid.empty || question.sectionId && question.sectionId !== Guid.empty)
        return 13.3;
    return 6.6;
}

export function getControlHeight(control: Control): number {
    let height = 0;
    switch (control.controlType) {
        case ControlType.QuestionControl: {
            const questionControl = control as QuestionControl;
            height = questionControl.textbox.boundingRectangle.height;
            if (questionControl.inputTextbox && questionControl.inputTextbox?.boundingRectangle?.height) {
                height += questionControl.inputTextbox.boundingRectangle.height;
            }
            return height;
        }
        case ControlType.Section: {
            const sectionControl = control as SectionControl;
            return sectionControl.section.boundingRectangle.height;
        }

    }
    return height;
}
export function resetCookie(cookiename: string, value: string) {
    var cookiestring = document.cookie;
    var cookiearray = cookiestring.split(";");
    for (var i = 0; i < cookiearray.length; ++i) {
        if (cookiearray[i].trim().match("^" + cookiename + "=")) {
            if (cookiearray[i]) {
                cookiearray[i] = `${cookiename}=${value}`.trim();
            }
        }
    }
}
export function validateError(error: any) {
    if (typeof error === 'string') return error;
    if (typeof error === 'undefined') return '';
    if (error.message !== undefined) return error.message;
    if (error.statusText !== undefined) return error.statusText;
    return '';
}

export const getTotalQuestions = (formData: IFormData) => {
    let totalQuestions = 0;

    formData && formData.sections.forEach((section) => totalQuestions = section.sectionItems.length + totalQuestions)
    return totalQuestions;
}

export const getTemplateLimitErrorMessage = (templateLimit: number): string => `Maximum limit of templates is ${templateLimit}`;

export const getQuestionsLimitErrorMessage = (questionLimit: number, text: string): string => `Unable to ${text}. Question limit exceeded! Maximum limit of questions is ${questionLimit}`;

export const getMaxOptionsDisbaledTooltip = (optionLimit: number): string => `Maximum ${optionLimit} options are allowed`;

export const getMaxAdditionalQuestionDisbaledTooltip = (optionLimit: number): string => `Maximum ${optionLimit} additional questions are allowed`;

export function textWithEllipsis(text: string, maxLength: number) {
    if (text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
    }
    return text;
}

export function removeUnwantedWhitespacesFromText(text: string) {
    const splittedText = text.split(/\s+/);
    if (splittedText.length > 1) {
        const lastsplittedText = splittedText.at(-1);
        const firstsplittedText = splittedText.at(0);
        if (lastsplittedText === "") {
            splittedText.pop();
        }
        if (firstsplittedText === "") {
            splittedText.shift();
        }
    }
    return splittedText.join(" ");
}
export const generateRandomString = () => Math.floor(Math.random() * Date.now()).toString(36);

export const generateRandomNumber = () => Number(Math.floor(Math.random() * Date.now()).toString().slice(0,4));

export const insertRandomIds = (data: IFormData) => {
    if (data?.sections?.length > 0) {
        data.sections.forEach((section) =>
            section.sectionItems.forEach((sectionItem) => (sectionItem.id = generateRandomString()))
        );
    }
    return data;
};

export const updateSectionAndQuestionnaireOrder = (data: IFormData) => {
    if(data){
        const mutableData=JSON.parse(JSON.stringify(data));
        if (data.sections && mutableData.sections.length > 0) {
            mutableData.sections.forEach((section, sectionIndex) => {
                section.order = sectionIndex + 1;
                section.sectionItems.forEach((sectionItem, sectionItemIndex) => {
                    if (sectionItem.hasOwnProperty('hasFollowUpQuestion')) {
                        delete sectionItem.hasFollowUpQuestion;
                    }
                    sectionItem.order = sectionItemIndex + 1;
                });
            });
        }
        return mutableData;
    }
   return data
};

export const createForethoughtCookie = (key: string, value: string, expiryMiliSeconds?: number) => {
    const domain = getDomain(window.location.href);
    createCookie(key, value, expiryMiliSeconds ?? 24 * 60 * 60 * 1000, `.${domain}`);
};