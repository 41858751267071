export enum AccessCodeStatus {
    NA = 0,
    Active,
    Authenticated,
    NotRequested,
    Expired
}
export enum OnehubAccessCodeStatus {
    Active = 0,
    NotRequested = 1,
    Expired = 2,
    Authenticated = 3
}

export const statusButtonText = (status: OnehubAccessCodeStatus) => {
    switch (status) {
        case OnehubAccessCodeStatus.Active:
            return "Active";
        case OnehubAccessCodeStatus.NotRequested:
            return "Not Requested";
        case OnehubAccessCodeStatus.Expired:
            return "Expired";
        case OnehubAccessCodeStatus.Authenticated:
            return "Authenticated";
    }
}