import { IFileClient, IEFileClient, FileClient } from '../../Services/FileClient';



export class ServiceFactory {

    public getFileClient(): IFileClient {

        try {
            if (/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
                return new IEFileClient();
            }
            else {
                return new FileClient();
            }
        } catch (error) {
            return new FileClient();
        }
    }
}