import React, { useEffect, useState } from "react";
import { formattedClientName } from "../../Helper/OrganizerHelperFunctions";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { LinkIcon } from "src/assets/SvgIconCollection";
import { ApplicationState } from "src/store";
import { useSelector } from "react-redux";
import { ILinkedMergedDetail } from "src/Organizer/store/UTEMergedDetailStore";

interface TaxpayerNameForDeliveredGatherTableProps {
    row: any;
    onLinkClick: (emailAddress: string) => void;
}

const ClientNameFormatter: React.FC<TaxpayerNameForDeliveredGatherTableProps> = (props) => {
    const uteMergedData = useSelector((state: ApplicationState) => state.uteMergedDetail);

    const getPopoverContent = () => {
        if (uteMergedData.loading) {
            return <div className="details-container">Loading...</div>;
        }
        if (uteMergedData.mergedDetails.length > 0) {
            const uniqueClientIds = uteMergedData.mergedDetails.reduce((clientIds: string[], eachDetail: ILinkedMergedDetail) => {
                if (!clientIds.includes(eachDetail.clientId)) {
                    clientIds.push(eachDetail.clientId);
                }
                return clientIds;
            }, []);

            return (
                <div className="details-container">
                    <div className="connected-with-text">Connected with:</div>
                    {uniqueClientIds?.map((eachClientId: string) => {
                        const linkedClientDetails = uteMergedData.mergedDetails.filter(
                            (eachDetail: ILinkedMergedDetail) => eachDetail.clientId === eachClientId
                        );

                        const uniqueClientNames = linkedClientDetails.reduce(
                            (clientNames: string[], eachClientDetail: ILinkedMergedDetail) => {
                                if (!clientNames.includes(eachClientDetail.name)) {
                                    clientNames.push(eachClientDetail.name);
                                }
                                return clientNames;
                            },
                            []
                        );

                        return uniqueClientNames.map((eachClientName: string) => {
                            const linkedDataForClientIdAndName = linkedClientDetails.filter(
                                (eachClientDetail: ILinkedMergedDetail) =>
                                    eachClientDetail.clientId === eachClientId && eachClientDetail.name === eachClientName
                            );

                            const uniqueEmailIds = uteMergedData.mergedDetails.reduce(
                                (emailIds: string[], eachDetail: ILinkedMergedDetail) => {
                                    if (!emailIds.includes(eachDetail.emailId)) {
                                        emailIds.push(eachDetail.emailId);
                                    }
                                    return emailIds;
                                },
                                []
                            );

                            return (
                                <div className="linked-data">
                                    <div className="linked-clientName">{eachClientName}</div>
                                    <div className="linked-clientId">{eachClientId}</div>
                                    {linkedDataForClientIdAndName.length === 1 ? (
                                        <div
                                            className={`linked-emailId ${
                                                linkedDataForClientIdAndName[0].isPrimaryUTE ? "primary-emailId" : ""
                                            }`}
                                        >
                                            {linkedDataForClientIdAndName[0].emailId}
                                        </div>
                                    ) : (
                                        linkedDataForClientIdAndName.map(
                                            (eachLinkedDetail: ILinkedMergedDetail, index: number) => (
                                                <div
                                                    className={`linked-emailId ${
                                                        eachLinkedDetail.isPrimaryUTE ? "primary-emailId" : ""
                                                    }`}
                                                >
                                                    {index + 1}. {eachLinkedDetail.emailId}
                                                </div>
                                            )
                                        )
                                    )}
                                </div>
                            );
                        });
                    })}
                </div>
            );
        }
    };
    return (
        <span title={formattedClientName(props.row.taxpayerName)} className="ellipsis report-name">
            {formattedClientName(props.row.taxpayerName)}
            {props.row.isUTELinked ? (
                <OverlayTrigger
                    trigger={"click"}
                    rootClose
                    placement={props.row.rowIndex < 10 ? "bottom" : "top"}
                    overlay={
                        <Popover className="linked-details-popover" id={""}>
                            {getPopoverContent()}
                        </Popover>
                    }
                >
                    <span
                        onClick={() => {
                            props.onLinkClick(props.row.taxpayerEmail);
                        }}
                    >
                        <LinkIcon />
                    </span>
                </OverlayTrigger>
            ) : (
                ""
            )}
        </span>
    );
};

export default ClientNameFormatter;
