import { IUserProfile } from '../components/navigation/profile/ProfileObjects';
import * as UserProfileStore from './UserManagement/UserProfileStore';
import * as UserPrivilegeStore from './UserManagement/UserPrivilegeStore';
import * as UserSettingStore from './UserManagement/UserSettingStore';
import * as UserStore from './UserManagement/UserStore';
import * as CompanyStore from './company/CompanyStore';
import * as SavedMessageStore from './settings/SavedMessageStore';
import * as NotificationStore from '../store/common/NotificationStore';
import * as UserLoginHistory from './UserManagement/UserLoginHistoryStore';
import * as OneTimePasswordStore from '../store/common/OneTimePasswordStore';
import {
    DeliveredOrganizerFilterStoreState,
    DeliveredOrganizerStoreState,
    OrganizerUploadState,
    OrganizerSettingsStoreState,
    OrganizerStoreState,
    OrganizerEmailMessageStoreState,
    OrganizerPrepareMessageStoreState,
    OrganizerBlankTemplateStoreState,
    OrganizerPageSelectorStoreState,
    BatchStoreState,
    PagedBatchOrganizerStoreState,
    PagedBatchStoreState,
    AdditionalQuestionStoreState,
    InprocessOrganizerFilterStoreState,
    OrganizerBatchExcelDownloadStoreState,
    DownloadOrganizerStoreState,
    IColumnSettingsState,
    RecycledOrganizerStoreState,

} from './../Organizer/models/OrganizerStoreModels';

import * as  DeliveredOrganizerStore from './../Organizer/store/DeliveredOrganizerStore';
import * as  UndeliveredBatchStore from './../Organizer/store/UndeliveredBatchStore';
import * as  OrganizerStore from './../Organizer/store/OrganizerStore';
import * as  OrganizerFilterStore from './../Organizer/store/OrganizerFilterStore';
import * as  OrganizerUploadStore from './../Organizer/store/OrganizerUploadStore';
import * as  OrganizerCompanySettingsStore from './../Organizer/store/OrganizerCompanySettingsStore';
import * as  OrganizerPreparerMessageStore from './../Organizer/store/OrganizerPreparerMessageStore';
import * as  OrganizerEmailMessageStore from './../Organizer/store/OrganizerEmailMessageStore';
import * as  BlankOrganizerTemplateStore from './../Organizer/store/BlankOrganizerTemplateStore';
import * as  OrganizerPageSelectorStore from './../Organizer/store/OrganizerPageSelectorStore';
import * as  BatchStore from './../Organizer/store/BatchStore';
import * as  DeliveredBatchStore from './../Organizer/store/DeliveredBatchStore';
import * as  PagedBatchOrganizerStore from './../Organizer/store/PagedBatchOrganizerStore';
import * as  PagedBatchStore from './../Organizer/store/PagedBatchStore';
import * as  AdditionalQuestionsStore from './../Organizer/store/AdditionalQuestionsStore';
import * as  InprocessOrganizerFilterStore from './../Organizer/store/InprocessOrganizerFilterStore';
import * as OrganizerBatchExcelDownloadStore from './../Organizer/store/BatchExcelDownloadStore';
import * as UserSignatureStore from './../Organizer/store/UserSignatureStore';
import * as UteMergedStore from './../Organizer/store/UTEMergedDetailStore';
import * as AdditionalEsignDocumentStore from './../Organizer/store/AdditionalEsignDocumentStore';
import * as DownloadOrganizerStore from '../Organizer/store/DownloadOrganizerStore';
import * as  ColumnSettingsStore from './../Organizer/store/ColumnSettingsStore';
import * as  RecycledOrganizerStore from './../Organizer/store/RecycledOrganizerStore';
import * as RoleBasedAccessControlStore from './../store/roleBasedAccessControl/RoleBasedAccessControlStore';
import * as ProductMenuStore from '../store/productMenu/ProductMenuStore';
import * as WidgetMenuStore from '../store/productMenu/WidgetMenuStore';
import * as FormBuilderStore from '../store/settings/FormBuilder/FormBuilderStore';
import * as CustomQuestionsStore from '../store/settings/CustomQuestions/CustomQuestionsStore';
import * as CqPreviewStore from './settings/CqPreview/CqPreviewStore';
import { IRbacState } from './roleBasedAccessControl/model/RoleBasedAccessControlStoreModel';
import { IMenuState, IProductData, IWidgetMenuState } from './productMenu/model/ProductMenuStoreModel';
import { IUserPrivilege } from './UserManagement/UserPrivilegeStore';
import { IAuthState, reducer } from './auth/reducer';
import { ICustomQuestionsState, IQuestionnaireState } from 'src/Core/ViewModels/CompanySettings/CustomQuestionModel';
import { reducer as PostAuthStoreReducer, PostAuthStoreState } from './PostAuth/PostAuthStore';
import { ILinkedMergedDetails } from 'src/Organizer/store/UTEMergedDetailStore';


// The top-level state object
export interface ApplicationState {
    auth: IAuthState;
    userProfile: IUserProfile;
    userSettings: UserSettingStore.UserSettings;
    usersList: UserStore.IUserData;
    partners: UserStore.IPartnerData;
    userLocations: UserStore.ILocationData;
    companyData: CompanyStore.ICompanyData;
    //manualAddress: ManualAddressStore.IManualAddressStoreState;
    notification: NotificationStore.NotificationState;

    savedMessage: SavedMessageStore.SavedMessageStoreState;

    userLoginHistory: UserLoginHistory.IUserLoginHistoryState;
    //inboxState: InboxStore.IInboxState;

    //signatureFlowSettingsData: SignatureFlowSettingsStore.ISignatureFlowSettingsData;

    ssrUserList: UserStore.ISSRUserData;
    oneTimePasswordStore: OneTimePasswordStore.IOneTimePasswordState;

    deliveredOrganizerReports: DeliveredOrganizerStoreState;
    organizerReportFilter: DeliveredOrganizerFilterStoreState;
    organizerUpload: OrganizerUploadState;
    organizerCompanySettings: OrganizerSettingsStoreState;
    organizerPreparerMessage: OrganizerPrepareMessageStoreState;
    organizerEmailMessage: OrganizerEmailMessageStoreState;
    organizerReports: OrganizerStoreState;
    organizerTemplateStore: OrganizerBlankTemplateStoreState;
    organizerReportSelectorStore: OrganizerPageSelectorStoreState;
    pagedBatchStore: PagedBatchStoreState;
    batchStore: BatchStoreState;
    deliveredBatchStore: BatchStoreState;
    pagesBatchOrganizerStore: PagedBatchOrganizerStoreState;
    additionalQuestionStore: AdditionalQuestionStoreState;
    inprocessOrganizerReportFilter: InprocessOrganizerFilterStoreState;
    batchExportExcelStore: OrganizerBatchExcelDownloadStoreState;
    userSignatures: UserSignatureStore.IUserSignatures;
    additionalEsignDocumentStore: AdditionalEsignDocumentStore.IBlobFile;
    downloadOrganizerStore: DownloadOrganizerStoreState;
    undeliveredBatchStoreState: BatchStoreState;
    columnSettings: IColumnSettingsState;
    recycledOrganizerReports: RecycledOrganizerStoreState;
    roleBasedAccessControl: IRbacState;
    productMenuControl: IMenuState;
    widgetMenuControl: IWidgetMenuState;
    userPrivilege: IUserPrivilege;
    formBuilder: IQuestionnaireState;
    customQuestions: ICustomQuestionsState;
    cqPreviewFormData: IQuestionnaireState;
    AccessibleProductData: IProductData,
    postAuthDataStore: PostAuthStoreState;
    uteMergedDetail: ILinkedMergedDetails;
}

export const reducers = {
    auth: reducer,
    userProfile: UserProfileStore.reducer,
    userSettings: UserSettingStore.reducer,
    usersList: UserStore.reducer,
    partners: UserStore.PartnerReducer,
    userLocations: UserStore.LocationReducer,
    companyData: CompanyStore.reducer,
    notification: NotificationStore.reducer,
    savedMessage: SavedMessageStore.reducer,
    userLoginHistory: UserLoginHistory.reducer,
    ssrUserList: UserStore.SSRUserReducer,
    oneTimePasswordStore: OneTimePasswordStore.reducer,
    deliveredOrganizerReports: DeliveredOrganizerStore.reducer,
    organizerReportFilter: OrganizerFilterStore.reducer,
    organizerUpload: OrganizerUploadStore.reducer,
    organizerCompanySettings: OrganizerCompanySettingsStore.reducer,
    organizerPreparerMessage: OrganizerPreparerMessageStore.reducer,
    organizerEmailMessage: OrganizerEmailMessageStore.reducer,
    organizerReports: OrganizerStore.reducer,
    organizerTemplateStore: BlankOrganizerTemplateStore.reducer,
    organizerReportSelectorStore: OrganizerPageSelectorStore.reducer,
    pagedBatchStore: PagedBatchStore.reducer,
    batchStore: BatchStore.reducer,
    deliveredBatchStore: DeliveredBatchStore.reducer,
    pagesBatchOrganizerStore: PagedBatchOrganizerStore.reducer,
    additionalQuestionStore: AdditionalQuestionsStore.reducer,
    inprocessOrganizerReportFilter: InprocessOrganizerFilterStore.reducer,
    batchExportExcelStore: OrganizerBatchExcelDownloadStore.reducer,
    userSignatures: UserSignatureStore.reducer,
    downloadOrganizerStore: DownloadOrganizerStore.reducer,
    undeliveredBatchStoreState: UndeliveredBatchStore.reducer,
    columnSettings: ColumnSettingsStore.reducer,
    recycledOrganizerReports: RecycledOrganizerStore.reducer,
    roleBasedAccessControl: RoleBasedAccessControlStore.reducer,
    productMenuControl: ProductMenuStore.reducer,
    widgetMenuControl: WidgetMenuStore.reducer,
    userPrivilege: UserPrivilegeStore.reducer,
    formBuilder: FormBuilderStore.reducer,
    customQuestions: CustomQuestionsStore.reducer,
    cqPreviewFormData: CqPreviewStore.reducer,
    AccessibleProductData: ProductMenuStore.productsReducer,
    postAuthDataStore: PostAuthStoreReducer,
    uteMergedDetail:UteMergedStore.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}
