import React, { FC } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const PreLogin: FC = () => {
    const history = useHistory();
    return <Redirect path="*" to={{ pathname: "/login", state: { redirectedFrom: history.location } }} />
}

PreLogin.displayName = 'PreLogin'

export default PreLogin
